import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQuery, useQueryClient } from "react-query"
import CustomHolidayArray from "../components/form/CustomHolidayArray"
import FormErrorMessages from "../components/form/FormErrorMessages"
import FormSuccessMessage from "../components/form/FormSuccessMessage"
import NationalHolidayArray from "../components/form/NationalHolidayArray"
import RegularPage from "../components/page/RegularPage"
import { useTabs } from "../contexts/TabsContext"
import { useSelectedLocation } from "../contexts/UserSettingsContext"
import { getShortDayName, getTimeString } from "../helpers/DaysHelper"
import { loadHolidaySettings, updateHolidaySettings } from "../services/HolidaySettings"
import { HolidaySettingsType } from "../types/HolidaySettingsType"

const SettingsPage: FC = () => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const { setActiveTab } = useTabs()
    const location = useSelectedLocation()
    const [succeededId, setSucceededId] = useState(0)
    const [formErrors, setFormErrors] = useState<string[]>([])

    useEffect(() => setActiveTab("Settings"), [setActiveTab])

    const { data: holidaySettings } = useQuery(["HolidaySetting", location.id], () => loadHolidaySettings(location.id))

    const { control, register, handleSubmit, getValues, setValue, watch } = useForm<HolidaySettingsType>()

    useEffect(() => {
        if (holidaySettings) {
            const { customHolidays, nationalHolidays } = holidaySettings
            setValue("customHolidays", customHolidays)
            setValue("nationalHolidays", nationalHolidays)
        }
    }, [holidaySettings])

    const removeQueries = useCallback(() => {
        queryClient.removeQueries({ queryKey: ["HolidaySetting", location.id] })
    }, [queryClient, location])

    const onSuccess = useCallback(() => {
        setSucceededId(succeededId + 1)
        setFormErrors([])
        removeQueries()
    }, [setSucceededId, succeededId, setFormErrors, removeQueries])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setFormErrors(data.nonFieldErrors || [t("Main.something_went_wrong")])
            setSucceededId(0)
        },
        [setSucceededId, setFormErrors]
    )

    const onSubmit = useCallback(
        (data: HolidaySettingsType) => {
            updateHolidaySettings(data, location.id).then(onSuccess).catch(onFailure)
        },
        [location, onSuccess, onFailure]
    )

    const daysValue = useMemo(
        () =>
            Array.from(location.enabledDays)
                .map((c, i) => (c === "1" ? `${getShortDayName(i).toLowerCase()}` : null))
                .filter((c) => !!c)
                .join(", "),
        [location]
    )

    const timeLineValue = useMemo(() => `${getTimeString("start", location.startSlot)}-${getTimeString("end", location.endSlot)}`, [location])

    return (
        <RegularPage id="MyPassword">
            <h2>{t("SettingsPage.settings")}</h2>
            <Form onSubmit={handleSubmit(onSubmit)} className="spacer">
                <Form.Group>
                    <Form.Label>{t("SettingsPage.name")}</Form.Label>
                    <Form.Control size="lg" type="text" value={location.name} disabled />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("SettingsPage.days")}</Form.Label>
                    <Form.Control size="lg" type="text" value={daysValue} disabled />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("SettingsPage.timeline")}</Form.Label>
                    <Form.Control size="lg" type="text" value={timeLineValue} disabled />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("SettingsPage.holidays")}</Form.Label>
                    <div className="ms-2 spacer">
                        <NationalHolidayArray control={control} register={register} />
                        <CustomHolidayArray control={control} register={register} setValue={setValue} getValues={getValues} watch={watch} />
                    </div>
                </Form.Group>
                <div>
                    <Button type="submit" data-cy="submit-button">
                        {t("Buttons.save")}
                    </Button>
                </div>
                <FormErrorMessages errors={formErrors} />
                <FormSuccessMessage succeededId={succeededId} />
            </Form>
        </RegularPage>
    )
}

export default SettingsPage
