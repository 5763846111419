import { FC } from "react"
import { Badge } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import IconCell from "../../components/table/cells/IconCell"
import { loadAbsenceRequests } from "../../helpers/AbsenceRequestHelper"
import { dateFromDjango, isoPrint } from "../../helpers/DaysHelper"
import useResolveAbsenceRequestHandleStatusIcon from "../../hooks/UseResolveAbsenceRequestHandleStatusIcon"
import useResolveAbsenceRequestReviewStatusIcon from "../../hooks/UseResolveAbsenceRequestReviewStatusIcon"

interface AbsenceRequestViewProps {
    startOfToday: Date
    userId: number
}

const AbsenceRequestsView: FC<AbsenceRequestViewProps> = ({ startOfToday, userId }) => {
    const { t } = useTranslation()
    const resolveReviewStatusIcon = useResolveAbsenceRequestReviewStatusIcon()
    const resolveHandleStatusIcon = useResolveAbsenceRequestHandleStatusIcon()

    const { data, isLoading, isError } = useQuery(["absenceRequests", startOfToday, userId], () => loadAbsenceRequests(startOfToday, userId))

    if (isLoading || isError) {
        return null
    }

    if (data?.length === 0) {
        return (
            <tr>
                <td className="pt-2">
                    <Badge bg="light-gray" text="dark">
                        {t("Main.none")}
                    </Badge>
                </td>
            </tr>
        )
    }

    return (
        <>
            {data?.map((ar) => (
                <tr data-cy="absenceRequest" key={ar.id}>
                    <td data-cy="startDate" className="text-small">
                        {isoPrint(dateFromDjango(ar.startDate))}
                    </td>
                    <td data-cy="endDate" className="text-small">
                        {isoPrint(dateFromDjango(ar.endDate))}
                    </td>
                    <td>
                        <IconCell iconProps={resolveReviewStatusIcon(ar.reviewStatus)} />
                    </td>
                    <td>
                        <IconCell iconProps={resolveHandleStatusIcon(ar.handleStatus)} />
                    </td>
                </tr>
            ))}
        </>
    )
}

export default AbsenceRequestsView
