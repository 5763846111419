import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useColors } from "../contexts/UserSettingsContext"
import { getBorderColor, getCSSColor, getCSSColor3, getWhiteColor } from "../helpers/ColorHelper"
import { NestedRoleItem } from "../helpers/RolesHelper"
import RoleType from "../types/RoleType"
import styles from "./RoleBadge.module.scss"

interface Props {
    role?: RoleType | NestedRoleItem | null
}

const RoleBadge: FC<Props> = ({ role }) => {
    const { t } = useTranslation()
    const colors = useColors()

    const color = useMemo(() => (role ? colors.get(role.color) ?? getWhiteColor() : getBorderColor()), [colors, role])
    const name = useMemo(() => (role ? role.name : t("Main.role_free")), [role, t])

    return (
        <span className={styles.pill} style={{ backgroundColor: getCSSColor3(color, 0.2), textWrap: "nowrap" }}>
            <FontAwesomeIcon icon={faCircle} className="me-2" color={getCSSColor(color)} />
            {name}
        </span>
    )
}

export default RoleBadge
