import { getNestedRoles } from "../services/Role"
import LocationType from "../types/LocationType"
import RoleGroupType from "../types/RoleGroupType"
import RoleType from "../types/RoleType"
import { ROLE_TYPE } from "./Constants"

export function constructNestedRoleMap(nestedRoles: NestedRoleItem[]) {
    const map: Map<string, NestedRoleItem> = new Map()
    for (const item of nestedRoles) {
        map.set(item.uid, item)
        if (item.subRoles) {
            for (const j in item.subRoles) {
                const subItem = item.subRoles[j]
                map.set(subItem.uid, subItem)
            }
        }
    }
    return map
}

export function constructRoleMap(roles: RoleType[]): Map<string, RoleType> {
    var roleMap: Map<string, RoleType> = new Map()
    roles.forEach((role) => {
        roleMap.set(role.id.toString(), role)
    })
    return roleMap
}

export interface NestedRoleItem {
    id: number
    type: "role" | "roleGroup"
    uid: string
    name: string
    parent?: string
    subRoles?: NestedRoleItem[]
    orderKey: number[]
    color: number
    hasOccupationTarget: boolean
    isAbsence: boolean
}

export function createNestedRoles(roles: RoleType[], roleGroups: RoleGroupType[]): NestedRoleItem[] {
    let groupedRoles: NestedRoleItem[] = []

    for (let i in roleGroups) {
        let roleGroup = roleGroups[i]
        let item: NestedRoleItem = {
            ...roleGroup,
            isAbsence: false,
            type: "roleGroup",
            uid: "g" + roleGroup.id,
            subRoles: [],
            orderKey: [roleGroup.order],
        }
        groupedRoles.push(item)
    }

    for (let i in roles) {
        let role = roles[i]
        let roleGroupId = role.roleGroup
        let item: NestedRoleItem = {
            ...role,
            type: "role",
            uid: "r" + role.id,
            parent: roleGroupId ? "g" + roleGroupId : undefined,
            orderKey: [role.order],
        }

        if (roleGroupId) {
            let roleGroup = groupedRoles.find((item) => item.id === roleGroupId && item.type === "roleGroup")!
            item.orderKey = [...roleGroup.orderKey, ...item.orderKey]
            roleGroup.subRoles!.push(item)
        } else {
            groupedRoles.push(item)
        }
    }

    return groupedRoles.sort(compareOrderKey)
}

function compareOrderKey(a: NestedRoleItem, b: NestedRoleItem): number {
    for (let i = 0; i < Math.max(a.orderKey.length, b.orderKey.length); i++) {
        if (i >= b.orderKey.length) {
            // a = [1, 2], b = [1], i = 1
            return 1
        } else if (i >= a.orderKey.length) {
            // a = [1], b = [1, 2], i = 1
            return -1
        } else if (a.orderKey[i] !== b.orderKey[i]) {
            // a = [1, 2], b = [1, 3], i = 1
            return a.orderKey[i] - b.orderKey[i]
        }
    }
    return a.name > b.name ? 1 : -1
}

export interface RolesAndGroupsType {
    roles: RoleType[]
    roleGroups: RoleGroupType[]
}

export function loadRolesAndGroupsForLocation(location: LocationType): Promise<RolesAndGroupsType> {
    return new Promise((resolve, reject) => {
        getNestedRoles(location.id)
            .then((response) => {
                const { roles, roleGroups } = response.data
                resolve({ roles: roles, roleGroups: roleGroups })
            })
            .catch((error) => reject(error))
    })
}

const optionMapper = (s: RoleType) => {
    return { id: s.id.toString(), name: s.name }
}

export const getRoleOptionsForType = (roles: RoleType[], type: ROLE_TYPE) => {
    let filteredRoles = roles.filter((r) => r.type === type)
    if (filteredRoles.length === 0) {
        filteredRoles = roles
    }
    return filteredRoles.map(optionMapper)
}

export function getRoleAndRoleGroupOptionsWithOccupationTarget(nestedRoles: NestedRoleItem[]) {
    let options: NestedRoleItem[] = []
    if (nestedRoles) {
        for (const i in nestedRoles) {
            if (nestedRoles[i].hasOccupationTarget) {
                options.push(nestedRoles[i])
            }
            if (nestedRoles[i].subRoles) {
                for (const subRole of nestedRoles[i].subRoles!) {
                    if (subRole.hasOccupationTarget) {
                        options.push(subRole)
                    }
                }
            }
        }
    }
    return options
}

export function getRoleIds(roleUids: string[]) {
    return roleUids.filter((uid) => uid.startsWith("r")).map((uid) => parseInt(uid.substring(1)))
}

export function getRoleGroupIds(roleUids: string[]) {
    return roleUids.filter((uid) => uid.startsWith("g")).map((uid) => parseInt(uid.substring(1)))
}
