import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Badge } from "react-bootstrap"
import { ScheduleEditorContext, useScheduleEditorState } from "../../contexts/ScheduleEditorContext"
import { useCompanyModules } from "../../contexts/UserSettingsContext"
import { COMPANY_MODULE_EXTERNAL_HIRES } from "../../helpers/Constants"
import { onClickFilter } from "../../helpers/FilterHelper"
import useHiringAvailabilityStatusFilterOptions from "../../hooks/UseHiringAvailabilityStatusFilterOptons"
import useRoleFilterOptions from "../../hooks/UseRoleFilterOptions"
import { HiringAvailabilityStatusWithUnknown } from "../../types/HiringAvailabilityType"
import MultiSelectFilter from "../table/MultiSelectFilter"

interface ScheduleFiltersProps {
    checkedFreeRole: boolean
}

const ScheduleFilters: FC<ScheduleFiltersProps> = ({ checkedFreeRole }) => {
    const { setRoleFilter, setHiringAvailabilityFilter } = useContext(ScheduleEditorContext)
    const editorState = useScheduleEditorState()
    const hiringAvailabilityStatusFilterOptions = useHiringAvailabilityStatusFilterOptions()

    const companyModules = useCompanyModules()
    const [selectedRoleFilterValues, setSelectedRoleFilterValues] = useState<string[]>([])
    const [selectedHiringAvailabilities, setSelectedHiringAvailabilities] = useState<HiringAvailabilityStatusWithUnknown[]>(["AVAILABLE"])

    const options = useRoleFilterOptions(editorState.nestedRoles, true, checkedFreeRole)

    useEffect(() => {
        const roleFilterOptions = options.filter((item) => item.checked).map((item) => item.key)
        setSelectedRoleFilterValues(roleFilterOptions)
        setRoleFilter(roleFilterOptions)
        setHiringAvailabilityFilter(selectedHiringAvailabilities)
    }, [options])

    const appliedFilterCount = useMemo(() => {
        let count = 0
        if (editorState.submittedRoleFilterValues.length !== options.length) {
            count++
        }
        if (companyModules.includes(COMPANY_MODULE_EXTERNAL_HIRES) && editorState.submittedHiringAvailabilities.length !== hiringAvailabilityStatusFilterOptions.length) {
            count++
        }
        return count
    }, [editorState.submittedRoleFilterValues, options, editorState.submittedHiringAvailabilities])

    const onSubmit = useCallback(() => {
        setRoleFilter(selectedRoleFilterValues)
        setHiringAvailabilityFilter(selectedHiringAvailabilities)
    }, [selectedRoleFilterValues, selectedHiringAvailabilities])

    return (
        <div className="dropdown d-inline-block">
            <button className="btn btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Filters {appliedFilterCount > 0 ? <Badge className="notifications-badge">{appliedFilterCount}</Badge> : null}
            </button>
            <ul className="dropdown-menu filter-list text-start" onClick={onClickFilter}>
                <div className="text-bold text-muted mb-2">Taken:</div>
                <div className="mb-3" style={{ minWidth: "150px", maxHeight: "210px", overflow: "scroll" }}>
                    <MultiSelectFilter options={options} values={selectedRoleFilterValues} putFilterValue={setSelectedRoleFilterValues} toggleAllEnabled={true} autoUpdateParent={true} />
                </div>
                {companyModules.includes(COMPANY_MODULE_EXTERNAL_HIRES) ? (
                    <>
                        <div className="text-bold text-muted mb-2">Inleen:</div>
                        <div className="mb-3">
                            <MultiSelectFilter
                                options={hiringAvailabilityStatusFilterOptions}
                                values={selectedHiringAvailabilities}
                                putFilterValue={(values) => setSelectedHiringAvailabilities(values as HiringAvailabilityStatusWithUnknown[])}
                                toggleAllEnabled={false}
                                autoUpdateParent={false}
                            />
                        </div>
                    </>
                ) : null}
                <button onClick={onSubmit} className="btn btn-primary close-filters">
                    Filter
                </button>
            </ul>
        </div>
    )
}

export default ScheduleFilters
