import { ChangeEvent, FC, useCallback, useMemo, useState } from "react"
import { Badge, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import AfasLeaveBalanceType from "../../types/AfasLeaveBalanceType"
import styles from "./AfasBalanceTable.module.scss"

interface Props {
    balances?: AfasLeaveBalanceType[]
}

const AfasBalanceTable: FC<Props> = ({ balances }) => {
    const { t } = useTranslation()

    const currentYear = useMemo(() => new Date().getFullYear(), [])

    const [financialYear, setFinancialYear] = useState(currentYear.toString())

    const onChange = useCallback(
        (event: ChangeEvent) => {
            const target = event.target as HTMLSelectElement
            setFinancialYear(target.value)
        },
        [setFinancialYear]
    )

    const balance = useMemo(() => {
        return balances ? balances.filter((b) => b.financialYear.toString() === financialYear).reduce((sum, current) => sum + current.balance, 0) : 0
    }, [balances, financialYear])

    return (
        <div className="spacer">
            <Form.Group>
                <Form.Label>{t("AfasBalanceTable.financial_year")}</Form.Label>
                <Form.Select value={financialYear} onChange={onChange} style={{ width: "100px" }}>
                    <option value={currentYear.toString()}>{currentYear}</option>
                    <option value={(currentYear + 1).toString()}>{currentYear + 1}</option>
                </Form.Select>
            </Form.Group>
            <Form.Group>
                <Form.Label>{t("AfasBalanceTable.balance")}</Form.Label>
                <div>
                    <Badge bg="success">{balance}</Badge>
                </div>
            </Form.Group>
            <Form.Group>
                <Form.Label>{t("AfasBalanceTable.overview")}</Form.Label>
                <table className={`table ${styles.table}`}>
                    <thead>
                        <tr>
                            <th>{t("AfasBalanceTable.leave_type")}</th>
                            <th>{t("AfasBalanceTable.leave_code")}</th>
                            <th>{t("AfasBalanceTable.financial_year")}</th>
                            <th>{t("AfasBalanceTable.period_right")}</th>
                            <th>{t("AfasBalanceTable.extra_period_right")}</th>
                            <th>{t("AfasBalanceTable.start_balance")}</th>
                            <th>{t("AfasBalanceTable.used")}</th>
                            <th>{t("AfasBalanceTable.balance")}</th>
                            <th>{t("AfasBalanceTable.corrections")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {balances
                            ? balances.map((b) => (
                                  <tr className={b.financialYear.toString() === financialYear ? "table-success" : undefined}>
                                      <td>{b.leaveType}</td>
                                      <td>{b.leaveCode}</td>
                                      <td>{b.financialYear}</td>
                                      <td>{b.periodRight}</td>
                                      <td>{b.extraPeriodRight}</td>
                                      <td>{b.startBalance}</td>
                                      <td>{b.used}</td>
                                      <td className={b.financialYear.toString() === financialYear ? "fw-bold" : undefined}>{b.balance}</td>
                                      <td>{b.corrections}</td>
                                  </tr>
                              ))
                            : null}
                    </tbody>
                </table>
            </Form.Group>
        </div>
    )
}

export default AfasBalanceTable
