import { faChevronLeft, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import AfasLeaveBalanceType from "../../types/AfasLeaveBalanceType"
import { UserType } from "../../types/UserType"
import AfasBalanceForm from "./AfasBalanceForm"
import AfasBalanceTable from "./AfasBalanceTable"

interface Props {
    show: boolean
    close: () => void
    user?: UserType
}

const AfasBalanceModal: FC<Props> = ({ show, close, user }) => {
    const { t } = useTranslation()

    const [balances, setBalances] = useState<AfasLeaveBalanceType[]>()

    const goBackFromTable = useCallback(() => setBalances(undefined), [setBalances])

    return (
        <Modal show={show} onHide={close} size="xl">
            <Modal.Header className="justify-content-between">
                <Modal.Title>
                    {balances ? (
                        <Button type="button" variant="link" onClick={goBackFromTable} size="sm">
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </Button>
                    ) : null}
                    {t("AfasBalanceModal.title")}
                </Modal.Title>
                <Button type="button" variant="link" onClick={close}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body className="spacer">{!balances ? <AfasBalanceForm user={user} setBalances={setBalances} /> : <AfasBalanceTable balances={balances} />}</Modal.Body>
        </Modal>
    )
}

export default AfasBalanceModal
