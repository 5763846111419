import { FC, useCallback } from "react"
import { Button, Form } from "react-bootstrap"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import InfoPopover from "../../../components/InfoPopover"
import { Inputs, UnexusFileRow } from "../EditOccupationTemplateDetailsPage"
import UnexusFileRowView from "./UnexusFileRowView"

interface Props {
    watch: UseFormWatch<Inputs>
    setValue: UseFormSetValue<Inputs>
}

const getNextRowId = (rows: UnexusFileRow[]) => {
    return rows.map((r) => r.id).reduce((a, b) => Math.max(a, b), 0) + 1
}

const UnexusFileRowsView: FC<Props> = ({ watch, setValue }) => {
    const { t } = useTranslation()

    const unexusFileRows = watch("unexusFileRows")

    const addRow = useCallback(() => {
        const rows = [...unexusFileRows]
        rows.push({
            id: getNextRowId(rows),
            role: "",
            file: "",
            errors: [],
        })
        setValue("unexusFileRows", rows)
    }, [unexusFileRows, setValue])

    return (
        <Form.Group>
            <Form.Label>{t("UnexusFileRowsView.add_unexus_files")}</Form.Label>
            <InfoPopover id="unexusFileInfo" body={t("UnexusFileRowsView.add_unexus_files_description")} />
            <ul className="list-group">
                {unexusFileRows.map((row, i) => (
                    <UnexusFileRowView key={row.id} rowIndex={i} watch={watch} setValue={setValue} />
                ))}
            </ul>
            <Button type="button" variant="link" onClick={addRow} data-cy="addFileRow">
                {t("Buttons.add")}
            </Button>
        </Form.Group>
    )
}

export default UnexusFileRowsView
