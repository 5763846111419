import { ColumnDef } from "@tanstack/react-table"
import { FC, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import RegularPage from "../../components/page/RegularPage"
import Table from "../../components/table/Table"
import DateRangeCell from "../../components/table/cells/DateRangeCell"
import DateSlotCell from "../../components/table/cells/DateSlotCell"
import IconCell from "../../components/table/cells/IconCell"
import UserNameCell from "../../components/table/cells/UserNameCell"
import { useTabs } from "../../contexts/TabsContext"
import { useContinueTour } from "../../contexts/TourContext"
import { STEP_INDEX_ABSENCE_REQUEST } from "../../contexts/TourSteps"
import { useIsAdmin, useSelectedLocation, useUsersForSelectedLocation } from "../../contexts/UserSettingsContext"
import * as DaysHelper from "../../helpers/DaysHelper"
import { dateFromDjango } from "../../helpers/DaysHelper"
import { getLocationFilter } from "../../helpers/LocationHelper"
import useAbsenceRequestHandleStatusOptions from "../../hooks/UseAbsenceRequestHandleStatusOptions"
import useAbsenceRequestReviewStatusOptions from "../../hooks/UseAbsenceRequestReviewStatusOptions"
import useResolveAbsenceRequestHandleStatusIcon from "../../hooks/UseResolveAbsenceRequestHandleStatusIcon"
import useResolveAbsenceRequestReviewStatusIcon from "../../hooks/UseResolveAbsenceRequestReviewStatusIcon"
import useResolveUserName from "../../hooks/UseResolveUserName"
import { loadAbsenceRequests } from "../../services/AbsenceRequest"
import AbsenceRequestType from "../../types/AbsenceRequestType"

const AbsenceRequestsPage: FC = () => {
    const { t } = useTranslation()
    const { setActiveTab } = useTabs()
    const isAdmin = useIsAdmin()
    const location = useSelectedLocation()
    const users = useUsersForSelectedLocation()
    const resolveUserName = useResolveUserName(users)
    const continueTour = useContinueTour()
    const reviewStatusOptions = useAbsenceRequestReviewStatusOptions()
    const resolveReviewStatusIcon = useResolveAbsenceRequestReviewStatusIcon()
    const handleStatusOptions = useAbsenceRequestHandleStatusOptions()
    const resolveHandleStatusIcon = useResolveAbsenceRequestHandleStatusIcon()

    const columns = useMemo((): ColumnDef<AbsenceRequestType>[] => {
        const columns: ColumnDef<AbsenceRequestType>[] = []
        if (isAdmin) {
            columns.push({
                id: "user",
                header: t("Main.employee"),
                cell: ({ row }) => <UserNameCell userName={resolveUserName(row.original.user)} link={`/verlofaanvragen/${row.original.id}`} />,
            })
            columns.push({
                id: "from",
                header: t("Date.from"),
                cell: ({ row }) => <DateSlotCell date={dateFromDjango(row.original.startDate)} slot={row.original.startSlot} at="start" />,
            })
            columns.push({
                id: "to",
                header: t("Date.to"),
                cell: ({ row }) => <DateSlotCell date={dateFromDjango(row.original.endDate)} slot={row.original.endSlot} at="end" />,
            })
        } else {
            columns.push({
                id: "fromTo",
                header: t("Date.period"),
                cell: ({ row }) => <DateRangeCell from={row.original.startDate} to={row.original.endDate} link={`/verlofaanvragen/${row.original.id}`} />,
            })
        }
        columns.push({
            id: "reviewStatus",
            header: t("AbsenceRequestsPage.review_status"),
            cell: ({ row }) => <IconCell iconProps={resolveReviewStatusIcon(row.original.reviewStatus)} />,
        })
        columns.push({
            id: "handleStatus",
            header: t("AbsenceRequestsPage.handle_status"),
            cell: ({ row }) => <IconCell iconProps={resolveHandleStatusIcon(row.original.handleStatus)} />,
        })
        return columns
    }, [t, isAdmin, resolveUserName])

    useEffect(() => continueTour(STEP_INDEX_ABSENCE_REQUEST), [continueTour])
    useEffect(() => setActiveTab("AbsenceRequests"), [setActiveTab])

    const locationFilter = useMemo(() => getLocationFilter(location), [location])

    return (
        <RegularPage id="AbsenceRequests">
            <h2>{t("AbsenceRequestsPage.title")}</h2>
            <Table
                id={`${location.id}_AbsenceRequests`}
                columns={columns}
                buttons={[{ name: t("AbsenceRequestsPage.new_absence_request"), link: `/verlofaanvragen/nieuw` }]}
                filters={[
                    locationFilter,
                    {
                        id: "date",
                        type: "daterange",
                        label: t("AbsenceRequestsPage.filter_period"),
                        initialValues: [
                            { key: "dateFrom", value: DaysHelper.isoPrint(new Date()) },
                            { key: "dateTo", value: "" },
                        ],
                    },
                    {
                        id: "user",
                        type: isAdmin ? "picker" : "hidden",
                        label: t("AbsenceRequestsPage.filter_user"),
                        placeholder: t("AbsenceRequestsPage.filter_user_placeholder"),
                        options: users.map((user) => {
                            return { key: user.id.toString(), title: `${user.firstName} ${user.lastName}` }
                        }),
                        initialValues: [{ key: "user", value: "" }],
                    },
                    {
                        id: "reviewStatus",
                        type: isAdmin ? "picker" : "hidden",
                        label: t("AbsenceRequestsPage.filter_review_status"),
                        placeholder: t("AbsenceRequestsPage.filter_review_status_placeholder"),
                        options: reviewStatusOptions.map(({ id, name }) => {
                            return { key: id, title: name }
                        }),
                        initialValues: [{ key: "reviewStatus", value: "" }],
                    },
                    {
                        id: "handleStatus",
                        type: isAdmin ? "picker" : "hidden",
                        label: t("AbsenceRequestsPage.filter_handle_status"),
                        placeholder: t("AbsenceRequestsPage.filter_handle_status_placeholder"),
                        options: handleStatusOptions.map(({ id, name }) => {
                            return { key: id, title: name }
                        }),
                        initialValues: [{ key: "handleStatus", value: "" }],
                    },
                ]}
                fetchData={loadAbsenceRequests}
                subject={t("AbsenceRequestsPage.no_items_in_table_name")}
                minWidth="700px"
            />
        </RegularPage>
    )
}

export default AbsenceRequestsPage
