import { faCircleCheck, faCircleNotch, faCircleXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useMemo } from "react"
import { EnvironmentGenerationStepStatus } from "../../types/EnvironmentGenerationType"

interface EnvinromentGenerationStepProps {
    index: number
    title: string
    status: EnvironmentGenerationStepStatus
}

const getColor = (status: EnvironmentGenerationStepStatus) => {
    switch (status) {
        case EnvironmentGenerationStepStatus.FAILED:
            return "red"
        case EnvironmentGenerationStepStatus.SUCCEEDED:
            return "green"
        default:
            return undefined
    }
}

const EnvinromentGenerationStep: FC<EnvinromentGenerationStepProps> = ({ index, title, status }) => {
    const icon = useMemo(() => {
        if (status === EnvironmentGenerationStepStatus.TODO || status === EnvironmentGenerationStepStatus.EXECUTING) {
            return faCircleNotch
        } else if (status === EnvironmentGenerationStepStatus.FAILED) {
            return faCircleXmark
        } else if (status === EnvironmentGenerationStepStatus.SUCCEEDED) {
            return faCircleCheck
        }
    }, [status])

    return (
        <div className="d-flex">
            <h4 className="me-2">
                {icon ? (
                    <FontAwesomeIcon
                        icon={icon}
                        spin={status === EnvironmentGenerationStepStatus.EXECUTING}
                        beat={status === EnvironmentGenerationStepStatus.FAILED || status === EnvironmentGenerationStepStatus.SUCCEEDED}
                        style={{
                            animationIterationCount: status === EnvironmentGenerationStepStatus.EXECUTING ? undefined : 1,
                            animationDuration: "500ms",
                            animationDelay: `${index * 100}ms`,
                            color: getColor(status),
                        }}
                    />
                ) : null}
            </h4>
            {title}
        </div>
    )
}

export default EnvinromentGenerationStep
