import { faArrowRightArrowLeft, faCheck, faUserLock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ColumnDef } from "@tanstack/react-table"
import { FC, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import RegularPage from "../../components/page/RegularPage"
import Table from "../../components/table/Table"
import { useTabs } from "../../contexts/TabsContext"
import { useContinueTour } from "../../contexts/TourContext"
import { STEP_INDEX_USERS } from "../../contexts/TourSteps"
import { useSelectedLocation } from "../../contexts/UserSettingsContext"
import { getLocationFilter } from "../../helpers/LocationHelper"
import { loadDetailedUsers } from "../../services/User"
import { UserDetailedType } from "../../types/UserType"

const UsersPage: FC = () => {
    const { t } = useTranslation()
    const location = useSelectedLocation()
    const { setActiveTab } = useTabs()
    const continueTour = useContinueTour()

    useEffect(() => continueTour(STEP_INDEX_USERS), [continueTour])
    useEffect(() => setActiveTab("Users"), [setActiveTab])

    const columns = useMemo(
        (): ColumnDef<UserDetailedType>[] => [
            {
                id: "name",
                header: t("UsersPage.name"),
                cell: ({ row }) => {
                    return (
                        <Link to={"/medewerkers/" + row.original.id} data-cy="tableItem">
                            {row.original.type === "ADMIN" ? <FontAwesomeIcon icon={faUserLock} size="sm" className="me-2" /> : null}
                            {row.original.firstName + " " + row.original.lastName}
                            {row.original.type === "EXTERNAL" ? <FontAwesomeIcon icon={faArrowRightArrowLeft} size="sm" className="ms-2" /> : null}
                        </Link>
                    )
                },
            },
            {
                id: "email",
                header: t("UsersPage.email"),
                cell: ({ row }) => row.original.email,
            },
            {
                id: "isActive",
                header: t("UsersPage.active"),
                cell: ({ row }) => {
                    if (row.original.isActive) {
                        return <FontAwesomeIcon icon={faCheck} className="font-success" />
                    } else {
                        return null
                    }
                },
            },
        ],
        []
    )

    const locationFilter = useMemo(() => getLocationFilter(location), [location])

    return (
        <RegularPage id="Users">
            <h2>{t("UsersPage.title_employees")}</h2>
            <Table
                id={`${location.id}_Users`}
                columns={columns}
                buttons={[{ name: t("UsersPage.register_user"), link: "/medewerkers/nieuw" }]}
                filters={[
                    locationFilter,
                    { id: "active", type: "toggle", label: t("UsersPage.show_active_only"), initialValues: [{ key: "active", value: "" }] },
                    { id: "employablenow", type: "toggle", label: t("UsersPage.show_employable_only"), initialValues: [{ key: "employablenow", value: "true" }] },
                ]}
                fetchData={loadDetailedUsers}
                subject={t("UsersPage.employees")}
                minWidth="600px"
            />
        </RegularPage>
    )
}

export default UsersPage
