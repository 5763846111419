import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import DateSelector, { DateLabelType } from "../../components/DateSelector"
import Loader from "../../components/Loader"
import FormErrorMessages from "../../components/form/FormErrorMessages"
import FormSuccessMessage from "../../components/form/FormSuccessMessage"
import NumberOfWeeksSelector from "../../components/headlineControls/NumberOfWeeksSelector"
import HireAvailabilityArray from "../../components/hireAvailabilities/HireAvailabilityArray"
import RegularPage from "../../components/page/RegularPage"
import { useTabs } from "../../contexts/TabsContext"
import { useCurrentUser } from "../../contexts/UserSettingsContext"
import { addDays, dateToDjango, getFirstMondayOfWeek, getFirstNextWorkday, getFirstPreviousWorkday, getSlot, getTimeString, isEnabledDate, isoPrint, timeToDate } from "../../helpers/DaysHelper"
import useDetailedUser from "../../hooks/UseDetailedUser"
import { getHiringAvailabilities, saveAllHiringAvailabilities } from "../../services/HiringAvailability"
import { EditingHiringAvailabilityType, UpsertHiringAvailabilityType } from "../../types/HiringAvailabilityType"
import styles from "./HireAvailabilitiesUserPage.module.scss"

export interface HiringAvailabilitiesFormProps {
    availabilities: EditingHiringAvailabilityType[]
}

const HireAvailabilitiesUserPage: FC = () => {
    const { t } = useTranslation()
    const { setActiveTab } = useTabs()
    const { id: userId, enabledDays } = useCurrentUser()
    const { data: detailedUser, isLoading: isLoadingDetailedUser } = useDetailedUser(userId)

    const today = new Date()
    const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate())
    const startOfFirstNextWorkday = getFirstNextWorkday(startOfToday, enabledDays)
    const startOfCurrentWeek = getFirstMondayOfWeek(startOfFirstNextWorkday)
    const [numberOfWeeks, setNumberOfWeeks] = useState<number>(2)
    const [startOfWeek, setStartOfWeek] = useState<Date>(startOfCurrentWeek)
    const [selectedDate, setSelectedDate] = useState<Date>(startOfFirstNextWorkday)

    const [succeededId, setSucceededId] = useState(0)
    const [availabilitiesErrors, setAvailabilitiesErrors] = useState([])
    const [formErrors, setFormErrors] = useState<string[]>([])

    const getItems = useCallback(() => {
        return new Promise<HiringAvailabilitiesFormProps>((resolve) => {
            const dateFrom = startOfWeek
            const dateTo = addDays(startOfWeek, numberOfWeeks * 7 - 1)
            getHiringAvailabilities({
                pageIndex: 0,
                pageSize: 999,
                filters: [
                    { key: "user", value: userId.toString() },
                    { key: "dateFrom", value: isoPrint(dateFrom) },
                    { key: "dateTo", value: isoPrint(dateTo) },
                ],
            }).then((response) => {
                const availabilities = response.data.items
                const items: EditingHiringAvailabilityType[] = []
                for (let date = dateFrom; date <= dateTo; date = addDays(date, 1)) {
                    if (!isEnabledDate(enabledDays, date)) {
                        continue
                    }
                    const dateString = dateToDjango(date)
                    const availability = availabilities.find((a) => a.date === dateString)
                    items.push({
                        id: availability?.id,
                        date: date,
                        status: availability?.status ?? "UNKNOWN",
                        fromTime: availability?.startSlot === 0 || !!availability?.startSlot ? getTimeString("start", availability.startSlot) : "",
                        toTime: availability?.endSlot === 0 || !!availability?.endSlot ? getTimeString("end", availability.endSlot) : "",
                        message: availability?.message ?? "",
                    })
                }
                resolve({ availabilities: items })
            })
        })
    }, [userId, enabledDays, startOfWeek, numberOfWeeks])

    const {
        control,
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { isLoading: isLoadingValues },
        reset,
    } = useForm({
        defaultValues: getItems,
    })

    const updateStartOfWeek = () => {
        const newStartOfWeek = getFirstMondayOfWeek(selectedDate)
        if (newStartOfWeek.getTime() !== startOfWeek.getTime()) {
            setStartOfWeek(newStartOfWeek)
        }
    }

    const reloadItems = useCallback(() => {
        getItems().then((response) => reset(response))
    }, [getItems])

    useEffect(reloadItems, [startOfWeek, numberOfWeeks])
    useEffect(() => setActiveTab("HireAvailabilities"), [setActiveTab])
    useEffect(updateStartOfWeek, [selectedDate, setStartOfWeek])

    const onSuccess = useCallback(() => {
        setSucceededId(succeededId + 1)
        reloadItems()
    }, [setSucceededId, succeededId])

    const onFailure = useCallback((error: any) => {
        const data = error.response && error.response.data ? error.response.data : {}
        setAvailabilitiesErrors(data.availabilities || [])
        setFormErrors(data.nonFieldErrors || [t("Main.something_went_wrong")])
    }, [])

    const onSubmit = (data: HiringAvailabilitiesFormProps) => {
        const items: UpsertHiringAvailabilityType[] = data.availabilities
            .filter((a) => a.id || a.status !== "UNKNOWN")
            .map((a) => {
                return {
                    id: a.id,
                    date: isoPrint(a.date),
                    status: a.status,
                    startSlot: getSlot("start", timeToDate(a.fromTime)),
                    endSlot: getSlot("end", timeToDate(a.toTime)),
                    message: a.message,
                }
            })
        saveAllHiringAvailabilities(items).then(onSuccess).catch(onFailure)
    }

    const prevWeek = useCallback(() => setSelectedDate(getFirstPreviousWorkday(addDays(selectedDate, -7), enabledDays)), [setSelectedDate, selectedDate, enabledDays])

    const nextWeek = useCallback(() => setSelectedDate(getFirstNextWorkday(addDays(selectedDate, 7), enabledDays)), [setSelectedDate, selectedDate, enabledDays])

    return (
        <RegularPage id="HireAvailabilities">
            <div className="d-flex">
                <h2 style={{ flex: "1 1 auto" }}>{t("HireAvailabilitiesUserPage.title")}</h2>
                <div style={{ flex: "0 0 auto" }}>
                    <div className="d-inline-block me-1">
                        <NumberOfWeeksSelector options={[2, 4, 8]} setNumberOfWeeks={setNumberOfWeeks} />
                    </div>
                    <Button size="sm" variant="link" data-cy="previousWeek" onClick={prevWeek}>
                        <FontAwesomeIcon icon={faCaretLeft} />
                    </Button>
                    <Button size="sm" variant="link" data-cy="nextWeek" onClick={nextWeek}>
                        <FontAwesomeIcon icon={faCaretRight} />
                    </Button>
                    <DateSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} labelType={DateLabelType.NONE} />
                </div>
            </div>
            <div className="container-scroll">
                {isLoadingValues || isLoadingDetailedUser || !detailedUser ? (
                    <Loader />
                ) : (
                    <Form noValidate onSubmit={handleSubmit(onSubmit)} className="spacer">
                        <table className={styles.table}>
                            <thead>
                                <tr className="text-bold">
                                    <td></td>
                                    <td className="ps-2 pb-3">{t("HireAvailabilitiesUserPage.available")}</td>
                                    <td className="ps-2 pb-3">{t("HireAvailabilitiesUserPage.time")}</td>
                                    <td className="ps-2 pb-3">{t("HireAvailabilitiesUserPage.message")}</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                <HireAvailabilityArray control={control} register={register} setValue={setValue} watch={watch} detailedUser={detailedUser} />
                            </tbody>
                        </table>
                        <div>
                            <Button type="submit" data-cy="save">
                                {t("Buttons.save")}
                            </Button>
                        </div>
                        <FormErrorMessages errors={availabilitiesErrors} />
                        <FormErrorMessages errors={formErrors} />
                        <FormSuccessMessage succeededId={succeededId} />
                    </Form>
                )}
            </div>
        </RegularPage>
    )
}

export default HireAvailabilitiesUserPage
