import { FC } from "react"
import { Form } from "react-bootstrap"
import { FieldErrors } from "react-hook-form"

interface Props {
    errors: FieldErrors<any>
}

const RootFeedback: FC<Props> = ({ errors }) => {
    return (
        <Form.Group hidden={!errors.root}>
            <Form.Control type="hidden" isInvalid={!!errors.root} />
            <Form.Control.Feedback type="invalid">{errors.root?.message}</Form.Control.Feedback>
        </Form.Group>
    )
}

export default RootFeedback
