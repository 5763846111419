import { UserAvailabilityType } from "./UserAvailabilityType"

export type UserTypeType = "ADMIN" | "REGULAR" | "EXTERNAL"

export interface UserType {
    id: number
    firstName: string
    lastName: string
    type: UserTypeType
    employableFrom: string
    employableTo: string
    locations: number[]
    weekCycle: number
    enabledDays: string
    startSlot: number
    endSlot: number
    order: number
}

export interface RolePreferenceType {
    role: number
    priority: number
}

export interface EditUserAvailabilityType {
    startTime?: string
    endTime?: string
    day: number
}

export interface UserDetailedType extends UserType {
    initMode: number
    email: string
    isActive: boolean
    availabilities: UserAvailabilityType[]
    rolePreferences: RolePreferenceType[]
    hasUsablePassword: boolean
}

export enum UserInitMode {
    CREATED_ACCOUNT = 0,
    ACTIVATED_ACCOUNT = 1,
    INITIALIZING_ENVIRONMENT = 2,
    INITIALIZED_ENVIRONMENT = 3,
    STARTED_TOUR = 4,
    DONE = 10,
}

export type UserInvitationMode = "PASSWORD" | "SSO"

export interface CreateUserType {
    email: string
    firstName: string
    lastName: string
    type: UserTypeType
    employableFrom?: string
    employableTo?: string
    weekCycle: number
    locations: number[]
    rolePreferences: RolePreferenceType[]
    availabilities: { day: number; startSlot?: number; endSlot?: number }[]
    company: number
    invitationMode?: UserInvitationMode
}

export interface UpdateUserType {
    id: number
    email: string
    firstName: string
    lastName: string
    type: UserTypeType
    employableFrom?: string
    employableTo?: string
    weekCycle: number
    locations: number[]
    rolePreferences: RolePreferenceType[]
    availabilities: { day: number; startSlot?: number; endSlot?: number }[]
}

export interface UpdateMyUserType {
    id: number
    firstName: string
    lastName: string
}

export interface UpdateUserInitModeType {
    id: number
    initMode: UserInitMode
}
