import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import UserTabComponent from "./UserTabComponent"

export type UserTab = "profile" | "locations" | "times" | "roles"

interface UserTabsProps {
    selectedTab: UserTab
    setSelectedTab: (userTab: UserTab) => void
}

const UserTabs: FC<UserTabsProps> = ({ selectedTab, setSelectedTab }) => {
    const { t } = useTranslation()

    const userTabs = useMemo(() => {
        const userTabs: { tab: UserTab; label: string }[] = []
        userTabs.push({ tab: "profile", label: t("UserTabs.profile") })
        userTabs.push({ tab: "locations", label: t("UserTabs.locations") })
        userTabs.push({ tab: "times", label: t("UserTabs.times") })
        userTabs.push({ tab: "roles", label: t("UserTabs.roles") })
        return userTabs
    }, [t])

    return (
        <div className="overflow-auto">
            <ul className="selectable-segment me-3 mb-0">
                {userTabs.map(({ tab, label }) => (
                    <UserTabComponent key={tab} tab={tab} label={label} active={tab === selectedTab} setSelectedTab={setSelectedTab} />
                ))}
            </ul>
        </div>
    )
}

export default UserTabs
