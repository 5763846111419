import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import StaticWeekSchedule from "../../components/dashboard/staticWeek/StaticWeekSchedule"
import DateSelector, { DateLabelType } from "../../components/DateSelector"
import DateControl from "../../components/headlineControls/DateControl"
import NumberOfWeeksSelector from "../../components/headlineControls/NumberOfWeeksSelector"
import UserSelect from "../../components/headlineControls/UserSelect"
import RegularPage from "../../components/page/RegularPage"
import { useTabs } from "../../contexts/TabsContext"
import { useSelectedLocation, useUsersForSelectedLocation } from "../../contexts/UserSettingsContext"
import { addDays, getFirstMondayOfWeek, getFirstNextWorkday, getWeekNumber } from "../../helpers/DaysHelper"
import { ScheduleMap, getScheduleMap } from "../../helpers/ScheduleMapHelper"
import { getPersonalShiftsAndLocalities } from "../../services/Schedule"

const WeekPlanningPage = () => {
    const { t } = useTranslation()
    const { setActiveTab } = useTabs()
    const location = useSelectedLocation()
    const allUsers = useUsersForSelectedLocation()

    const startOfFirstNextWorkday = useMemo(() => {
        const today = new Date()
        const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        return getFirstNextWorkday(startOfToday, location.enabledDays)
    }, [location])

    const [selectedDate, setSelectedDate] = useState(startOfFirstNextWorkday)
    const [selectedUser, setSelectedUser] = useState(allUsers[0].id)
    const [numberOfWeeks, setNumberOfWeeks] = useState(1)
    const [scheduleMap, setScheduleMap] = useState<ScheduleMap>(new Map())

    const startOfSelectedWeek = useMemo(() => getFirstMondayOfWeek(selectedDate), [selectedDate])

    const loadShifts = useCallback(
        (location: number, user: number, fromDate: Date, numberOfWeeks: number) => {
            getPersonalShiftsAndLocalities([location], user, fromDate.getTime(), numberOfWeeks * 7).then((response) => {
                const { shifts, shiftMutations, localities, localityMutations } = response.data
                setScheduleMap(getScheduleMap(shifts, shiftMutations, localities, localityMutations))
            })
        },
        [setScheduleMap]
    )

    useEffect(() => loadShifts(location.id, selectedUser, startOfSelectedWeek, numberOfWeeks), [location, selectedUser, startOfSelectedWeek, numberOfWeeks])

    useEffect(() => setActiveTab("Dashboard"), [setActiveTab])

    return (
        <RegularPage id="WeekPlanning" breadcrumbs={[{ title: "Dashboard", href: "/dashboard" }, { title: "Weekplanning" }]}>
            <div className="mb-4">
                <div className="d-flex align-items-baseline justify-content-between">
                    <h2>
                        {t("Main.week_number", { week: getWeekNumber(startOfSelectedWeek) })}
                        {numberOfWeeks > 1 ? `-${getWeekNumber(addDays(startOfSelectedWeek, (numberOfWeeks - 1) * 7))}` : ""}
                    </h2>
                    <div className="selectable-segment">
                        <div className="me-1">
                            <UserSelect setSelectedUser={setSelectedUser} />
                        </div>
                        <div className="me-1">
                            <NumberOfWeeksSelector options={[1, 2, 4]} setNumberOfWeeks={setNumberOfWeeks} />
                        </div>
                        <DateSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} labelType={DateLabelType.NONE} />
                        <DateControl mode="PREVIOUS" unit="WEEK" selectedDate={selectedDate} setSelectedDate={setSelectedDate} enabledDays={location.enabledDays} />
                        <DateControl mode="NEXT" unit="WEEK" selectedDate={selectedDate} setSelectedDate={setSelectedDate} enabledDays={location.enabledDays} />
                    </div>
                </div>
            </div>
            <StaticWeekSchedule fromDate={startOfSelectedWeek} numberOfWeeks={numberOfWeeks} scheduleMap={scheduleMap} showLocation={false} />
        </RegularPage>
    )
}

export default WeekPlanningPage
