import { FC } from "react"
import { Col, Row } from "react-bootstrap"

interface Props {
    text: string
}

const Divider: FC<Props> = ({ text }) => {
    return (
        <Row>
            <Col>
                <hr />
            </Col>
            <Col xs="auto" className="m-auto text-small text-muted">
                {text}
            </Col>
            <Col>
                <hr />
            </Col>
        </Row>
    )
}

export default Divider
