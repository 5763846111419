import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { ChangeEvent, FC, MouseEvent, useCallback, useMemo } from "react"
import { Button, Form } from "react-bootstrap"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"
import Select from "react-select"
import ClearIndicator from "../../../components/select/ClearIndicator"
import DropdownIndicator from "../../../components/select/DropdownIndicator"
import styles from "../../../components/select/InlineSelectStyles"
import InlineSelectTheme from "../../../components/select/InlineSelectTheme"
import MultiValueRemove from "../../../components/select/MultiValueRemove"
import { useNestedRolesForSelectedLocation } from "../../../contexts/UserSettingsContext"
import { getRoleAndRoleGroupOptionsWithOccupationTarget } from "../../../helpers/RolesHelper"
import UnexusRouterType from "../../../types/UnexusRouterType"
import { Inputs } from "../EditOccupationTemplateDetailsPage"

interface Props {
    rowIndex: number
    watch: UseFormWatch<Inputs>
    setValue: UseFormSetValue<Inputs>
    unexusRouters: { id: string; unexusName: string }[]
}

const UnexusConnectionRowView: FC<Props> = ({ rowIndex, watch, setValue, unexusRouters }) => {
    const nestedRoles = useNestedRolesForSelectedLocation()
    const roleOptions = useMemo(() => {
        return getRoleAndRoleGroupOptionsWithOccupationTarget(nestedRoles)
    }, [nestedRoles])
    const unexusConnectionRows = watch("unexusConnectionRows")
    const row = useMemo(() => unexusConnectionRows[rowIndex], [unexusConnectionRows, rowIndex])

    const handleConnectionRoleSelect = useCallback(
        (event: ChangeEvent<HTMLSelectElement>) => {
            const target = event.target as HTMLSelectElement
            const rows = [...unexusConnectionRows]
            rows[rowIndex].roleUid = target.value
            setValue("unexusConnectionRows", rows)
        },
        [unexusConnectionRows, setValue]
    )

    const onChangeRouter = useCallback(
        (newValue: unknown) => {
            const rows = [...unexusConnectionRows]
            if (!newValue) {
                rows[rowIndex].routers = []
            } else {
                rows[rowIndex].routers = newValue as UnexusRouterType[]
            }
            setValue("unexusConnectionRows", rows)
        },
        [unexusConnectionRows, setValue]
    )

    const deleteRow = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            const target = event.target as HTMLButtonElement
            const rowId = parseInt(target.id.substring(9))
            const rows = [...unexusConnectionRows]
            const index = rows.findIndex((r) => r.id === rowId)
            rows.splice(index, 1)
            setValue("unexusConnectionRows", rows)
        },
        [unexusConnectionRows]
    )

    const getOptionLabel = useCallback((option: unknown) => (option as UnexusRouterType).unexusName, [])
    const getOptionValue = useCallback((option: unknown) => (option as UnexusRouterType).id.toString(), [])

    return (
        <tr>
            <td className="pb-2" style={{ minWidth: "150px" }}>
                <Form.Select value={row.roleUid} onChange={handleConnectionRoleSelect} isInvalid={false} data-cy="role">
                    <option key="empty" value="">
                        {t("Main.choose")}
                    </option>
                    {roleOptions.map((r) => (
                        <option key={r.uid} value={r.uid}>
                            {r.name}
                        </option>
                    ))}
                </Form.Select>
            </td>
            <td className="pb-2 ps-2" style={{ minWidth: "150px" }}>
                <div style={{ width: "300px" }}>
                    <div className="form-group">
                        <Select
                            components={{ ClearIndicator, DropdownIndicator, MultiValueRemove }}
                            isMulti
                            onChange={onChangeRouter}
                            placeholder={t("UnexusConnectionRowView.choose_router")}
                            options={unexusRouters}
                            getOptionLabel={getOptionLabel}
                            getOptionValue={getOptionValue}
                            styles={styles}
                            theme={InlineSelectTheme}
                        />
                    </div>
                </div>
            </td>
            <td className="pb-2 ps-2">
                <Button type="button" id={"delete_b_" + row.id} variant="link" onClick={deleteRow}>
                    <FontAwesomeIcon id={"delete_i_" + row.id} icon={faTrashAlt} />
                </Button>
            </td>
        </tr>
    )
}

export default UnexusConnectionRowView
