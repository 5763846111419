import { ColumnDef } from "@tanstack/react-table"
import { FC, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import RegularPage from "../../components/page/RegularPage"
import Table from "../../components/table/Table"
import UserNameCell from "../../components/table/cells/UserNameCell"
import { useTabs } from "../../contexts/TabsContext"
import { useEmployableUsersForSelectedLocation, useIsAdmin, useSelectedLocation } from "../../contexts/UserSettingsContext"
import { addMonths, dateFromDjango, isoPrint } from "../../helpers/DaysHelper"
import { getLocationFilter } from "../../helpers/LocationHelper"
import useHiringAvailabilityStatusOptions from "../../hooks/UseHiringAvailabilityStatusOptons"
import useResolveHiringAvailabilityStatusLabel from "../../hooks/UseResolveHiringAvailabilityStatusLabel"
import useResolveUserName from "../../hooks/UseResolveUserName"
import { loadHiringAvailabilities } from "../../services/HiringAvailability"
import HiringAvailabilityType from "../../types/HiringAvailabilityType"

const HireAvailabilitiesAdminPage: FC = () => {
    const { t } = useTranslation()
    const { setActiveTab } = useTabs()
    const location = useSelectedLocation()
    const isAdmin = useIsAdmin()
    const users = useEmployableUsersForSelectedLocation()
    const hiringAvailabilityStatusOptions = useHiringAvailabilityStatusOptions()
    const resolveUserName = useResolveUserName(users)
    const resolveHiringAvailabilityStatusLabel = useResolveHiringAvailabilityStatusLabel()

    const columns = useMemo(
        (): ColumnDef<HiringAvailabilityType>[] => [
            {
                id: "user",
                header: t("HireAvailabilitiesAdminPage.employee"),
                cell: ({ row }) => <UserNameCell userName={resolveUserName(row.original.user)} link={`/beschikbaarheid/${row.original.id}`} />,
            },
            {
                id: "date",
                header: t("Main.date"),
                cell: ({ row }) => <span className="text-no-wrap">{isoPrint(dateFromDjango(row.original.date))}</span>,
            },
            {
                id: "status",
                header: t("HireAvailabilitiesAdminPage.available"),
                cell: ({ row }) => <span>{resolveHiringAvailabilityStatusLabel(row.original.status)}</span>,
            },
            {
                id: "message",
                header: t("HireAvailabilitiesAdminPage.message"),
                cell: ({ row }) => <div>{row.original.message}</div>,
            },
        ],
        [t, resolveUserName, resolveHiringAvailabilityStatusLabel]
    )

    const locationFilter = useMemo(() => getLocationFilter(location), [location])

    useEffect(() => setActiveTab("HireAvailabilities"), [setActiveTab])

    return (
        <RegularPage id="HireAvailabilities">
            <h2>{t("HireAvailabilitiesAdminPage.title")}</h2>
            <Table
                id={`${location.id}_HiringAvailabilities`}
                columns={columns}
                buttons={[]}
                filters={[
                    locationFilter,
                    {
                        id: "date",
                        type: "daterange",
                        label: t("HireAvailabilitiesAdminPage.filter_period"),
                        initialValues: [
                            { key: "dateFrom", value: isoPrint(new Date()) },
                            { key: "dateTo", value: isoPrint(addMonths(new Date(), 1)) },
                        ],
                    },
                    {
                        id: "user",
                        type: isAdmin ? "picker" : "hidden",
                        label: t("HireAvailabilitiesAdminPage.filter_user"),
                        placeholder: t("HireAvailabilitiesAdminPage.filter_user_placeholder"),
                        options: users.map((user) => {
                            return { key: user.id.toString(), title: `${user.firstName} ${user.lastName}` }
                        }),
                        initialValues: [{ key: "user", value: "" }],
                    },
                    {
                        id: "status",
                        type: "picker",
                        label: t("HireAvailabilitiesAdminPage.filter_available"),
                        placeholder: t("HireAvailabilitiesAdminPage.filter_available_placeholder"),
                        options: hiringAvailabilityStatusOptions.map((status) => {
                            return { key: status.id, title: status.name }
                        }),
                        initialValues: [{ key: "status", value: "" }],
                    },
                ]}
                fetchData={loadHiringAvailabilities}
                subject={t("HireAvailabilitiesAdminPage.no_items_in_table_name")}
                minWidth="600px"
            />
        </RegularPage>
    )
}

export default HireAvailabilitiesAdminPage
