import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FilterSelectOption } from "../components/table/TableFilters"
import { NestedRoleItem } from "../helpers/RolesHelper"

interface RoleFilterOption extends FilterSelectOption {
    checked: boolean
}

const useRoleFilterOptions = (nestedRoles: NestedRoleItem[], includeFreeRole: boolean, checkedFreeRole: boolean) => {
    const { t } = useTranslation()

    return useMemo(() => {
        let roleFilterOptions: RoleFilterOption[] = []
        if (nestedRoles) {
            if (includeFreeRole) {
                roleFilterOptions.push({ key: "0", title: t("Main.role_free"), checked: checkedFreeRole, parent: undefined, children: undefined })
            }
            for (let i in nestedRoles) {
                roleFilterOptions.push(getRoleFilterOption(nestedRoles[i]))
                if (nestedRoles[i].subRoles) {
                    for (const subRole of nestedRoles[i].subRoles!) {
                        roleFilterOptions.push(getRoleFilterOption(subRole))
                    }
                }
            }
        }
        return roleFilterOptions
    }, [nestedRoles, includeFreeRole, checkedFreeRole, t])
}

function getRoleFilterOption(item: NestedRoleItem) {
    return {
        key: item.uid,
        title: item.name,
        checked: true,
        parent: item.parent,
        children: item.subRoles ? item.subRoles.map((i) => "r" + i.id) : undefined,
    } as RoleFilterOption
}

export default useRoleFilterOptions
