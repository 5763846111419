import { FC, useCallback, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import OccupationEditor from "../../components/occupationEditor/OccupationEditor"
import RegularPage from "../../components/page/RegularPage"
import { OccupationEditorContextProvider } from "../../contexts/OccupationEditorContext"
import { useTabs } from "../../contexts/TabsContext"
import { useSelectedLocation } from "../../contexts/UserSettingsContext"
import { getFirstEnabledDayIndex } from "../../helpers/DaysHelper"
import { loadOccupationScheduleForOccupationTemplate } from "../../helpers/OccupationHelper"
import { getOccupationTemplate } from "../../services/OccupationTemplate"
import LocationType from "../../types/LocationType"
import OccupationTemplateType from "../../types/OccupationTemplateType"

const UpdateOccupationTemplatePage: FC = () => {
    const { t } = useTranslation()
    const { setActiveTab } = useTabs()
    const location = useSelectedLocation()
    const params = useParams()
    const id = useMemo(() => parseInt(params.id!), [params])
    const initialDay = useMemo(() => getFirstEnabledDayIndex(location.enabledDays), [location])
    const navigate = useNavigate()

    const loadOccupationTemplate = () => {
        return new Promise<OccupationTemplateType>((resolve) => {
            getOccupationTemplate(id).then((response) => {
                if (response.data.location !== location.id) {
                    navigate("/basisbezettingen")
                    return
                }
                resolve(response.data)
            })
        })
    }
    const loadSchedule = useCallback(
        (occupationTemplate: OccupationTemplateType | null, _location: LocationType | null, _date: Date | null) => loadOccupationScheduleForOccupationTemplate(occupationTemplate!),
        []
    )

    useEffect(() => setActiveTab("OccupationTemplates"), [setActiveTab])

    return (
        <RegularPage id="UpdateOccupationTemplate" breadcrumbs={[{ title: t("OccupationTemplatesPage.title_occupation_templates"), href: "/basisbezettingen" }, { title: id.toString() }]}>
            <OccupationEditorContextProvider initialLocation={location} initialDay={initialDay} loadOccupationTemplate={loadOccupationTemplate} loadSchedule={loadSchedule}>
                <OccupationEditor mode="DAYS" />
            </OccupationEditorContextProvider>
        </RegularPage>
    )
}

export default UpdateOccupationTemplatePage
