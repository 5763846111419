import { ChangeEvent, FC, useCallback } from "react"
import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"

interface Props {
    options: number[]
    setNumberOfWeeks: (numberOfWeeks: number) => void
}

const NumberOfWeeksSelector: FC<Props> = ({ options, setNumberOfWeeks }) => {
    const { t } = useTranslation()

    const onChangeNumberOfWeeks = useCallback(
        (event: ChangeEvent<HTMLSelectElement>) => {
            setNumberOfWeeks(parseInt(event.target.value))
        },
        [setNumberOfWeeks]
    )

    return (
        <Form.Select className="form-select-inline" onChange={onChangeNumberOfWeeks} data-cy="weekSelect">
            {options.map((w) => (
                <option key={w} value={w}>
                    {t("Main.weeks", { count: w })}
                </option>
            ))}
        </Form.Select>
    )
}

export default NumberOfWeeksSelector
