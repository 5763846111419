import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback } from "react"
import { Button } from "react-bootstrap"
import { useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { ROLE_TYPE_LEAVE, ROLE_TYPE_REGULAR, ROLE_TYPE_SICK } from "../../helpers/Constants"

interface RoleArrayProps {
    control: any
    register: any
    setValue: any
    getValues: any
}

const RoleArray: FC<RoleArrayProps> = ({ control, register }) => {
    const { t } = useTranslation()
    const { fields, append, remove } = useFieldArray({ control, name: "roles" })

    const onAdd = useCallback(() => append({ name: "", type: ROLE_TYPE_REGULAR }), [append])

    return (
        <>
            <table>
                <thead>
                    <tr>
                        <td className="ps-2"></td>
                        <td className="ps-2">
                            <span className="input-label">{t("Forms.name")}</span>
                        </td>
                        <td className="ps-2">
                            <span className="input-label">{t("Forms.type")}</span>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="p-2">
                            <span className="text-small text-muted">1</span>
                        </td>
                        <td className="p-2">
                            <input disabled className="form-control" value={t("RoleArray.type_leave_default")} />
                        </td>
                        <td className="p-2">
                            <select disabled className="form-select form-control" value={ROLE_TYPE_LEAVE}>
                                <option value={ROLE_TYPE_REGULAR}>{t("RoleArray.type_regular")}</option>
                                <option value={ROLE_TYPE_LEAVE}>{t("RoleArray.type_leave")}</option>
                                <option value={ROLE_TYPE_SICK}>{t("RoleArray.type_sick")}</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td className="p-2">
                            <span className="text-small text-muted">2</span>
                        </td>
                        <td className="p-2">
                            <input disabled className="form-control" value={t("RoleArray.type_sick_default")} />
                        </td>
                        <td className="p-2">
                            <select disabled className="form-select form-control" value={ROLE_TYPE_SICK}>
                                <option value={ROLE_TYPE_REGULAR}>{t("RoleArray.type_regular")}</option>
                                <option value={ROLE_TYPE_LEAVE}>{t("RoleArray.type_leave")}</option>
                                <option value={ROLE_TYPE_SICK}>{t("RoleArray.type_sick")}</option>
                            </select>
                        </td>
                    </tr>
                    {fields.map((item, index) => {
                        return (
                            <tr key={item.id}>
                                <td className="p-2">
                                    <span className="text-small text-muted">{index + 3}</span>
                                </td>
                                <td className="p-2">
                                    <input {...register(`roles.${index}.name`)} data-cy={`roles.${index}.name`} className="form-control" />
                                </td>
                                <td className="p-2">
                                    <select {...register(`roles.${index}.type`, { required: true })} className="form-select form-control">
                                        <option value={ROLE_TYPE_REGULAR}>{t("RoleArray.type_regular")}</option>
                                        <option value={ROLE_TYPE_LEAVE}>{t("RoleArray.type_leave")}</option>
                                        <option value={ROLE_TYPE_SICK}>{t("RoleArray.type_sick")}</option>
                                    </select>
                                </td>
                                <td className="p-2">
                                    <Button type="button" variant="link" data-cy={`roles.${index}.delete`} onClick={() => remove(index)}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <Button type="button" variant="link" onClick={onAdd}>
                {t("Buttons.add")}
            </Button>
        </>
    )
}

export default RoleArray
