import AfasConnectionType from "../types/AfasConnectionType"
import api from "./Api"

export function loadAfasConnections(user: number): Promise<AfasConnectionType[]> {
    return new Promise<AfasConnectionType[]>((resolve, reject) =>
        getAfasConnections(user)
            .then((response) => resolve(response.data.items))
            .catch(reject)
    )
}

export function getAfasConnections(user: number) {
    return api.get<{ items: AfasConnectionType[] }>(`/afas-connections/?user=${user}`)
}
