import LocationType from "../types/LocationType"
import { UserAvailabilityType } from "../types/UserAvailabilityType"
import api from "./Api"

export function loadUserAvailabilities(location: LocationType): Promise<UserAvailabilityType[]> {
    return new Promise<UserAvailabilityType[]>((resolve, reject) =>
        getUserAvailabilities(location)
            .then((response) => resolve(response.data.items))
            .catch(reject)
    )
}

export function getUserAvailabilities(location: LocationType) {
    return api.get<{ items: UserAvailabilityType[] }>(`/user-availabilities/?location=${location.id}`)
}
