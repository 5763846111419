import { withParams } from "../helpers/FilterHelper"
import AbsenceRequestAfasSyncType, { CreateAbsenceRequestAfasSyncType } from "../types/AbsenceRequestAfasSyncType"
import PageRequestType from "../types/PageRequestType"
import PageResponseType from "../types/PageResponseType"
import api from "./Api"

export function createAbsenceRequestAfasSync(afasSync: CreateAbsenceRequestAfasSyncType) {
    return api.post("/absence-request-afas-syncs/", afasSync)
}

export function loadAbsenceRequestAfasSyncs(request: PageRequestType): Promise<PageResponseType<AbsenceRequestAfasSyncType>> {
    return new Promise<PageResponseType<AbsenceRequestAfasSyncType>>((resolve, reject) =>
        getAbsenceRequestAfasSyncs(request)
            .then((response) => resolve(response.data))
            .catch(reject)
    )
}

export function getAbsenceRequestAfasSyncs(pageDetails: PageRequestType) {
    return api.get<PageResponseType<AbsenceRequestAfasSyncType>>(withParams("/absence-request-afas-syncs/", pageDetails))
}
