import { FC, useEffect, useMemo, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import RegularPage from "../../components/page/RegularPage"
import ProgressBar from "../../components/ProgressBar"
import { useTabs } from "../../contexts/TabsContext"
import { useSelectedLocation } from "../../contexts/UserSettingsContext"
import { refetchInterval } from "../../helpers/QueryHelper"
import { loadShiftTemplate } from "../../services/ShiftTemplate"
import { loadTask, TaskShiftsResult, TaskType } from "../../services/Task"
import ShiftTemplateType from "../../types/ShiftTemplateType"

const GenerateShiftTemplateStatusPage: FC = () => {
    const { t } = useTranslation()
    const { setActiveTab } = useTabs()
    const params = useParams()
    const location = useSelectedLocation()
    const shiftTemplateId = useMemo(() => parseInt(params.shiftTemplateId!), [params])
    const taskId = useMemo(() => parseInt(params.taskId!), [params])
    const navigate = useNavigate()

    const { data: shiftTemplate } = useQuery<ShiftTemplateType>(["shiftTemplate", shiftTemplateId], loadShiftTemplate(shiftTemplateId))
    const shiftTemplateName = useMemo(() => (shiftTemplate ? shiftTemplate.name : ""), [shiftTemplate])

    const fetchCount = useRef(0)
    const { data: task } = useQuery<TaskType<TaskShiftsResult>>(["Task", taskId], () => loadTask(taskId), {
        refetchInterval: refetchInterval(fetchCount),
    })
    const progress = useMemo(() => (task ? task.progress : 0), [task])
    const done = useMemo(() => (task ? task.done : false), [task])

    useEffect(() => setActiveTab("ShiftTemplates"), [setActiveTab])
    useEffect(() => {
        if (shiftTemplate && shiftTemplate.location !== location.id) {
            navigate("/basisroosters")
        }
    }, [shiftTemplate])
    useEffect(() => {
        if (done) {
            navigate(`/basisroosters/${shiftTemplateId}`)
        }
    }, [done, shiftTemplateId])

    return (
        <RegularPage
            id="GenerateShiftTemplateStatus"
            breadcrumbs={[
                { title: t("GenerateShiftTemplateStatusPage.shift_templates"), href: "/basisroosters" },
                { title: shiftTemplateName, href: `/basisroosters/${shiftTemplateId}` },
                { title: t("GenerateShiftTemplateStatusPage.generate") },
            ]}
        >
            <h2>{t("GenerateShiftTemplateStatusPage.generate_item", { item: shiftTemplateName })}</h2>
            <ProgressBar done={done} progress={progress} />
        </RegularPage>
    )
}

export default GenerateShiftTemplateStatusPage
