import { useCallback, useContext, useEffect } from "react"
import { Button, Form } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import SmallCardPage from "../components/page/SmallCardPage"
import { UserSettingsContext, useIsAuthenticated, useTOTPDevices } from "../contexts/UserSettingsContext"
import { setOptionalError } from "../helpers/FormHelper"
import { submitMfa } from "../services/User"

interface Inputs {
    otp_device: string
    otp_token: string
}

const LoginMfaPage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { userSettings, reloadUserSettings } = useContext(UserSettingsContext)
    const isAuthenticated = useIsAuthenticated()
    const totpDevices = useTOTPDevices()
    const {
        register,
        setValue,
        setError,
        formState: { errors },
        handleSubmit,
    } = useForm<Inputs>()

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "otp_token", data.otp_token)
            setOptionalError(setError, "otp_device", data.otp_device)
            setOptionalError(setError, "root", data.nonFieldErrors)
        },
        [setError]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ otp_device, otp_token }) => {
            submitMfa(otp_device, otp_token).then(reloadUserSettings).catch(onFailure)
        },
        [reloadUserSettings, onFailure]
    )

    useEffect(() => {
        if (!userSettings) {
            return
        } else if (!userSettings.authenticated) {
            navigate("/login")
        } else if (userSettings.totpDevices.length === 0) {
            navigate("/inloggen/mfa/start")
        }
    }, [userSettings])

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/dashboard")
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (totpDevices?.length > 0) {
            setValue("otp_device", totpDevices[0].persistentId)
        }
    }, [totpDevices])

    return (
        <SmallCardPage>
            <Form noValidate onSubmit={handleSubmit(onSubmit)} className="spacer">
                <Form.Group>
                    <Form.Label>{t("LoginMfaPage.2fa_device")}</Form.Label>
                    <Form.Select {...register("otp_device")}>
                        {totpDevices.map(({ persistentId, name }) => (
                            <option key={persistentId} value={persistentId}>
                                {name}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.otp_device?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("Forms.totp_code")}</Form.Label>
                    <Form.Control type="text" inputMode="numeric" pattern="[0-9]*" {...register("otp_token")} isInvalid={!!errors.otp_token} autoFocus />
                    <Form.Control.Feedback type="invalid">{errors.otp_token?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="flex-row">
                    <Button data-cy="login-button" type="submit">
                        {t("Buttons.confirm")}
                    </Button>
                    <small className="end">
                        <Link to="/uitloggen" className="fw-bold">
                            {t("Buttons.back")}
                        </Link>
                    </small>
                </Form.Group>
                <Form.Group hidden={!errors.root}>
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid">{errors.root?.message}</Form.Control.Feedback>
                </Form.Group>
            </Form>
        </SmallCardPage>
    )
}

export default LoginMfaPage
