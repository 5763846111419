import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { getCSSColor, getGreenColor, getRedColor } from "../helpers/ColorHelper"
import { AbsenceRequestReviewStatus } from "../types/AbsenceRequestType"

type AbsenceRequestReviewStatusOption = {
    id: AbsenceRequestReviewStatus
    name: string
    icon?: FontAwesomeIconProps
}

const useAbsenceRequestReviewStatusOptions = () => {
    const { t } = useTranslation()

    return useMemo(
        (): AbsenceRequestReviewStatusOption[] => [
            { id: "TO_BE_REVIEWED", name: t("AbsenceRequest.review_status_to_be_reviewed") },
            { id: "ACCEPTED", name: t("AbsenceRequest.review_status_accepted"), icon: { icon: faCheck, color: getCSSColor(getGreenColor()) } },
            { id: "DENIED", name: t("AbsenceRequest.review_status_denied"), icon: { icon: faXmark, color: getCSSColor(getRedColor()) } },
        ],
        [t]
    )
}

export default useAbsenceRequestReviewStatusOptions
