import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { addDays, addMonths, addWeeks, getMonthName, getWeekNumber, shortDate } from "../helpers/DaysHelper"
import { IntervalType } from "../services/Task"

const useGetTimeAxisValue = () => {
    const { t } = useTranslation()

    return useCallback(
        (referenceDate: Date, interval: IntervalType, value: number) => {
            if (value === undefined || value % 1 !== 0) {
                return ""
            } else if (interval === "DAY") {
                return shortDate(addDays(referenceDate, value))
            } else if (interval === "WEEK") {
                const startOfWeek = addWeeks(referenceDate, value)
                const weekNumber = getWeekNumber(startOfWeek)
                if (startOfWeek.getFullYear() !== new Date().getFullYear()) {
                    return t("Main.week_number_with_year", { week: weekNumber, year: startOfWeek.getFullYear().toString().substring(2) })
                } else {
                    return t("Main.week_number", { week: weekNumber })
                }
            } else if (interval === "MONTH") {
                const startOfMonth = addMonths(referenceDate, value)
                const yearSuffix = startOfMonth.getFullYear() !== new Date().getFullYear() ? ` '${startOfMonth.getFullYear().toString().substring(2)}` : ""
                return `${getMonthName(startOfMonth.getMonth())}${yearSuffix}`
            }
            return ""
        },
        [t]
    )
}

export default useGetTimeAxisValue
