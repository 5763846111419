import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { getCSSColor, getGreenColor } from "../helpers/ColorHelper"
import { AbsenceRequestHandleStatus } from "../types/AbsenceRequestType"

type AbsenceRequestHandleStatusOption = {
    id: AbsenceRequestHandleStatus
    name: string
    icon?: FontAwesomeIconProps
}

const useAbsenceRequestHandleStatusOptions = () => {
    const { t } = useTranslation()

    return useMemo(
        (): AbsenceRequestHandleStatusOption[] => [
            { id: "TO_BE_HANDLED", name: t("AbsenceRequest.handle_status_to_be_handled") },
            { id: "NOT_NEEDED", name: t("AbsenceRequest.handle_status_not_needed"), icon: { icon: faCheck, color: getCSSColor(getGreenColor()) } },
            { id: "HANDLED", name: t("AbsenceRequest.handle_status_handled"), icon: { icon: faCheck, color: getCSSColor(getGreenColor()) } },
        ],
        [t]
    )
}

export default useAbsenceRequestHandleStatusOptions
