import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { useCallback } from "react"
import { AbsenceRequestHandleStatus } from "../types/AbsenceRequestType"
import useAbsenceRequestHandleStatusOptions from "./UseAbsenceRequestHandleStatusOptions"

const useResolveAbsenceRequestHandleStatusIcon = () => {
    const handleStatusOptions = useAbsenceRequestHandleStatusOptions()

    return useCallback(
        (status: AbsenceRequestHandleStatus): FontAwesomeIconProps | undefined => {
            return handleStatusOptions.find((o) => o.id === status)?.icon
        },
        [t]
    )
}

export default useResolveAbsenceRequestHandleStatusIcon
