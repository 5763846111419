import { AxiosResponse } from "axios"
import { FC, useCallback, useEffect } from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { setOptionalError } from "../../helpers/FormHelper"
import { loadAfasConnections } from "../../services/AfasConnection"
import { loadAfasLeaveTypes } from "../../services/AfasLeaveType"
import { getAfasUserConnectionLeaveBalance, loadAfasUserConnections } from "../../services/AfasUserConnection"
import AfasLeaveBalanceType from "../../types/AfasLeaveBalanceType"
import { UserType } from "../../types/UserType"

interface Props {
    user?: UserType
    setBalances: (balances: AfasLeaveBalanceType[]) => void
}

interface Inputs {
    afasConnection: string
    afasUserConnection: string
    leaveType: string
}

const AfasBalanceForm: FC<Props> = ({ user, setBalances }) => {
    const { t } = useTranslation()

    const {
        register,
        setError,
        setValue,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm<Inputs>()

    const selectedAfasConnection = watch("afasConnection")

    const { data: afasConnections } = useQuery(["AfasConnections", user?.id], () => loadAfasConnections(user!.id), { enabled: !!user })

    const { data: afasUserConnections } = useQuery(["AfasUserConnections", user?.id], () => loadAfasUserConnections(parseInt(selectedAfasConnection!), user!.id), {
        enabled: !!user && !!selectedAfasConnection,
    })

    const { data: afasLeaveTypes } = useQuery(["AfasLeaveTypes", selectedAfasConnection], () => loadAfasLeaveTypes(parseInt(selectedAfasConnection!)), { enabled: !!selectedAfasConnection })

    useEffect(() => {
        if (afasConnections && afasConnections.length > 0) {
            setValue("afasConnection", afasConnections[0].id.toString())
        }
    }, [afasConnections, setValue])

    useEffect(() => {
        if (afasUserConnections && afasUserConnections.length > 0) {
            setValue("afasUserConnection", afasUserConnections[0].id.toString())
        }
    }, [afasUserConnections, setValue])

    const onSuccess = useCallback(
        (response: AxiosResponse<{ items: AfasLeaveBalanceType[] }>) => {
            setBalances(response.data.items)
        },
        [setBalances]
    )

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "afasUserConnection", data.afasUserConnection)
            setOptionalError(setError, "root", data.nonFieldErrors || [t("Main.something_went_wrong")])
        },
        [setError]
    )

    const onSubmit = useCallback(
        ({ afasUserConnection }: Inputs) => {
            getAfasUserConnectionLeaveBalance(parseInt(afasUserConnection)).then(onSuccess).catch(onFailure)
        },
        [onSuccess, onFailure]
    )

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className="spacer">
            <Form.Group>
                <Form.Label>{t("AbsenceRequestAfasSync.afas_connection")}</Form.Label>
                <Form.Select {...register("afasConnection")} isInvalid={!!errors.afasConnection}>
                    {afasConnections?.map(({ id, name }) => (
                        <option key={id} value={id.toString()}>
                            {name}
                        </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.afasConnection?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>{t("AbsenceRequestAfasSync.afas_user_connection")}</Form.Label>
                <Form.Select {...register("afasUserConnection")} isInvalid={!!errors.afasUserConnection}>
                    {afasUserConnections?.map(({ id, afasEmail, afasEmployeeId, afasEmploymentId }) => (
                        <option key={id} value={id.toString()}>
                            {afasEmail} ({afasEmployeeId}-{afasEmploymentId})
                        </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.afasConnection?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
                <Form.Label>{t("AbsenceRequestAfasSync.leave_type")}</Form.Label>
                <Form.Select {...register("leaveType")} isInvalid={!!errors.leaveType}>
                    {afasLeaveTypes?.map(({ id, cao, leaveCode, leaveName, leaveBaseName }) => (
                        <option key={id} value={leaveCode}>
                            {cao} - {leaveCode} - {leaveName} ({leaveBaseName})
                        </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.leaveType?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group hidden={!errors.root}>
                <Form.Control type="hidden" isInvalid={!!errors.root} />
                <Form.Control.Feedback type="invalid" data-cy="root_errors">
                    {errors.root?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <div>
                <Button type="submit">{t("Buttons.request")}</Button>
            </div>
        </Form>
    )
}

export default AfasBalanceForm
