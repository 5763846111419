import { FC, ReactNode } from "react"
import { Col, Row } from "react-bootstrap"
import RegularPage from "./RegularPage"

interface Props {
    children?: ReactNode
}

const SmallCardPage: FC<Props> = ({ children }) => {
    return (
        <Row className="w-100 pt-3">
            <Col xs={{ span: 12 }} sm={{ span: 8, offset: 2 }} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
                <RegularPage>{children}</RegularPage>
            </Col>
        </Row>
    )
}

export default SmallCardPage
