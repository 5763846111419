import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ColumnDef } from "@tanstack/react-table"
import { FC, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import RegularPage from "../../components/page/RegularPage"
import Table from "../../components/table/Table"
import { useTabs } from "../../contexts/TabsContext"
import { useContinueTour } from "../../contexts/TourContext"
import { STEP_INDEX_SHIFT_TEMPLATES } from "../../contexts/TourSteps"
import { useSelectedLocation } from "../../contexts/UserSettingsContext"
import { dateFromDjango, isoPrint } from "../../helpers/DaysHelper"
import { getLocationFilter } from "../../helpers/LocationHelper"
import { loadShiftTemplates } from "../../services/ShiftTemplate"
import ShiftTemplateType from "../../types/ShiftTemplateType"

const ShiftTemplatesPage: FC = () => {
    const { t } = useTranslation()
    const { setActiveTab } = useTabs()
    const location = useSelectedLocation()
    const continueTour = useContinueTour()

    useEffect(() => continueTour(STEP_INDEX_SHIFT_TEMPLATES), [continueTour])
    useEffect(() => setActiveTab("ShiftTemplates"), [setActiveTab])

    const columns = useMemo(
        (): ColumnDef<ShiftTemplateType>[] => [
            {
                id: "name",
                header: t("ShiftTemplatesPage.name"),
                cell: ({ row }) => <Link to={`/basisroosters/${row.original.id}`}>{row.original.name}</Link>,
            },
            {
                id: "validFrom",
                header: t("ShiftTemplatesPage.valid_from"),
                cell: ({ row }) => <div>{isoPrint(dateFromDjango(row.original.validFrom))}</div>,
            },
            {
                id: "validTo",
                header: t("ShiftTemplatesPage.valid_to"),
                cell: ({ row }) => <div>{row.original.validTo ? isoPrint(dateFromDjango(row.original.validTo)) : null}</div>,
            },
            {
                id: "isPublished",
                header: t("ShiftTemplatesPage.published"),
                cell: ({ row }) => {
                    if (row.original.isPublished) {
                        return <FontAwesomeIcon icon={faCheck} className="font-success" />
                    } else {
                        return null
                    }
                },
            },
        ],
        [t]
    )

    const locationFilter = useMemo(() => getLocationFilter(location), [location])

    return (
        <RegularPage id="ShiftTemplates">
            <h2>{t("ShiftTemplatesPage.title_shift_templates")}</h2>
            <Table
                id={`${location.id}_ShiftTemplates`}
                key={location.id}
                columns={columns}
                buttons={[{ name: t("ShiftTemplatesPage.new_shift_template"), link: "/basisroosters/nieuw" }]}
                fetchData={loadShiftTemplates}
                filters={[locationFilter]}
                subject={t("ShiftTemplatesPage.table_items_shift_templates")}
                minWidth="200px"
            />
        </RegularPage>
    )
}

export default ShiftTemplatesPage
