import { MouseEvent } from "react"
import PageRequestType from "../types/PageRequestType"

export function withParams(url: string, pageDetails: PageRequestType) {
    const { pageIndex, pageSize, filters } = pageDetails
    url += `?pageSize=${pageSize}&page=${pageIndex + 1}`
    if (filters) {
        for (let { key, value } of filters) {
            if (value !== "") {
                url += `&${key}=${value}`
            }
        }
    }
    return url
}

export function getEnabledRoles(roleFilterValues: string[]) {
    let enabledRoles: number[] = []
    for (let i in roleFilterValues) {
        const value = roleFilterValues[i].toString()
        if (value.startsWith("r")) {
            enabledRoles.push(parseInt(value.substring(1)))
        } else if (value === "0") {
            enabledRoles.push(0)
        }
    }
    return enabledRoles
}

export function onClickFilter(e: MouseEvent): void {
    if (e.target instanceof HTMLElement) {
        if (e.target.classList.contains("close-filters")) {
            return
        }
    }
    e.stopPropagation()
}
