import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AxiosResponse } from "axios"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import InfoPopover from "../../components/InfoPopover"
import RegularPage, { BreadcrumbType } from "../../components/page/RegularPage"
import { useTabs } from "../../contexts/TabsContext"
import { useSelectedLocation } from "../../contexts/UserSettingsContext"
import { setOptionalError } from "../../helpers/FormHelper"
import { CreatedOccupationTemplateResponse, createOccupationTemplate, deleteOccupationTemplate, loadOccupationTemplatesForLocation, updateOccupationTemplate } from "../../services/OccupationTemplate"
import OccupationTemplateType from "../../types/OccupationTemplateType"
import UnexusConnectionRowsView from "./unexus/UnexusConnectionRowsView"
import UnexusFileRowsView from "./unexus/UnexusFileRowsView"
import { UnexusRowConnectionDetails } from "./unexus/UnexusRowConnectionDetails"

export interface UnexusFileRow {
    id: any
    file: any
    role: any
    errors: any
}

interface EditOccupationTemplateDetailsPageProps {
    mode: "Create" | "Update"
    occupationTemplate?: OccupationTemplateType
}

export interface Inputs {
    name: string
    sourceTemplate: string
    weekCycle: number
    unexusFileRows: UnexusFileRow[]
    unexusConnectionRows: UnexusRowConnectionDetails[]
}

const EditOccupationTemplateDetailsPage: FC<EditOccupationTemplateDetailsPageProps> = ({ mode, occupationTemplate }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useSelectedLocation()
    const { setActiveTab } = useTabs()
    const {
        register,
        setError,
        setValue,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm<Inputs>({ defaultValues: { sourceTemplate: "", unexusFileRows: [], unexusConnectionRows: [] } })

    const [deletionWarningVisible, setDeletionWarningVisible] = useState(false)

    const { data: occupationTemplates } = useQuery(["OccupationTemplatesForLocation", location.id], loadOccupationTemplatesForLocation(location.id), { initialData: [] })

    useEffect(() => {
        if (occupationTemplate && occupationTemplate.location !== location.id) {
            navigate("/basisbezettingen")
            return
        }
        setValue("name", occupationTemplate?.name || "")
        setValue("sourceTemplate", "")
        setValue("weekCycle", occupationTemplate?.weekCycle || 1)
        setValue("unexusFileRows", [])
        setValue("unexusConnectionRows", [])
    }, [occupationTemplate])

    const onSuccessCreate = useCallback(
        (response: AxiosResponse<CreatedOccupationTemplateResponse>) => {
            const newId = response.data.template.id
            const generation = response.data.generation
            if (generation) {
                navigate(`/basisbezettingen/${newId}/genereer/${generation.id}`)
            } else {
                navigate(`/basisbezettingen/${newId}`)
            }
        },
        [navigate]
    )

    const onSuccessUpdate = useCallback(() => {
        navigate(`/basisbezettingen/${occupationTemplate!.id}`)
    }, [navigate, occupationTemplate])

    const onSuccessDelete = useCallback(() => {
        navigate("/basisbezettingen/")
    }, [navigate])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "name", data.name)
            setOptionalError(setError, "sourceTemplate", data.sourceTemplate)
            setOptionalError(setError, "weekCycle", data.weekCycle)
            setOptionalError(setError, "unexusFileRows", data.unexusFileRows)
            setOptionalError(setError, "unexusConnectionRows", data.unexusConnectionRows)
            setOptionalError(setError, "root", data.nonFieldErrors)
        },
        [setError]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ name, sourceTemplate, weekCycle, unexusFileRows, unexusConnectionRows }) => {
            if (mode === "Create") {
                createOccupationTemplate(
                    {
                        name,
                        location: location.id,
                        sourceTemplate: sourceTemplate ? parseInt(sourceTemplate) : undefined,
                        weekCycle,
                    },
                    unexusFileRows,
                    unexusConnectionRows
                )
                    .then(onSuccessCreate)
                    .catch(onFailure)
            } else if (mode === "Update") {
                updateOccupationTemplate({
                    id: occupationTemplate!.id,
                    name,
                    weekCycle,
                })
                    .then(onSuccessUpdate)
                    .catch(onFailure)
            }
        },
        [location, occupationTemplate, onSuccessUpdate, onFailure]
    )

    const onDeleteOccupationTemplate = useCallback(() => {
        if (!deletionWarningVisible) {
            setDeletionWarningVisible(true)
            return
        }

        deleteOccupationTemplate(occupationTemplate!.id).then(onSuccessDelete).catch(onFailure)
    }, [deletionWarningVisible, setDeletionWarningVisible, occupationTemplate, onSuccessDelete, onFailure])

    useEffect(() => setActiveTab("OccupationTemplates"), [setActiveTab])

    const pageTitle = useMemo(() => (mode === "Update" ? occupationTemplate?.name : "Nieuwe basisbezetting"), [mode, occupationTemplate])

    const breadcrumbs = useMemo(() => {
        const breadcrumbs: BreadcrumbType[] = [{ title: t("EditOccupationTemplateDetailsPage.occupation_templates"), href: "/basisbezettingen" }]
        if (mode === "Create") {
            breadcrumbs.push({ title: t("Main.new") })
        } else if (mode === "Update") {
            breadcrumbs.push({ title: occupationTemplate?.name ?? "", href: `/basisbezettingen/${occupationTemplate?.id}` })
            breadcrumbs.push({ title: t("Main.details") })
        }
        return breadcrumbs
    }, [mode, occupationTemplate])

    return (
        <RegularPage id={mode + "OccupationTemplate"} breadcrumbs={breadcrumbs}>
            <h2>{pageTitle}</h2>
            <Form noValidate onSubmit={handleSubmit(onSubmit)} className="spacer">
                <Form.Group>
                    <Form.Label>{t("Forms.name")}</Form.Label>
                    <Form.Control type="text" {...register("name")} isInvalid={!!errors.name} data-cy="name" />
                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                </Form.Group>
                {mode === "Create" ? (
                    <Form.Group>
                        <Form.Label>{t("EditOccupationTemplateDetailsPage.copy_occupation_template")}</Form.Label>
                        <InfoPopover id="sourceTemplate" body={t("EditOccupationTemplateDetailsPage.copy_occupation_template_description")} />
                        <Form.Select {...register("sourceTemplate")} isInvalid={!!errors.sourceTemplate} data-cy="sourceTemplate">
                            <option value="">{t("Main.choose")}</option>
                            {occupationTemplates?.map(({ id, name }) => (
                                <option key={id} value={`${id}`}>
                                    {name}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.sourceTemplate?.message}</Form.Control.Feedback>
                    </Form.Group>
                ) : null}
                <Form.Group>
                    <Form.Label>{t("EditOccupationTemplateDetailsPage.week_cycle")}</Form.Label>
                    <InfoPopover id="weekCycle" body={t("EditOccupationTemplateDetailsPage.week_cycle_description")} />
                    <Form.Control type="number" {...register("weekCycle")} isInvalid={!!errors.weekCycle?.message} data-cy="weekCycle" />
                    <Form.Control.Feedback type="invalid">{errors.weekCycle?.message}</Form.Control.Feedback>
                </Form.Group>
                {mode === "Create" && location.unexusFilesEnabled ? <UnexusFileRowsView watch={watch} setValue={setValue} /> : null}
                {mode === "Create" && location.unexusConnectionEnabled ? <UnexusConnectionRowsView watch={watch} setValue={setValue} /> : null}
                {mode === "Create" ? (
                    <div>
                        <Button type="submit" data-cy="createOccupationTemplate">
                            {t("Buttons.save")}
                        </Button>
                    </div>
                ) : null}
                {mode === "Update" ? (
                    <div>
                        <Button type="submit" data-cy="saveOccupationTemplate">
                            {t("Buttons.save")}
                        </Button>
                        <Button type="button" variant="danger" onClick={onDeleteOccupationTemplate} className="ms-2" data-cy="deleteOccupationTemplate">
                            {t("Buttons.delete")}
                        </Button>
                    </div>
                ) : null}
                {deletionWarningVisible ? (
                    <span className="ms-3">
                        <FontAwesomeIcon icon={faExclamationCircle} className="me-1" />
                        {t("EditOccupationTemplateDetailsPage.are_you_sure_delete")}
                        <Button type="button" variant="link" onClick={onDeleteOccupationTemplate} data-cy="confirmDeleteOccupationTemplate">
                            {t("Buttons.yes_delete")}
                        </Button>
                    </span>
                ) : null}
                <Form.Group hidden={!errors.root}>
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid" data-cy="root_errors">
                        {errors.root?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </RegularPage>
    )
}

export default EditOccupationTemplateDetailsPage
