import { useMemo } from "react"
import { useTranslation } from "react-i18next"

const useHiringAvailabilityStatusOptions = () => {
    const { t } = useTranslation()

    return useMemo(
        () => [
            { id: "AVAILABLE", name: t("HiringAvailabilityStatus.available") },
            { id: "UNAVAILABLE", name: t("HiringAvailabilityStatus.unavailable") },
        ],
        [t]
    )
}

export default useHiringAvailabilityStatusOptions
