import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { ScheduleEditorContext, useScheduleEditorState } from "../../contexts/ScheduleEditorContext"
import { dateToDjango, getDaysString, getFirstNextWorkday, getSlot, getTime, prettyPrintTime, timeFromIso, timeToDate } from "../../helpers/DaysHelper"
import { setOptionalError } from "../../helpers/FormHelper"
import { toScheduleMapFromPlan } from "../../helpers/ScheduleMapHelper"
import { getUserOptions } from "../../helpers/UsersHelper"
import useRoleOptions from "../../hooks/UseRoleOptions"
import { createShiftMutationsPlan } from "../../services/ShiftMutationsPlan"
import { createShiftTemplateMutationsPlan } from "../../services/ShiftTemplateMutationsPlan"
import ShiftMutationsPlanType from "../../types/ShiftMutationsPlanType"
import ShiftTemplateMutationsPlanType from "../../types/ShiftTemplateMutationsPlanType"
import DayOptions from "../DayOptions"
import DateTimeSelector from "../form/DateTimeSelector"
import InlineFormMultiSelect from "../form/InlineFormMultiSelect"
import UserTimeInputPopover from "../user/UserTimeInput/UserTimeInputPopover"
import styles from "./ShiftPlanner.module.scss"
import ShiftPlannerProgress from "./ShiftPlannerProgress"
import ShiftPlannerResult from "./ShiftPlannerResult"

interface ShiftPlannerInputs {
    periodDateFrom?: Date
    periodTimeFrom: string
    periodDateTo?: Date
    periodTimeTo: string
    timeFrom: string
    timeTo: string
    repeating: boolean
    repeatingWeeks: number
    repeatingDays: string
    fixedDays: string
    role: string
    users: string[]
    useAvailabilities: boolean
}

interface Props {
    visible: boolean
    mode: "DAYS" | "DATERANGE"
    close: () => void
}

const ShiftPlanner: FC<Props> = ({ visible, mode, close }) => {
    const { applyMutations } = useContext(ScheduleEditorContext)
    const editorState = useScheduleEditorState()

    const roles = useMemo(() => editorState.roles || [], [editorState.roles])
    const shiftTemplate = useMemo(() => editorState.shiftTemplate, [editorState.shiftTemplate])
    const location = useMemo(() => editorState.location, [editorState.location])
    const roleOptions = useRoleOptions(roles, true)
    const userOptions = useMemo(() => getUserOptions(editorState.users), [editorState.users])
    const enabledDays = useMemo(() => location.enabledDays, [location])
    const weekCycle = useMemo(() => (shiftTemplate ? shiftTemplate.weekCycle : 1), [shiftTemplate])
    const firstNextWorkday = useMemo(() => getFirstNextWorkday(new Date(), enabledDays), [enabledDays])

    const {
        register,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
        handleSubmit,
        watch,
        reset,
    } = useForm<ShiftPlannerInputs>({
        defaultValues: {
            periodDateFrom: firstNextWorkday,
            periodTimeFrom: "",
            periodDateTo: firstNextWorkday,
            periodTimeTo: "",
            timeFrom: prettyPrintTime(getTime("start", location.startSlot)),
            timeTo: prettyPrintTime(getTime("end", location.endSlot)),
            repeating: false,
            repeatingWeeks: 1,
            repeatingDays: "0000000",
            fixedDays: getDaysString(weekCycle),
            role: "",
            users: [],
            useAvailabilities: true,
        },
    })

    useEffect(() => setValue("fixedDays", getDaysString(weekCycle)), [setValue, weekCycle])

    const [plan, setPlan] = useState<ShiftMutationsPlanType | ShiftTemplateMutationsPlanType>()

    const onSubmit: SubmitHandler<ShiftPlannerInputs> = useCallback(
        ({ periodDateFrom, periodTimeFrom, periodDateTo, periodTimeTo, timeFrom, timeTo, repeating, repeatingWeeks, repeatingDays, fixedDays, role, users, useAvailabilities }) => {
            const required = "Dit veld is vereist."
            const validDate = "Vul een geldige datum in"
            const validTime = "Vul een geldige tijd in"
            const validNumber = "Vul een getal in"
            let newPeriodDateFromError: string | undefined = undefined
            let newPeriodTimeFromError: string | undefined = undefined
            let newPeriodDateToError: string | undefined = undefined
            let newPeriodTimeToError: string | undefined = undefined
            let newTimeFromError: string | undefined = undefined
            let newTimeToError: string | undefined = undefined
            let newRepeatingWeekError: string | undefined = undefined
            let newRepeatingDaysError: string | undefined = undefined
            let newFixedDaysError: string | undefined = undefined
            let newRoleError: string | undefined = undefined
            let newUsersError: string | undefined = undefined
            const parsedTimeFrom = getSlot("start", timeToDate(timeFrom))
            const parsedTimeTo = getSlot("end", timeToDate(timeTo))
            if (mode === "DATERANGE") {
                if (!periodDateFrom) {
                    newPeriodDateFromError = required
                } else if (!!periodDateTo && periodDateFrom > periodDateTo) {
                    newPeriodDateFromError = validDate
                }
                if (!parsedTimeFrom && parsedTimeFrom !== 0) {
                    newTimeFromError = required
                } else if (!!parsedTimeTo && parsedTimeFrom > parsedTimeTo) {
                    newTimeFromError = validTime
                }
                if (!parsedTimeTo && parsedTimeTo !== 0) {
                    newTimeToError = required
                }
                if (repeating) {
                    if (repeatingWeeks < 1) {
                        newRepeatingWeekError = validNumber
                    } else {
                        let hasIncorrect = false
                        let hasSelected = false
                        for (let i = 0; i < 7; i++) {
                            if (repeatingDays[i] === "1") {
                                hasSelected = true
                            }
                            if (repeatingDays[i] !== "1" && repeatingDays[i] !== "0") {
                                hasIncorrect = true
                            }
                        }
                        if (hasIncorrect || !hasSelected) {
                            newRepeatingDaysError = required
                        }
                    }
                }
            } else if (mode === "DAYS") {
                let hasIncorrect = false
                let hasSelected = false
                for (let i = 0; i < 7 * weekCycle; i++) {
                    if (fixedDays[i] === "1") {
                        hasSelected = true
                    }
                    if (fixedDays[i] !== "1" && fixedDays[i] !== "0") {
                        hasIncorrect = true
                    }
                }
                if (hasIncorrect || !hasSelected) {
                    newFixedDaysError = required
                }
            }
            if (!role) {
                newRoleError = required
            }
            if (!users || users.length === 0) {
                newUsersError = required
            }
            setOptionalError(setError, "periodDateFrom", newPeriodDateFromError)
            setOptionalError(setError, "periodTimeFrom", newPeriodTimeFromError)
            setOptionalError(setError, "periodDateTo", newPeriodDateToError)
            setOptionalError(setError, "periodTimeTo", newPeriodTimeToError)
            setOptionalError(setError, "timeFrom", newTimeFromError)
            setOptionalError(setError, "timeTo", newTimeToError)
            setOptionalError(setError, "repeatingWeeks", newRepeatingWeekError)
            setOptionalError(setError, "repeatingDays", newRepeatingDaysError)
            setOptionalError(setError, "fixedDays", newFixedDaysError)
            setOptionalError(setError, "role", newRoleError)
            setOptionalError(setError, "users", newUsersError)
            if (
                newPeriodDateFromError ||
                newPeriodTimeFromError ||
                newPeriodDateToError ||
                newPeriodTimeToError ||
                newTimeFromError ||
                newTimeToError ||
                newRepeatingWeekError ||
                newRepeatingDaysError ||
                newFixedDaysError ||
                newRoleError ||
                newUsersError
            ) {
                // Show error, do nothing
            } else {
                if (mode === "DATERANGE") {
                    createShiftMutationsPlan({
                        location: location.id,
                        periodDateFrom: dateToDjango(periodDateFrom!),
                        periodStartSlot: periodTimeFrom !== "" ? getSlot("start", timeFromIso(periodTimeFrom)!) : undefined,
                        periodDateTo: dateToDjango(periodDateTo!),
                        periodEndSlot: periodTimeTo !== "" ? getSlot("end", timeFromIso(periodTimeTo)!) : undefined,
                        startSlot: getSlot("start", timeToDate(timeFrom)),
                        endSlot: getSlot("end", timeToDate(timeTo)),
                        repeating,
                        repeatingWeeks,
                        repeatingDays,
                        role: role !== "" ? parseInt(role) : null,
                        users,
                        useAvailabilities,
                    }).then((response) => setPlan(response.data))
                } else if (mode === "DAYS") {
                    createShiftTemplateMutationsPlan({
                        shiftTemplate: shiftTemplate!.id,
                        fixedDays,
                        startSlot: getSlot("start", timeToDate(timeFrom)),
                        endSlot: getSlot("end", timeToDate(timeTo)),
                        role: role !== "" ? parseInt(role) : null,
                        users,
                        useAvailabilities,
                    }).then((response) => setPlan(response.data))
                }
            }
        },
        [mode, weekCycle, location, shiftTemplate, setError, setPlan]
    )

    const apply = useCallback(() => {
        const scheduleMap = toScheduleMapFromPlan(plan!)
        applyMutations(scheduleMap)
        close()
        setPlan(undefined)
        reset()
    }, [plan, applyMutations, close, setPlan, reset])

    const resetPlan = useCallback(() => setPlan(undefined), [setPlan])

    const fixedDays = watch("fixedDays")
    const setFixedDays = useCallback(
        (fixedDays: string) => {
            setValue("fixedDays", fixedDays)
            clearErrors("fixedDays")
        },
        [setValue, clearErrors]
    )

    const periodDateFrom = watch("periodDateFrom")
    const periodTimeFrom = watch("periodTimeFrom")
    const periodDateTo = watch("periodDateTo")
    const periodTimeTo = watch("periodTimeTo")
    const setPeriodDateFrom = useCallback(
        (periodDateFrom: Date | null) => {
            setValue("periodDateFrom", periodDateFrom ?? undefined)
            clearErrors("periodDateFrom")
        },
        [setValue, clearErrors]
    )
    const setPeriodTimeFrom = useCallback(
        (periodTimeFrom: string) => {
            setValue("periodTimeFrom", periodTimeFrom)
            clearErrors("periodTimeFrom")
        },
        [setValue, clearErrors]
    )
    const setPeriodDateTo = useCallback(
        (periodDateTo: Date | null) => {
            setValue("periodDateTo", periodDateTo ?? undefined)
            clearErrors("periodDateTo")
        },
        [setValue, clearErrors]
    )
    const setPeriodTimeTo = useCallback(
        (periodTimeTo: string) => {
            setValue("periodTimeTo", periodTimeTo)
            clearErrors("periodTimeTo")
        },
        [setValue, clearErrors]
    )

    const timeFrom = watch("timeFrom")
    const timeTo = watch("timeTo")
    const setTimeFrom = useCallback(
        (timeFrom: string) => {
            setValue("timeFrom", timeFrom)
            clearErrors("timeFrom")
        },
        [setValue, clearErrors]
    )
    const setTimeTo = useCallback(
        (timeTo: string) => {
            setValue("timeTo", timeTo)
            clearErrors("timeTo")
        },
        [setValue, clearErrors]
    )

    const repeating = watch("repeating")
    const repeatingDays = watch("repeatingDays")
    const setRepeatingDays = useCallback(
        (repeatingDays: string) => {
            setValue("repeatingDays", repeatingDays)
            clearErrors("repeatingDays")
        },
        [setValue, clearErrors]
    )

    const users = watch("users")
    const setUsers = useCallback(
        (users: string[]) => {
            setValue("users", users)
            clearErrors("users")
        },
        [setValue, clearErrors]
    )

    return (
        <Modal show={visible} onHide={close}>
            <Modal.Header className="justify-content-between">
                <Modal.Title>Bulkwijziging maken {plan && plan.done ? `(${plan.mutations.length})` : ""}</Modal.Title>
                <Button type="button" variant="link" onClick={close}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body>
                {plan ? (
                    <div>
                        {plan.done ? <ShiftPlannerResult plan={plan} roles={roles} weekCycle={weekCycle} /> : <ShiftPlannerProgress plan={plan} setPlan={setPlan} mode={mode} />}
                        <div className="mt-3">
                            <Button type="button" variant="link" onClick={resetPlan}>
                                Vorige
                            </Button>
                            {plan.done ? (
                                <Button type="button" onClick={apply}>
                                    Toepassen
                                </Button>
                            ) : null}
                        </div>
                    </div>
                ) : (
                    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <table className="form-inline-table">
                            <tbody>
                                {mode === "DAYS" ? (
                                    <tr>
                                        <td className={styles.nameCell}>
                                            <span className={styles.title}>Dagen:</span>
                                        </td>
                                        <td>
                                            <Form.Group>
                                                <div className="d-inline-block form-inline-input-group px-2">
                                                    <DayOptions enabledDays={enabledDays} weekCycle={weekCycle} selectedDays={fixedDays} disabled={false} setSelectedDays={setFixedDays} />
                                                </div>
                                                <Form.Control type="hidden" isInvalid={!!errors.fixedDays} />
                                                <Form.Control.Feedback type="invalid">{errors.fixedDays?.message}</Form.Control.Feedback>
                                            </Form.Group>
                                        </td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td className={styles.nameCell}>
                                            <span className={styles.title}>Periode:</span>
                                        </td>
                                        <td>
                                            <Form.Group>
                                                <div className="form-inline-input-group" style={{ paddingRight: "0.5rem" }}>
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                            <span className="form-inline-input-group-text">van</span>
                                                            <DateTimeSelector
                                                                selectedDate={periodDateFrom}
                                                                setSelectedDate={setPeriodDateFrom}
                                                                dateTabIndex={1}
                                                                dateIsError={!!errors.periodDateFrom?.message}
                                                                selectedTime={periodTimeFrom}
                                                                setSelectedTime={setPeriodTimeFrom}
                                                                timeTabIndex={2}
                                                                timeIsError={!!errors.periodTimeFrom?.message}
                                                                startSlot={location.startSlot}
                                                                endSlot={location.endSlot}
                                                                inline={true}
                                                                type="start"
                                                            />
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                            <span className="form-inline-input-group-text">t/m</span>
                                                            <DateTimeSelector
                                                                selectedDate={periodDateTo}
                                                                setSelectedDate={setPeriodDateTo}
                                                                dateTabIndex={3}
                                                                dateIsError={!!errors.periodDateTo?.message}
                                                                selectedTime={periodTimeTo}
                                                                setSelectedTime={setPeriodTimeTo}
                                                                timeTabIndex={4}
                                                                timeIsError={!!errors.periodTimeTo?.message}
                                                                startSlot={location.startSlot}
                                                                endSlot={location.endSlot}
                                                                inline={true}
                                                                type="end"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <Form.Control
                                                    type="hidden"
                                                    isInvalid={!!(errors.periodDateFrom?.message || errors.periodTimeFrom?.message || errors.periodDateTo?.message || errors.periodTimeTo?.message)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.periodDateFrom?.message || errors.periodTimeFrom?.message || errors.periodDateTo?.message || errors.periodTimeTo?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td className={styles.nameCell}>
                                        <span className={styles.title}>Tijd:</span>
                                    </td>
                                    <td>
                                        <Form.Group>
                                            <div>
                                                <div className="form-inline-input-group" style={{ paddingRight: "0.5rem" }}>
                                                    <span className="form-inline-input-group-text">van</span>
                                                    <div className="p-1">
                                                        <UserTimeInputPopover
                                                            startSlot={location.startSlot}
                                                            endSlot={location.endSlot}
                                                            selectedTime={timeFrom}
                                                            setSelectedTime={setTimeFrom}
                                                            type="start"
                                                        />
                                                    </div>
                                                    <span className="form-inline-input-group-text">tot</span>
                                                    <div className="p-1">
                                                        <UserTimeInputPopover startSlot={location.startSlot} endSlot={location.endSlot} selectedTime={timeTo} setSelectedTime={setTimeTo} type="end" />
                                                    </div>
                                                </div>
                                            </div>
                                            <Form.Control type="hidden" isInvalid={!!(errors.timeFrom?.message || errors.timeTo?.message)} />
                                            <Form.Control.Feedback type="invalid">{errors.timeFrom?.message || errors.timeTo?.message}</Form.Control.Feedback>
                                        </Form.Group>
                                    </td>
                                </tr>
                                {mode === "DATERANGE" ? (
                                    <tr>
                                        <td className={styles.nameCell}>
                                            <span className={styles.title}>Herhalend:</span>
                                        </td>
                                        <td>
                                            <div className="d-inline-block">
                                                <div className="form-inline-input-group">
                                                    <div className="d-flex flex-column">
                                                        <div className="d-flex align-items-center py-1 px-2">
                                                            <Form.Check {...register("repeating")} />
                                                            <span className={"form-inline-input-group-text" + (!repeating ? " text-muted" : "")}>Elke</span>
                                                            <Form.Control type="number" {...register("repeatingWeeks")} disabled={!repeating} />
                                                            <span className={"form-inline-input-group-text" + (!repeating ? " text-muted" : "")}>weken</span>
                                                        </div>
                                                        <div className="d-flex">
                                                            <span className={"form-inline-input-group-text" + (!repeating ? " text-muted" : "")}>op: </span>
                                                            <DayOptions
                                                                enabledDays={enabledDays}
                                                                weekCycle={1}
                                                                selectedDays={repeating ? repeatingDays : "0000000"}
                                                                disabled={!repeating}
                                                                setSelectedDays={setRepeatingDays}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Form.Control type="hidden" isInvalid={!!(errors.repeating?.message || errors.repeatingWeeks?.message || errors.repeatingDays?.message)} />
                                            <Form.Control.Feedback type="invalid">{errors.repeating?.message || errors.repeatingWeeks?.message || errors.repeatingDays?.message}</Form.Control.Feedback>
                                        </td>
                                    </tr>
                                ) : null}
                                <tr>
                                    <td className={styles.nameCell}>
                                        <span className={styles.title}>Taak:</span>
                                    </td>
                                    <td>
                                        <Form.Select {...register("role")} isInvalid={!!errors.role?.message}>
                                            {roleOptions.map(({ id, name }) => (
                                                <option key={id} value={id}>
                                                    {name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">{errors.role?.message}</Form.Control.Feedback>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.nameCell}>
                                        <span className={styles.title}>Medewerker(s):</span>
                                    </td>
                                    <td>
                                        <div className="container-scroll" style={{ maxHeight: "150px", paddingTop: "0.375rem" }}>
                                            <InlineFormMultiSelect id="users" options={userOptions} values={users} onChange={setUsers} toggleAllEnabled={true} />
                                        </div>
                                        <Form.Control type="hidden" isInvalid={!!errors.users?.message} />
                                        <Form.Control.Feedback type="invalid">{errors.users?.message}</Form.Control.Feedback>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.nameCell} colSpan={2}>
                                        <Form.Check
                                            id="useAvailabilities"
                                            {...register("useAvailabilities")}
                                            label="Voer wijzigingen alleen door binnen de standaardwerktijden van de betreffende medewerkers"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Button type="submit">Volgende</Button>
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default ShiftPlanner
