import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import * as yup from "yup"
import Loader from "../../../../components/Loader"
import DateRangeInput from "../../../../components/form/DateRangeInput"
import FormErrorMessages from "../../../../components/form/FormErrorMessages"
import MultiSelectFilter from "../../../../components/table/MultiSelectFilter"
import { useEmployableUsersForSelectedLocation, useSelectedLocation } from "../../../../contexts/UserSettingsContext"
import { addDays, addMonths, addWeeks, getFirstMondayOfWeek } from "../../../../helpers/DaysHelper"
import { onClickFilter } from "../../../../helpers/FilterHelper"
import { refetchInterval } from "../../../../helpers/QueryHelper"
import { getUserFilterOptions } from "../../../../helpers/UsersHelper"
import useIntervalOptions from "../../../../hooks/UseIntervalOptions"
import { useYupValidationResolver } from "../../../../hooks/UseYupValidationResolver"
import { CreateTaskUserTimeStats, IntervalType, TaskType, TaskUserTimeResult, createTaskUserTimeStats, loadTask } from "../../../../services/Task"
import styles from "../Statistics.module.scss"
import RoleHoursGraph3 from "../rolehours/RoleHoursGraph3"
import RoleHoursTable from "../rolehours/RoleHoursTable"
import UserHoursGraph from "./UserHoursGraph"

interface UserHoursProps {
    show: boolean
}

const UserHours: FC<UserHoursProps> = ({ show }) => {
    const { t } = useTranslation()
    const intervalOptions = useIntervalOptions()
    const location = useSelectedLocation()
    const users = useEmployableUsersForSelectedLocation()
    const userOptions = useMemo(() => getUserFilterOptions(users), [users])
    const [taskId, setTaskId] = useState<number | undefined>()
    const fetchCount = useRef(0)
    const { data: task } = useQuery<TaskType<TaskUserTimeResult>>(["task", taskId], () => loadTask<TaskUserTimeResult>(taskId!), {
        enabled: !!taskId,
        refetchInterval: refetchInterval(fetchCount),
    })

    const initialData = useMemo<CreateTaskUserTimeStats>(() => {
        const initialFrom = getFirstMondayOfWeek(new Date())
        return {
            location: location.id,
            interval: "DAY",
            users: [userOptions[0].key],
            fromDate: initialFrom,
            toDate: addDays(initialFrom, 6),
        }
    }, [location])

    const validationSchema = useMemo(() => {
        return yup.object({
            users: yup.array().min(1, t("StatisticsPage.select_at_least_one_employee")),
            fromDate: yup.date().required(t("StatisticsPage.fill_start_date")),
            toDate: yup
                .date()
                .required(t("StatisticsPage.fill_end_date"))
                .when(["fromDate", "interval"], ([fromDate, interval], schema) => {
                    if (interval === "DAY") {
                        const limit = addDays(fromDate, 100)
                        return schema.max(limit, t("StatisticsPage.max_100_days"))
                    } else if (interval === "WEEK") {
                        const limit = addWeeks(fromDate, 53)
                        return schema.max(limit, t("StatisticsPage.max_53_weeks"))
                    } else if (interval === "MONTH") {
                        const limit = addMonths(fromDate, 12)
                        return schema.max(limit, t("StatisticsPage.max_12_months"))
                    }
                    return schema.optional()
                }),
        })
    }, [t])

    const resolver = useYupValidationResolver(validationSchema)

    const {
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
        getValues,
    } = useForm({
        defaultValues: initialData,
        resolver,
    })

    const onChangeInterval = useCallback((e: ChangeEvent<HTMLSelectElement>) => setValue("interval", e.target.value as IntervalType), [setValue])
    const onChangeFromDate = useCallback((newValue: Date | null) => setValue("fromDate", newValue), [setValue])
    const onChangeToDate = useCallback((newValue: Date | null) => setValue("toDate", newValue), [setValue])
    const onChangeUsers = useCallback((newValue: string[]) => setValue("users", newValue), [setValue])

    const onSubmit = useCallback(
        (values: CreateTaskUserTimeStats) => {
            createTaskUserTimeStats(values).then((response) => {
                setTaskId(response.data.id)
            })
        },
        [setTaskId]
    )

    useEffect(() => {
        onSubmit(getValues())
    }, [])

    const errorMessages = useMemo(() => {
        let messages = []
        if (task && task.done && !task.success) {
            messages.push(t("Main.something_went_wrong"))
        }
        if (errors) {
            messages = messages.concat(Object.entries(errors).map(([type, error]) => error?.message as string))
        }
        return messages
    }, [task, errors])

    return show ? (
        <div className="spacer">
            <Form noValidate onSubmit={handleSubmit(onSubmit)} className={styles.formPanel}>
                <DateRangeInput startDate={watch("fromDate")} setStartDate={onChangeFromDate} endDate={watch("toDate")} setEndDate={onChangeToDate} />
                <select className="form-select form-select-inline form-control me-2" onChange={onChangeInterval} value={watch("interval")}>
                    {intervalOptions.map(({ id, name }) => (
                        <option key={id} value={id}>
                            {name}
                        </option>
                    ))}
                </select>
                <div className="filter-panel">
                    <div className="dropdown d-inline-block">
                        <button className="btn btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {t("StatisticsPage.employees")}
                        </button>
                        <ul className="dropdown-menu filter-list text-start" onClick={onClickFilter}>
                            <MultiSelectFilter options={userOptions} values={watch("users")} putFilterValue={onChangeUsers} toggleAllEnabled={true} autoUpdateParent={false} />
                        </ul>
                    </div>
                </div>
                <Button type="submit" data-cy="submit-button">
                    {t("Buttons.generate")}
                </Button>
                {task && task.resultUrl ? (
                    <Link target="_blank" to={task.resultUrl} className="btn btn-link">
                        {t("Buttons.download")}
                    </Link>
                ) : null}
                <div className="ms-3" hidden={!task || task.done}>
                    <Loader />
                </div>
            </Form>
            {errorMessages.length > 0 ? <FormErrorMessages errors={errorMessages} key={errorMessages.join()} /> : null}
            <Row>
                <Col className="p-3">
                    <h5>{t("StatisticsPage.planned_time")}</h5>
                    <div className={styles.graph}>
                        <UserHoursGraph task={task} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="p-3">
                    <h5>{t("StatisticsPage.planned_time_in_period")}</h5>
                    <RoleHoursTable task={task} />
                </Col>
                <Col xs={12} md={6} className="p-3" style={{ height: `${2 + (task?.result?.roleUids.length ?? 0) * 2}rem` }}>
                    <RoleHoursGraph3 task={task} />
                </Col>
            </Row>
        </div>
    ) : null
}

export default UserHours
