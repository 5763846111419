import { UserSerie } from "react-charts"
import { IntervalType, TaskResultWithRoles, TaskRoleTimeResult, TaskUserTimeResult } from "../services/Task"
import { DefaultColorType } from "../types/ColorType"
import { getCSSColor } from "./ColorHelper"
import { dateFromDjango, daysBetween, getFirstMondayOfWeek, getFirstOfMonth, monthsBetween, weeksBetween } from "./DaysHelper"
import { NestedRoleItem } from "./RolesHelper"

export interface RoleHoursDatum {
    x: string // DAY: 2024-01=01, WEEKLY: 2024-52, MONTHLY: 2024: 2024-12
    count: number
}

export interface RoleHoursAggregatedDatum {
    roleItem: NestedRoleItem
    total: number
    isSubItem: boolean
}

export const getRoleHoursData: (
    taskResult: TaskRoleTimeResult | TaskUserTimeResult,
    nestedRoleMap: Map<string, NestedRoleItem>,
    colors: Map<number, DefaultColorType>
) => UserSerie<RoleHoursDatum>[] = (taskResult, nestedRoleMap, colors) => {
    const result: UserSerie<RoleHoursDatum>[] = []

    if (!taskResult) return []

    const map: Map<string, RoleHoursDatum[]> = new Map()
    for (const x in taskResult.roleData) {
        for (const roleId in taskResult.roleData[x]) {
            if (!map.has(roleId)) {
                map.set(roleId, [])
            }
            map.get(roleId)!.push({
                x,
                count: taskResult.roleData[x][roleId],
            })
        }
    }

    for (const roleId of taskResult.roleUids) {
        const roleItem = nestedRoleMap.get(roleId)
        result.push({
            label: roleItem?.name,
            color: roleItem?.color ? getCSSColor(colors.get(roleItem?.color)!) : undefined,
            data: map.get(roleId.toString()) ?? [],
        })
    }

    return result
}

export const getReferenceDate = (fromDate: Date, interval: IntervalType): Date => {
    if (interval === "WEEK") {
        return getFirstMondayOfWeek(fromDate)
    } else if (interval === "MONTH") {
        return getFirstOfMonth(fromDate)
    }
    return fromDate
}

export const getXValue = (referenceDate: Date, interval: IntervalType, x: string): number => {
    if (interval === "DAY") {
        return daysBetween(dateFromDjango(x), referenceDate)
    } else if (interval === "WEEK") {
        return weeksBetween(dateFromDjango(x), referenceDate)
    } else if (interval === "MONTH") {
        return monthsBetween(dateFromDjango(x), referenceDate)
    }
    return 0
}

export const canShowData = (taskResult?: TaskResultWithRoles) => {
    return !!taskResult && taskResult.roleUids.length > 0
}
