import { FormEvent, useCallback, useContext, useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import SmallCardPage from "../components/page/SmallCardPage"
import { UserSettingsContext, useIsAuthenticated } from "../contexts/UserSettingsContext"
import { activate } from "../services/User"

const ActivateAccountPage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const params = useParams()
    const isAuthenticated = useIsAuthenticated()
    const { userSettings, reloadUserSettings } = useContext(UserSettingsContext)

    const [formErrors, setFormErrors] = useState<string[]>([])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setFormErrors(data.nonFieldErrors || [t("Main.something_went_wrong")])
        },
        [setFormErrors, t]
    )

    const onSuccess = useCallback(() => {
        reloadUserSettings()
    }, [reloadUserSettings])

    const onSubmit = useCallback(
        (event: FormEvent) => {
            event.preventDefault()
            const { uid, token } = params
            activate(uid!, token!).then(onSuccess).catch(onFailure)
        },
        [params, onSuccess, onFailure]
    )

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/dashboard")
        }
    }, [userSettings])

    return (
        <SmallCardPage>
            <h2>{t("ActivateAccountPage.activate_your_account")}</h2>
            <p>{t("ActivateAccountPage.confirm")}</p>
            <Form noValidate onSubmit={onSubmit} className="spacer">
                <Form.Group className="flex-row">
                    <Button type="submit" data-cy="activate-account">
                        {t("Buttons.confirm")}
                    </Button>
                </Form.Group>
                <Form.Group hidden={!formErrors}>
                    <Form.Control type="hidden" isInvalid={!!formErrors} />
                    <Form.Control.Feedback type="invalid" data-cy="root_errors">
                        {formErrors}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </SmallCardPage>
    )
}

export default ActivateAccountPage
