import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ColumnDef } from "@tanstack/react-table"
import { FC, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import RegularPage from "../../components/page/RegularPage"
import Table from "../../components/table/Table"
import { useTabs } from "../../contexts/TabsContext"
import { useContinueTour } from "../../contexts/TourContext"
import { STEP_INDEX_ROLES } from "../../contexts/TourSteps"
import { useSelectedLocation } from "../../contexts/UserSettingsContext"
import { getLocationFilter } from "../../helpers/LocationHelper"
import { loadRoles } from "../../services/Role"
import RoleType from "../../types/RoleType"

const RolesPage: FC = () => {
    const { t } = useTranslation()
    const location = useSelectedLocation()
    const { setActiveTab } = useTabs()
    const continueTour = useContinueTour()

    useEffect(() => continueTour(STEP_INDEX_ROLES), [continueTour])
    useEffect(() => setActiveTab("Roles"), [setActiveTab])

    const columns = useMemo(
        (): ColumnDef<RoleType>[] => [
            {
                id: "name",
                header: t("RolesPage.name"),
                cell: ({ row }) => <Link to={`/taken/${row.original.id}`}>{row.original.name}</Link>,
            },
            {
                id: "hasOccupationTarget",
                header: t("RolesPage.has_occupation_target"),
                cell: ({ row }) => {
                    if (row.original.hasOccupationTarget) {
                        return <FontAwesomeIcon icon={faCheck} className="font-success" />
                    } else {
                        return null
                    }
                },
            },
        ],
        [t]
    )

    const locationFilter = useMemo(() => getLocationFilter(location), [location])

    return (
        <RegularPage id="Roles">
            <h2>{t("RolesPage.title_roles")}</h2>
            <Table
                id={`${location.id}_Roles`}
                key={location.id}
                columns={columns}
                buttons={[
                    { name: t("RolesPage.new_role"), link: "/taken/nieuw" },
                    { name: t("RolesPage.manage_role_groups"), link: "/taakgroepen", variant: "link" },
                ]}
                fetchData={loadRoles}
                filters={[locationFilter]}
                subject={t("RolesPage.no_items_in_table_name")}
                minWidth="200px"
            />
        </RegularPage>
    )
}

export default RolesPage
