import Cookies from "js-cookie"
import { useCallback, useContext, useEffect } from "react"
import { Button, Form } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import Divider from "../components/Divider"
import SmallCardPage from "../components/page/SmallCardPage"
import { UserSettingsContext } from "../contexts/UserSettingsContext"
import { setOptionalError } from "../helpers/FormHelper"
import { login } from "../services/User"

interface Inputs {
    username: string
    password: string
}

const LoginPage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { userSettings, reloadUserSettings } = useContext(UserSettingsContext)
    const {
        register,
        reset,
        setError,
        formState: { errors },
        handleSubmit,
    } = useForm<Inputs>()

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "username", data.username)
            setOptionalError(setError, "password", data.password)
            setOptionalError(setError, "root", data.nonFieldErrors)
        },
        [setError]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ username, password }) => {
            login(username, password).then(reloadUserSettings).catch(onFailure)
        },
        [reloadUserSettings, reset, onFailure]
    )

    useEffect(() => {
        if (!userSettings) {
            return
        }
        if (userSettings.user) {
            navigate("/dashboard")
        } else if (userSettings.authenticated && !userSettings.verified) {
            navigate("/inloggen/mfa")
        }
    }, [userSettings])

    const resetCompanySystemId = useCallback(() => {
        Cookies.remove("companySystemName")
        window.location.replace(window.location.pathname)
    }, [])

    return (
        <SmallCardPage>
            <h2>{t("welcome_back")}</h2>
            {(userSettings?.samlLoginConfig?.samlOptions.length || 0) > 0 ? (
                <div className="spacer">
                    <div className="w-100 d-flex justify-content-between align-items-center">
                        <div>{t("LoginPage.use_sso_to_sign_in")}</div>
                        <Button type="button" variant="link" onClick={resetCompanySystemId} className="fw-normal fs-small">
                            {t("Buttons.forget_sso")}
                        </Button>
                    </div>
                    {userSettings?.samlLoginConfig?.samlOptions.map(({ id, name }) => (
                        <Link to={`${process.env.REACT_APP_API_URL}/accounts/saml/${id}/acs/signin/`}>
                            <Button type="button">{t("log_in_with", { service: name })}</Button>
                        </Link>
                    ))}
                    {!userSettings?.samlLoginConfig?.disablePasswordLogin ? <Divider text={t("Main.or")} /> : null}
                </div>
            ) : null}
            {!userSettings?.samlLoginConfig?.disablePasswordLogin ? (
                <Form noValidate onSubmit={handleSubmit(onSubmit)} className="spacer">
                    <p>{t("LoginPage.use_password_to_sign_in")}</p>
                    <Form.Group>
                        <Form.Label>{t("Forms.email_address")}</Form.Label>
                        <Form.Control type="email" autoComplete="email" {...register("username")} isInvalid={!!errors.username} data-cy="email" autoFocus />
                        <Form.Control.Feedback type="invalid" data-cy="email_errors">
                            {errors.username?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="w-100 d-flex justify-content-between align-items-center">
                            {t("Forms.password")}
                            <Link data-cy="password-reset" to="/wachtwoord-vergeten" className="fs-small">
                                {t("Buttons.lost_password_question")}
                            </Link>
                        </Form.Label>
                        <Form.Control type="password" {...register("password")} isInvalid={!!errors.password} data-cy="password" />
                        <Form.Control.Feedback type="invalid" data-cy="password_errors">
                            {errors.password?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="flex-row">
                        <Button data-cy="login-button" type="submit">
                            {t("Buttons.login")}
                        </Button>
                        <small className="end text-muted fs-small">
                            <Trans i18nKey="LoginPage.no_account_yet">
                                No account yet?
                                <Link data-cy="register" to="/registreren">
                                    Register
                                </Link>
                            </Trans>
                        </small>
                    </Form.Group>
                    <Form.Group hidden={!errors.root}>
                        <Form.Control type="hidden" isInvalid={!!errors.root} />
                        <Form.Control.Feedback type="invalid" data-cy="root_errors">
                            {errors.root?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            ) : null}
        </SmallCardPage>
    )
}

export default LoginPage
