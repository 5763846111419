import { FC, useCallback, useState } from "react"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import RoleSelect from "../RoleSelect"

interface ShiftEditorControlsProps {
    clearSelection: () => void
    setShift: (value: number | null) => void
}

const ShiftEditorControls: FC<ShiftEditorControlsProps> = ({ clearSelection, setShift }) => {
    const { t } = useTranslation()

    const [selectedRoleId, setSelectedRoleId] = useState<number>(0)

    const apply = useCallback(() => setShift(selectedRoleId === 0 ? null : selectedRoleId), [selectedRoleId, setShift])

    return (
        <div className="d-flex gap-2 my-3">
            <RoleSelect selectedRoleId={selectedRoleId} setSelectedRoleId={setSelectedRoleId} />
            <Button type="button" data-cy="apply" onClick={apply}>
                {t("Buttons.apply")}
            </Button>
            <Button type="button" variant="link" onClick={clearSelection}>
                {t("Buttons.cancel")}
            </Button>
        </div>
    )
}

export default ShiftEditorControls
