import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentUser } from "../../contexts/UserSettingsContext"

interface Props {}

const Greeting: FC<Props> = () => {
    const { t } = useTranslation()
    const currentUser = useCurrentUser()

    const message = useMemo(() => {
        const hours = new Date().getHours()
        if (hours < 12) {
            return t("Dashboard.greeting_morning", { name: currentUser.firstName })
        } else if (hours < 18) {
            return t("Dashboard.greeting_afternoon", { name: currentUser.firstName })
        } else {
            return t("Dashboard.greeting_evening", { name: currentUser.firstName })
        }
    }, [t, currentUser])

    return (
        <h4 data-cy="greeting" className="mb-0">
            {message}
        </h4>
    )
}

export default Greeting
