import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { HiringAvailabilityStatus } from "../types/HiringAvailabilityType"

const useResolveHiringAvailabilityStatusLabel = () => {
    const { t } = useTranslation()

    return useCallback(
        (status: HiringAvailabilityStatus) => {
            if (status === "AVAILABLE") {
                return t("HiringAvailabilityStatus.available")
            } else if (status === "UNAVAILABLE") {
                return t("HiringAvailabilityStatus.unavailable")
            }
        },
        [t]
    )
}

export default useResolveHiringAvailabilityStatusLabel
