import { getHiringAvailabilities } from "../services/HiringAvailability"
import HiringAvailabilityType from "../types/HiringAvailabilityType"
import LocationType from "../types/LocationType"
import { isoPrint } from "./DaysHelper"

export function loadHiringAvailabilitiesForDate(location: LocationType, date: Date): Promise<HiringAvailabilityType[]> {
    return new Promise((resolve) => {
        const pageDetails = {
            pageIndex: 0,
            pageSize: 999,
            filters: [
                { key: "dateFrom", value: isoPrint(date) },
                { key: "dateTo", value: isoPrint(date) },
                { key: "location", value: location.id.toString() },
            ],
        }
        getHiringAvailabilities(pageDetails).then((response) => resolve(response.data.items))
    })
}

export const isAppliccable = (hiringAvailability: HiringAvailabilityType, slot: number) => {
    const { startSlot, endSlot } = hiringAvailability
    let result = true
    if (startSlot && slot < startSlot) {
        result = false
    }
    if (endSlot && slot > endSlot) {
        result = false
    }
    return result
}
