import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import RolesLegend from "../../components/editor/RolesLegend"
import FormColorSelect from "../../components/form/FormColorSelect"
import InfoPopover from "../../components/InfoPopover"
import RegularPage from "../../components/page/RegularPage"
import { useTabs } from "../../contexts/TabsContext"
import { useRoleGroupsForSelectedLocation, UserSettingsContext, useSelectedLocation } from "../../contexts/UserSettingsContext"
import { ROLE_TYPE } from "../../helpers/Constants"
import { setOptionalError } from "../../helpers/FormHelper"
import { createRole, loadRole, removeRole, updateRole } from "../../services/Role"

interface Props {
    mode: "Create" | "Update"
}

interface Inputs {
    name: string
    type: ROLE_TYPE
    color: number | undefined
    roleGroup: string
    hasOccupationTarget: boolean
    isAbsence: boolean
    priority: number
    isDefaultPreference: boolean
}

const EditRolePage: FC<Props> = ({ mode }) => {
    const { t } = useTranslation()
    const location = useSelectedLocation()
    const roleGroups = useRoleGroupsForSelectedLocation()
    const { setActiveTab } = useTabs()
    const params = useParams()
    const id = useMemo(() => parseInt(params.id!), [params])
    const navigate = useNavigate()
    const { reloadUserSettings } = useContext(UserSettingsContext)

    const {
        register,
        setError,
        clearErrors,
        setValue,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm<Inputs>({
        defaultValues: {
            name: "",
            type: "regular",
            color: undefined,
            roleGroup: "",
            hasOccupationTarget: false,
            isAbsence: false,
            priority: 1,
            isDefaultPreference: true,
        },
    })

    const [deletionWarningVisible, setDeletionWarningVisible] = useState(false)

    const rolePriorityOptions = useMemo(() => {
        return [
            { id: 0, name: t("EditRolePage.priority_none") },
            { id: 1, name: t("EditRolePage.priority_medium") },
            { id: 2, name: t("EditRolePage.priority_high") },
        ]
    }, [t])

    const roleTypeOptions = useMemo(() => {
        const options: { id: ROLE_TYPE; name: string }[] = [
            { id: "regular", name: t("EditRolePage.type_regular") },
            { id: "leave", name: t("EditRolePage.type_leave") },
            { id: "sick", name: t("EditRolePage.type_sick") },
            { id: "break", name: t("EditRolePage.type_break") },
        ]
        return options
    }, [t])

    const { data: role } = useQuery(["Role", id], loadRole(id), { enabled: mode !== "Create" })

    useEffect(() => {
        if (!role) {
            return
        }
        setValue("name", role.name)
        setValue("type", role.type)
        setValue("color", role.color)
        setValue("roleGroup", role.roleGroup ? `${role.roleGroup}` : "")
        setValue("hasOccupationTarget", role.hasOccupationTarget)
        setValue("isAbsence", role.isAbsence)
        setValue("priority", role.priority)
        setValue("isDefaultPreference", role.isDefaultPreference)
    }, [role])

    const onSuccess = useCallback(() => {
        reloadUserSettings()
        navigate("/taken")
    }, [reloadUserSettings, navigate])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "name", data.name)
            setOptionalError(setError, "type", data.type)
            setOptionalError(setError, "color", data.color)
            setOptionalError(setError, "roleGroup", data.roleGroup)
            setOptionalError(setError, "hasOccupationTarget", data.hasOccupationTarget)
            setOptionalError(setError, "isAbsence", data.isAbsence)
            setOptionalError(setError, "priority", data.priority)
            setOptionalError(setError, "isDefaultPreference", data.isDefaultPreference)
            setOptionalError(setError, "root", data.nonFieldErrors)
        },
        [setError]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ name, type, color, roleGroup, hasOccupationTarget, isAbsence, priority, isDefaultPreference }) => {
            if (mode === "Create") {
                createRole({
                    location: location.id,
                    name,
                    type,
                    color,
                    roleGroup: roleGroup ? parseInt(roleGroup) : null,
                    hasOccupationTarget,
                    isAbsence,
                    priority,
                    isDefaultPreference,
                })
                    .then(onSuccess)
                    .catch(onFailure)
            } else if (mode === "Update") {
                updateRole({
                    id,
                    name,
                    type,
                    color,
                    roleGroup: roleGroup ? parseInt(roleGroup) : null,
                    hasOccupationTarget,
                    isAbsence,
                    priority,
                    isDefaultPreference,
                })
                    .then(onSuccess)
                    .catch(onFailure)
            }
        },
        [id, location, onSuccess, onFailure]
    )

    const onDeleteRole = useCallback(() => {
        if (!deletionWarningVisible) {
            setDeletionWarningVisible(true)
            return
        }

        removeRole(id).then(onSuccess).catch(onFailure)
    }, [deletionWarningVisible, setDeletionWarningVisible, id, onSuccess, onFailure])

    useEffect(() => setActiveTab("Roles"), [setActiveTab])

    const name = watch("name")

    const crumbTitle = useMemo(() => (mode === "Create" ? t("Main.new") : name), [name])
    const pageTitle = useMemo(() => (mode === "Create" ? t("EditRolePage.new_role") : name), [name])

    const color = watch("color")
    const setColor = useCallback(
        (color: number) => {
            setValue("color", color)
            clearErrors("color")
        },
        [setValue]
    )

    return (
        <RegularPage id="EditRole" breadcrumbs={[{ title: t("RolesPage.title_roles"), href: "/taken" }, { title: crumbTitle }]}>
            <Form noValidate onSubmit={handleSubmit(onSubmit)} className="spacer">
                <h2>{pageTitle}</h2>
                <Form.Group>
                    <Form.Label>{t("Forms.name")}</Form.Label>
                    <Form.Control size="lg" type="text" {...register("name")} isInvalid={!!errors.name} />
                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("Forms.type")}</Form.Label>
                    <Form.Select size="lg" {...register("type")} isInvalid={!!errors.type}>
                        {roleTypeOptions.map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.type?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("Forms.color")}</Form.Label>
                    <FormColorSelect selectedColor={color} setSelectedColor={setColor} />
                    <Form.Control type="hidden" isInvalid={!!errors.color} />
                    <Form.Control.Feedback type="invalid">{errors.color?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("EditRolePage.role_group")}</Form.Label>
                    <InfoPopover id="roleGroup" body={t("EditRolePage.role_group_description")} />
                    <Form.Select size="lg" {...register("roleGroup")} isInvalid={!!errors.roleGroup}>
                        <option value="">{t("Main.choose")}</option>
                        {roleGroups.map(({ id, name }) => (
                            <option key={id} value={`${id}`}>
                                {name}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.roleGroup?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("EditRolePage.priority_for_shift_template_generation")}</Form.Label>
                    <InfoPopover id="priority" body={t("EditRolePage.priority_description")} />
                    <Form.Select size="lg" {...register("priority")} isInvalid={!!errors.priority}>
                        {rolePriorityOptions.map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.priority?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("EditRolePage.default_enabled")}</Form.Label>
                    <Form.Check {...register("isDefaultPreference")} isInvalid={!!errors.isDefaultPreference} />
                    <Form.Control.Feedback type="invalid">{errors.isDefaultPreference?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("EditRolePage.has_occupation_target")}</Form.Label>
                    <InfoPopover id="hasOccupationTarget" body={t("EditRolePage.has_occupation_target_description")} />
                    <Form.Check {...register("hasOccupationTarget")} isInvalid={!!errors.hasOccupationTarget} />
                    <Form.Control.Feedback type="invalid">{errors.hasOccupationTarget?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("EditRolePage.is_absence")}</Form.Label>
                    <InfoPopover id="isAbsence" body={t("EditRolePage.is_absence_description")} />
                    <Form.Check {...register("isAbsence")} isInvalid={!!errors.isAbsence} />
                    <Form.Control.Feedback type="invalid">{errors.isAbsence?.message}</Form.Control.Feedback>
                </Form.Group>
                <div className="d-flex align-items-center">
                    <Button type="submit" className="me-2">
                        {t("Buttons.save")}
                    </Button>
                    {mode === "Update" ? (
                        <Button type="button" onClick={onDeleteRole} variant="danger">
                            {t("Buttons.delete")}
                        </Button>
                    ) : null}
                    {deletionWarningVisible ? (
                        <div className="ms-1 mt-2">
                            <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                            <span className="me-1">{t("EditRolePage.are_you_sure_delete")}</span>
                            <Button variant="link" type="button" onClick={onDeleteRole}>
                                {t("Buttons.yes_delete")}
                            </Button>
                        </div>
                    ) : null}
                </div>
                <Form.Group hidden={!errors.root}>
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid" data-cy="root_errors">
                        {errors.root?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <RolesLegend />
            </Form>
        </RegularPage>
    )
}

export default EditRolePage
