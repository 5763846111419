import AfasLeaveBalanceType from "../types/AfasLeaveBalanceType"
import AfasUserConnectionType from "../types/AfasUserConnectionType"
import api from "./Api"

export function loadAfasUserConnections(afasConnection: number, user: number): Promise<AfasUserConnectionType[]> {
    return new Promise<AfasUserConnectionType[]>((resolve, reject) =>
        getAfasUserConnections(afasConnection, user)
            .then((response) => resolve(response.data.items))
            .catch(reject)
    )
}

export function getAfasUserConnections(afasConnection: number, user: number) {
    return api.get<{ items: AfasUserConnectionType[] }>(`/afas-user-connections/?connection=${afasConnection}&user=${user}`)
}

export function getAfasUserConnectionLeaveBalance(afasUserConnection: number) {
    return api.get<{ items: AfasLeaveBalanceType[] }>(`/afas-user-connections/${afasUserConnection}/leave-balance/`)
}
