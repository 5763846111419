import { FC, useCallback, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import RegularPage from "../../components/page/RegularPage"
import ShiftEditor from "../../components/shiftEditor/ShiftEditor"
import { ScheduleEditorContextProvider } from "../../contexts/ScheduleEditorContext"
import { useTabs } from "../../contexts/TabsContext"
import { useSelectedLocation } from "../../contexts/UserSettingsContext"
import { getFirstEnabledDayIndex } from "../../helpers/DaysHelper"
import { loadOccupationsForShiftTemplate } from "../../helpers/OccupationHelper"
import { loadScheduleForShiftTemplate } from "../../helpers/ShiftHelper"
import { getShiftTemplate } from "../../services/ShiftTemplate"
import LocationType from "../../types/LocationType"
import ShiftTemplateType from "../../types/ShiftTemplateType"

const UpdateShiftTemplatePage: FC = () => {
    const { t } = useTranslation()
    const { setActiveTab } = useTabs()
    const location = useSelectedLocation()
    const params = useParams()
    const id = useMemo(() => parseInt(params.id!), [params])
    const initialDay = useMemo(() => getFirstEnabledDayIndex(location.enabledDays), [location])
    const navigate = useNavigate()

    const loadShiftTemplate = useCallback(() => {
        return new Promise<ShiftTemplateType>((resolve) => {
            getShiftTemplate(id).then((response) => {
                if (response.data.location !== location.id) {
                    navigate("/basisroosters")
                    return
                }
                resolve(response.data)
            })
        })
    }, [id])
    const loadSchedule = useCallback((shiftTemplate: ShiftTemplateType | null, _location: LocationType | null, _date: Date | null) => loadScheduleForShiftTemplate(shiftTemplate!), [])
    const loadOccupations = useCallback((shiftTemplate: ShiftTemplateType | null, _location: LocationType | null, _date: Date | null) => loadOccupationsForShiftTemplate(shiftTemplate!), [])

    useEffect(() => setActiveTab("ShiftTemplates"), [setActiveTab])

    return (
        <RegularPage id="UpdateShiftTemplate" breadcrumbs={[{ title: t("ShiftTemplatesPage.title_shift_templates"), href: "/basisroosters" }, { title: id.toString() }]}>
            <ScheduleEditorContextProvider
                mode="DAYS"
                initialLocation={location}
                initialDay={initialDay}
                loadShiftTemplate={loadShiftTemplate}
                loadSchedule={loadSchedule}
                loadOccupations={loadOccupations}
            >
                <ShiftEditor />
            </ScheduleEditorContextProvider>
        </RegularPage>
    )
}

export default UpdateShiftTemplatePage
