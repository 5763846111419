import { faBars, faCirclePlay, faEarthAmericas } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ChangeEvent, ChangeEventHandler, FC, useCallback, useContext, useMemo } from "react"
import { Badge, Button, Form, Nav, Navbar, NavDropdown } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useTabs } from "../contexts/TabsContext"
import { useStartTour } from "../contexts/TourContext"
import { useCurrentCompany, useInitMode, useIsAdmin, useIsAuthenticated, useIsStaff, useLocations, useOptionalCurrentUser, UserSettingsContext } from "../contexts/UserSettingsContext"
import { dateFromDjango, isoPrint } from "../helpers/DaysHelper"
import logo from "../images/logo.png"
import { UserInitMode } from "../types/UserType"
import AdminsOnline from "./AdminsOnline"
import LanguageButton from "./LanguageButton"
import styles from "./TopBar.module.scss"

const TopBar: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const currentUser = useOptionalCurrentUser()
    const currentCompany = useCurrentCompany()
    const locations = useLocations()
    const isAuthenticated = useIsAuthenticated()
    const { setMenuOpen } = useTabs()
    const initMode = useInitMode()
    const startTour = useStartTour()
    const isAdmin = useIsAdmin()
    const isStaff = useIsStaff()
    const { selectedLocationId, selectLocation } = useContext(UserSettingsContext)

    const selectedLocationValue = useMemo(() => selectedLocationId?.toString() ?? "", [selectedLocationId])

    const username = useMemo(() => {
        return `${currentUser?.firstName} ${currentUser?.lastName}`
    }, [currentUser])

    const onChangeLocationValue: ChangeEventHandler<HTMLSelectElement> = useCallback(
        (event: ChangeEvent) => {
            const target = event.target as HTMLSelectElement
            selectLocation(parseInt(target.value))
        },
        [selectLocation]
    )

    const toSettings = useCallback(() => navigate("/instellingen"), [navigate])

    const toMyAccount = useCallback(() => navigate("/mijn-account"), [navigate])

    const toAdminApp = useCallback(() => window.location.replace(process.env.REACT_APP_ADMIN_APP_URL!), [])

    const logout = useCallback(() => navigate("/uitloggen"), [navigate])

    const openMenu = useCallback(() => {
        setMenuOpen(true)
    }, [setMenuOpen])

    return (
        <Navbar>
            {isAuthenticated && initMode >= UserInitMode.STARTED_TOUR ? (
                <>
                    <Navbar.Brand className="d-none d-lg-block">
                        <img className={styles.logo} src={logo} alt="logo" />
                        <small className="fw-bold">{t("logo_text")}</small>
                    </Navbar.Brand>
                    <Nav className="d-block d-lg-none">
                        <Button onClick={openMenu} variant="link" className={styles.menuButton}>
                            <FontAwesomeIcon icon={faBars} />
                        </Button>
                    </Nav>
                    {currentCompany?.activeUntil ? (
                        <div>
                            <Badge bg="danger">{t("TopBar.trial_until", { date: isoPrint(dateFromDjango(currentCompany.activeUntil)) })}</Badge>
                            {isAdmin && initMode === UserInitMode.STARTED_TOUR ? (
                                <Button variant="link" onClick={startTour}>
                                    <FontAwesomeIcon icon={faCirclePlay} className="me-1" />
                                    {t("TopBar.tour_explanation")}
                                </Button>
                            ) : null}
                        </div>
                    ) : null}
                </>
            ) : null}
            <Nav className="ms-auto align-items-center">
                {locations.length > 1 ? (
                    <Form.Select value={selectedLocationValue} onChange={onChangeLocationValue} className="form-select-inline mx-2" data-cy="locationSelector">
                        {locations.map(({ id, name }) => (
                            <option key={`${id}`} value={`${id}`}>
                                {name}
                            </option>
                        ))}
                    </Form.Select>
                ) : null}
                {isAdmin && initMode >= UserInitMode.STARTED_TOUR ? <AdminsOnline /> : null}
                {["Dev", "Test"].includes(process.env.REACT_APP_ENV_ID!) ? (
                    <NavDropdown title={<FontAwesomeIcon icon={faEarthAmericas} />} align="end">
                        <LanguageButton title="English" language="en" />
                        <LanguageButton title="Nederlands" language="nl" />
                    </NavDropdown>
                ) : null}
                {isAuthenticated && initMode >= UserInitMode.STARTED_TOUR ? (
                    <NavDropdown title={username} align="end" data-cy="user-dropdown-toggle">
                        {isAdmin ? <NavDropdown.Item onClick={toSettings}>{t("TopBar.settings")}</NavDropdown.Item> : null}
                        <NavDropdown.Item onClick={toMyAccount}>{t("TopBar.my_account")}</NavDropdown.Item>
                        {["Dev", "Test"].includes(process.env.REACT_APP_ENV_ID!) ? (
                            <NavDropdown.Item href="https://servicedesk.rooster-planner.nl/tickets" target="_blank">
                                {t("TopBar.servicedesk")}
                            </NavDropdown.Item>
                        ) : null}
                        {isStaff ? <NavDropdown.Item onClick={toAdminApp}>{t("TopBar.to_admin")}</NavDropdown.Item> : null}
                        <NavDropdown.Item onClick={logout} data-cy="logout">
                            {t("log_out")}
                        </NavDropdown.Item>
                    </NavDropdown>
                ) : null}
            </Nav>
        </Navbar>
    )
}

export default TopBar
