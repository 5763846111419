import { FC, MouseEventHandler, useCallback } from "react"
import { useCompanyModules } from "../../contexts/UserSettingsContext"
import { COMPANY_MODULE_REMOTE_WORKING } from "../../helpers/Constants"
import { isAppliccable } from "../../helpers/HiringAvailabilityHelper"
import { getCellValues, ScheduleItem } from "../../helpers/ScheduleMapHelper"
import { CellSelection, isSelected, ShiftEditorCellDetails } from "../../helpers/editor/ShiftEditorHelper"
import useRoleColorMap from "../../hooks/UseRoleColorMap"
import useRoleNameMap from "../../hooks/UseRoleNameMap"
import HiringAvailabilityType, { HiringAvailabilityStatusWithUnknown } from "../../types/HiringAvailabilityType"
import { LocalityOptionOrInherit } from "../../types/LocalityType"
import RoleType from "../../types/RoleType"
import { UserAvailabilityType } from "../../types/UserAvailabilityType"
import { UserType, UserTypeType } from "../../types/UserType"
import InfoPopover from "../InfoPopover"
import LocalityButton from "./LocalityButton"
import ShiftsEditorSlot from "./ShiftsEditorSlot"

interface ShiftsEditorUserProps {
    dayId: string
    user: UserType
    startSlot: number
    endSlot: number
    scheduleItem: ScheduleItem | undefined
    cellSelection: CellSelection
    roles: RoleType[]
    enabledRoles: number[]
    enabledAvailabilities: HiringAvailabilityStatusWithUnknown[]
    selectCell: MouseEventHandler<HTMLDivElement>
    hiringAvailability: HiringAvailabilityType | undefined
    setLocality: (user: number, locality: LocalityOptionOrInherit) => void
    userAvailability: UserAvailabilityType | undefined
}

export type SlotType = "ROLE" | "AVAILABILITY"

const ShiftsEditorUser: FC<ShiftsEditorUserProps> = ({
    dayId,
    user,
    startSlot,
    endSlot,
    scheduleItem,
    cellSelection,
    roles,
    enabledRoles,
    enabledAvailabilities,
    selectCell,
    hiringAvailability,
    setLocality,
    userAvailability,
}) => {
    const values = getCellValues(startSlot, endSlot, scheduleItem ? scheduleItem.shifts : [])
    const roleColorMap = useRoleColorMap(roles)
    const roleNameMap = useRoleNameMap(roles)
    const companyModules = useCompanyModules()

    const getSlotState = (slot: number, value: number, availability: HiringAvailabilityStatusWithUnknown | null, nthColor: number, isMuted: boolean, slotType: SlotType): ShiftEditorCellDetails => {
        const i = slot - startSlot
        const isFirstOfShift = value > 0 && (i === 0 || values[i - 1] !== value)
        const selected = isSelected(cellSelection, user.id, i)
        const color = value > 0 ? roleColorMap.get(value)! : null
        const roleName = roleNameMap.get(value)!

        return {
            color: color,
            selected: selected,
            isFirstOfShift: isFirstOfShift,
            roleName: roleName,
            nthColor: nthColor,
            isMuted: isMuted,
            slotType: slotType,
            availability: availability,
            isInAvailability: !!userAvailability && slot >= userAvailability.startSlot && slot <= userAvailability.endSlot,
        }
    }

    const getSlots = (dayId: string, user: number, userType: UserTypeType, startSlot: number, endSlot: number) => {
        let slots = []
        let id: string
        let isMuted = false
        let nthColor = 0
        let lastRole: number | null = null
        let lastAvailability: HiringAvailabilityStatusWithUnknown | null = null
        let colorCount: number = 0
        let hasCellWithinFilter = false
        let slotType: SlotType = "ROLE"

        if (userType === "EXTERNAL") {
            if (hiringAvailability && enabledAvailabilities.indexOf(hiringAvailability.status) !== -1) {
                hasCellWithinFilter = true
            } else if (!hiringAvailability && enabledAvailabilities.indexOf("UNKNOWN") !== -1) {
                hasCellWithinFilter = true
            }
        }

        for (let slot = startSlot; slot <= endSlot; slot++) {
            const i = slot - startSlot
            const role = values[i]
            const availability = hiringAvailability && isAppliccable(hiringAvailability, slot) ? hiringAvailability.status : null

            if (role > 0) {
                slotType = "ROLE"
            } else if (userType === "EXTERNAL") {
                slotType = "AVAILABILITY"
            } else {
                slotType = "ROLE"
            }

            if (slotType === "AVAILABILITY") {
                if (availability !== lastAvailability) {
                    lastRole = null
                    lastAvailability = availability
                    nthColor = ++colorCount
                }
                isMuted = false
            } else if (slotType === "ROLE") {
                if (role !== lastRole) {
                    lastRole = role
                    lastAvailability = null
                    nthColor = ++colorCount
                }
                const isEnabledRole = enabledRoles.indexOf(role) !== -1
                isMuted = !isEnabledRole
                if (isEnabledRole) {
                    hasCellWithinFilter = true
                }
            }

            id = dayId + "#" + user + "#" + i
            slots.push(<ShiftsEditorSlot key={id + "#" + role} id={id} slot={slot} selectCell={selectCell} details={getSlotState(slot, role, availability, nthColor, isMuted, slotType)} />)
        }
        if (!hasCellWithinFilter) {
            return null
        }
        return slots
    }

    const setType = useCallback(
        (type: LocalityOptionOrInherit) => {
            setLocality(user.id, type)
        },
        [setLocality, user]
    )

    const order = user.type === "EXTERNAL" ? 999 : undefined
    const slots = getSlots(dayId, user.id, user.type, startSlot, endSlot)
    if (!slots) {
        return null
    }

    return (
        <div className="editor-cell-container" data-cy="editorRow" style={{ order: order }}>
            <div className="editor-cell-title text-bold" data-cy="editorRowTitle">
                <span className="editor-cell-title-label">
                    {companyModules.includes(COMPANY_MODULE_REMOTE_WORKING) ? <LocalityButton type={scheduleItem ? scheduleItem.locality : "UNKNOWN"} setType={setType} /> : null}
                    {user.firstName} {user.lastName}
                </span>
                {hiringAvailability?.message ? (
                    <div>
                        <InfoPopover id={hiringAvailability.id.toString()} body={hiringAvailability.message} />
                    </div>
                ) : null}
            </div>
            {slots}
        </div>
    )
}

export default ShiftsEditorUser
