import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback } from "react"
import { Button } from "react-bootstrap"
import { useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useCurrentUser } from "../../contexts/UserSettingsContext"

interface UserArrayProps {
    control: any
    register: any
}

const UserArray: FC<UserArrayProps> = ({ control, register }) => {
    const { t } = useTranslation()
    const currentUser = useCurrentUser()
    const { fields, append, remove } = useFieldArray({ control, name: "users" })

    const onAdd = useCallback(() => append({ firstName: "", lastName: "" }), [append])

    return (
        <>
            <table>
                <thead>
                    <tr>
                        <td className="p-2"></td>
                        <td className="ps-2">
                            <span className="input-label">{t("Forms.first_name")}</span>
                        </td>
                        <td className="ps-2">
                            <span className="input-label">{t("Forms.last_name")}</span>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="p-2">
                            <span className="text-small text-muted">1</span>
                        </td>
                        <td className="p-2">
                            <input disabled className="form-control" value={currentUser.firstName} />
                        </td>
                        <td className="p-2">
                            <input disabled className="form-control" value={currentUser.lastName} />
                        </td>
                    </tr>

                    {fields.map((item, index) => {
                        return (
                            <tr key={item.id}>
                                <td className="p-2">
                                    <span className="text-small text-muted">{index + 2}</span>
                                </td>
                                <td className="p-2">
                                    <input {...register(`users.${index}.firstName`, { required: true })} data-cy={`users.${index}.firstName`} className="form-control" />
                                </td>
                                <td className="p-2">
                                    <input {...register(`users.${index}.lastName`, { required: true })} data-cy={`users.${index}.lastName`} className="form-control" />
                                </td>
                                <td className="p-2">
                                    <Button type="button" variant="link" data-cy={`users.${index}.delete`} onClick={() => remove(index)}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <Button type="button" variant="link" onClick={onAdd}>
                {t("Buttons.add")}
            </Button>
        </>
    )
}

export default UserArray
