import { faCalendarDays, faClock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"
import DatePicker from "react-datepicker"
import { useTranslation } from "react-i18next"
import UserTimeInputPopover from "../user/UserTimeInput/UserTimeInputPopover"
import styles from "./DateTimeSelector.module.scss"
import InlineGroupLabel from "./InlineGroupLabel"

interface DateTimeSelectorProps {
    selectedDate?: Date
    setSelectedDate: (date: Date | null) => void
    dateTabIndex: number
    dateIsError: boolean
    selectedTime: string
    setSelectedTime: (time: string) => void
    timeTabIndex: number
    timeIsError: boolean
    startSlot: number
    endSlot: number
    disabled?: boolean
    inline: boolean
    type: "start" | "end"
}

const DateTimeSelector: FC<DateTimeSelectorProps> = ({
    selectedDate,
    setSelectedDate,
    dateTabIndex,
    dateIsError,
    selectedTime,
    setSelectedTime,
    timeTabIndex,
    timeIsError,
    startSlot,
    endSlot,
    disabled,
    inline,
    type,
}) => {
    const { t } = useTranslation()

    return (
        <div className={inline ? styles.container : "input-group"}>
            <InlineGroupLabel inline={inline}>
                <FontAwesomeIcon icon={faCalendarDays} />
            </InlineGroupLabel>
            <DatePicker
                selected={selectedDate}
                onChange={setSelectedDate}
                showPopperArrow={false}
                calendarStartDay={1}
                todayButton={t("Main.today")}
                showWeekNumbers
                dateFormat="d-M-yyyy"
                className={"form-control " + (inline ? "m-1" : styles.dateInput) + (dateIsError ? " form-input-error" : "")}
                tabIndex={dateTabIndex}
                disabled={!!disabled}
                wrapperClassName={inline ? null : styles.wrapper}
            />
            <InlineGroupLabel inline={inline}>
                <FontAwesomeIcon icon={faClock} />
            </InlineGroupLabel>
            <UserTimeInputPopover startSlot={startSlot} endSlot={endSlot} selectedTime={selectedTime} setSelectedTime={setSelectedTime} type={type} inline={inline} />
        </div>
    )
}

export default DateTimeSelector
