import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react"
import { Form, InputGroup } from "react-bootstrap"
import DatePicker from "react-datepicker"
import { FieldErrors, UseFormClearErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { UserEditInputs, UserEditMode } from "../../../pages/users/UserEditPage"
import { EditUserAvailabilityType } from "../../../types/UserType"
import InfoPopover from "../../InfoPopover"
import DatePickerHeader from "../../table/DatePickerHeader"
import UserTabErrors from "../UserTabErrors"
import { UserTab } from "../UserTabs"
import UserTimeTable from "./UserTimeTable"
import WeekOptions from "./WeekOptions"

interface Props {
    selectedTab: UserTab
    register: UseFormRegister<UserEditInputs>
    watch: UseFormWatch<UserEditInputs>
    setValue: UseFormSetValue<UserEditInputs>
    clearErrors: UseFormClearErrors<UserEditInputs>
    mode: UserEditMode
    errors: FieldErrors<UserEditInputs>
    tabErrors: UserTab[]
}

const UserTimeForm: FC<Props> = ({ selectedTab, register, watch, setValue, clearErrors, mode, errors, tabErrors }) => {
    const { t } = useTranslation()
    const availabilities = watch("availabilities")
    const employableFrom = watch("employableFrom")
    const employableTo = watch("employableTo")
    const weekCycle = watch("weekCycle")

    const [selectedWeek, setSelectedWeek] = useState(0)

    const setEmployableFrom = useCallback(
        (newValue: Date | null) => {
            setValue("employableFrom", newValue ?? undefined)
        },
        [setValue]
    )

    const setEmployableTo = useCallback(
        (newValue: Date | null) => {
            setValue("employableTo", newValue ?? undefined)
        },
        [setValue]
    )

    const onChangeWeek = useCallback(
        (event: ChangeEvent) => {
            const target = event.target as HTMLSelectElement
            setSelectedWeek(parseInt(target.value))
        },
        [setSelectedWeek]
    )

    const employableFromIsError = useMemo(() => {
        return !!errors.employableFrom
    }, [errors])

    const employableToIsError = useMemo(() => {
        return !!errors.employableTo
    }, [errors])

    const copyWeekAvailabilities = useCallback(
        (fromWeek: number, toWeek: number) => {
            const copiedAvailabilities: EditUserAvailabilityType[] = availabilities
                .filter((a) => a.day >= fromWeek * 7 && a.day < (fromWeek + 1) * 7)
                .map(({ day, startTime, endTime }) => {
                    return {
                        day: day + (toWeek - fromWeek) * 7,
                        startTime,
                        endTime,
                    }
                })
            setValue("availabilities", [...availabilities, ...copiedAvailabilities])
        },
        [availabilities, setValue]
    )

    useEffect(() => {
        for (let i = 1; i < weekCycle; i++) {
            const hasAvailabilityForWeek = !!availabilities.find((a) => a.day >= i * 7 && a.day < (i + 1) * 7)
            if (!hasAvailabilityForWeek) {
                copyWeekAvailabilities(i - 1, i)
            }
        }
    }, [weekCycle])

    if (selectedTab !== "times") {
        return null
    }

    return (
        <div>
            {mode !== "update-my" ? (
                <Form.Group className="mb-3">
                    <Form.Label>{t("UserTimeForm.employable")}</Form.Label>
                    <InfoPopover
                        id="employableFrom"
                        body="Medewerkers worden alleen getoond in overzichten als ze inzetbaar zijn. Met deze optie kun je dus medewerkers uit overzichten halen, bijvoorbeeld bij uitdiensttreding, zonder medewerkers en historische data volledig uit het systeem te verwijderen."
                    />
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1">{t("UserTimeForm.from")}</InputGroup.Text>
                        <DatePicker
                            selected={employableFrom}
                            onChange={setEmployableFrom}
                            showPopperArrow={false}
                            calendarStartDay={1}
                            todayButton={t("Main.today")}
                            showWeekNumbers
                            dateFormat="d-M-yyyy"
                            className={"form-control form-control-lg input-group-middle" + (employableFromIsError ? " form-input-error" : "")}
                            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => <DatePickerHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />}
                        />
                        <InputGroup.Text id="basic-addon1">{t("UserTimeForm.to")}</InputGroup.Text>
                        <DatePicker
                            selected={employableTo}
                            onChange={setEmployableTo}
                            showPopperArrow={false}
                            calendarStartDay={1}
                            todayButton={t("Main.today")}
                            showWeekNumbers
                            dateFormat="d-M-yyyy"
                            className={"form-control form-control-lg input-group-end" + (employableToIsError ? " form-input-error" : "")}
                            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => <DatePickerHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />}
                        />
                    </InputGroup>
                    <Form.Control type="hidden" isInvalid={!!errors.employableFrom} />
                    <Form.Control type="hidden" isInvalid={!!errors.employableTo} />
                    <Form.Control.Feedback type="invalid">{errors.employableFrom?.message}</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.employableTo?.message}</Form.Control.Feedback>
                </Form.Group>
            ) : null}
            <Form.Group className="mb-3">
                <Form.Label>{t("UserTimeForm.week_cycle")}</Form.Label>
                <InfoPopover
                    id="weekCyclePopover"
                    body="Indien er met even en oneven weken gewerkt wordt, kies je hier voor 2. Dit komt bijvoorbeeld voor als er medewerkers zijn die om de week een dag vrij zijn."
                />
                <Form.Control size="lg" type="number" {...register("weekCycle")} isInvalid={!!errors.weekCycle} tabIndex={3} disabled={mode === "update-my"} />
                <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>{t("UserTimeForm.work_times")}</Form.Label>
                {weekCycle > 1 ? (
                    <Form.Select size="lg" value={selectedWeek} onChange={onChangeWeek}>
                        <WeekOptions weekCycle={weekCycle} />
                    </Form.Select>
                ) : null}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Control type="hidden" isInvalid={!!errors.availabilities} />
                <UserTimeTable selectedWeek={selectedWeek} disabled={mode === "update-my"} watch={watch} setValue={setValue} clearErrors={clearErrors} />
                <Form.Control.Feedback type="invalid">{errors.availabilities?.message}</Form.Control.Feedback>
            </Form.Group>
            <UserTabErrors tabErrors={tabErrors} except="times" />
        </div>
    )
}

export default UserTimeForm
