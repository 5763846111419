import { isoPrint } from "../helpers/DaysHelper"
import { getAbsenceRequests } from "../services/AbsenceRequest"
import AbsenceRequestType from "../types/AbsenceRequestType"

export const loadAbsenceRequests = (fromDate: Date, user: number): Promise<AbsenceRequestType[]> => {
    return new Promise((resolve) => {
        if (!fromDate || !user) {
            resolve([])
            return
        }
        getAbsenceRequests({
            pageIndex: 0,
            pageSize: 99,
            filters: [
                {
                    key: "dateFrom",
                    value: isoPrint(fromDate),
                },
                {
                    key: "user",
                    value: user.toString(),
                },
            ],
        }).then((response) => resolve(response.data.items))
    })
}
