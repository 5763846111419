import { ColumnDef } from "@tanstack/react-table"
import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import CheckMarkCell from "../../components/table/cells/CheckMarkCell"
import Table from "../../components/table/Table"
import { dateTimeFromDjango, prettyPrintDateTime } from "../../helpers/DaysHelper"
import { loadAbsenceRequestAfasSyncs } from "../../services/AbsenceRequestAfasSync"
import AbsenceRequestAfasSyncType from "../../types/AbsenceRequestAfasSyncType"

interface Props {
    absenceRequestId: number
}

const AbsenceRequestAfasSyncsTable: FC<Props> = ({ absenceRequestId }) => {
    const { t } = useTranslation()

    const columns = useMemo(
        (): ColumnDef<AbsenceRequestAfasSyncType>[] => [
            {
                id: "createdAt",
                header: t("AbsenceRequestAfasSyncsTable.created_at"),
                cell: ({ row }) => <div>{prettyPrintDateTime(dateTimeFromDjango(row.original.createdAt))}</div>,
            },
            {
                id: "leaveType",
                header: t("AbsenceRequestAfasSyncsTable.leave_type"),
                cell: ({ row }) => <div>{row.original.leaveType}</div>,
            },
            {
                id: "dateTimeFrom",
                header: t("AbsenceRequestAfasSyncsTable.date_time_from"),
                cell: ({ row }) => <div>{prettyPrintDateTime(dateTimeFromDjango(row.original.dateTimeFrom))}</div>,
            },
            {
                id: "dateTimeTo",
                header: t("AbsenceRequestAfasSyncsTable.date_time_to"),
                cell: ({ row }) => <div>{prettyPrintDateTime(dateTimeFromDjango(row.original.dateTimeTo))}</div>,
            },
            {
                id: "message",
                header: t("AbsenceRequestAfasSyncsTable.message"),
                cell: ({ row }) => <div>{row.original.message}</div>,
            },
            {
                id: "partialLeave",
                header: t("AbsenceRequestAfasSyncsTable.partial_leave"),
                cell: ({ row }) => <CheckMarkCell checked={row.original.partialLeave} markFalse={false} />,
            },
            {
                id: "succeeded",
                header: t("AbsenceRequestAfasSyncsTable.succeeded"),
                cell: ({ row }) => <CheckMarkCell checked={row.original.succeeded} markFalse={true} />,
            },
        ],
        [t]
    )

    return (
        <Table
            id="AbsenceRequestAfasSync"
            columns={columns}
            filters={[
                {
                    id: "absenceRequest",
                    type: "hidden",
                    initialValues: [{ key: "absenceRequest", value: absenceRequestId.toString() }],
                },
            ]}
            fetchData={loadAbsenceRequestAfasSyncs}
            subject={t("AbsenceRequestAfasSyncsTable.no_items_in_table_name")}
            minWidth="600px"
        />
    )
}

export default AbsenceRequestAfasSyncsTable
