import { faAngleRight, faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ColumnDef } from "@tanstack/react-table"
import { FC, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import RegularPage from "../../components/page/RegularPage"
import RoleBadge from "../../components/RoleBadge"
import Table, { TableFilterDef } from "../../components/table/Table"
import { useTabs } from "../../contexts/TabsContext"
import { useContinueTour } from "../../contexts/TourContext"
import { STEP_INDEX_MUTATIONS } from "../../contexts/TourSteps"
import { useIsAdmin, useSelectedLocation, useUsersForSelectedLocation } from "../../contexts/UserSettingsContext"
import { dateFromDjango, getTimeString, isoPrint } from "../../helpers/DaysHelper"
import { getLocationFilter } from "../../helpers/LocationHelper"
import useResolveRoleForSelectedLocation from "../../hooks/UseResolveRole"
import useResolveUserName from "../../hooks/UseResolveUserName"
import { loadShiftMutations } from "../../services/ShiftMutation"
import ShiftMutationType from "../../types/ShiftMutationType"

const MutationsPage: FC = () => {
    const { t } = useTranslation()
    const location = useSelectedLocation()
    const users = useUsersForSelectedLocation()
    const isAdmin = useIsAdmin()
    const { setActiveTab } = useTabs()
    const continueTour = useContinueTour()
    const resolveUserName = useResolveUserName(users)
    const resolveRole = useResolveRoleForSelectedLocation()

    const columns = useMemo(
        (): ColumnDef<ShiftMutationType>[] => [
            {
                id: "user",
                header: t("MutationsPage.user"),
                cell: ({ row }) => (
                    <div>
                        {!row.original.viewed ? <FontAwesomeIcon icon={faCircle} className="me-2 text-blue" size="2xs" /> : null}
                        <Link to={`/wijzigingen/${row.original.id}`}>{resolveUserName(row.original.user)}</Link>
                    </div>
                ),
            },
            {
                id: "date",
                header: t("MutationsPage.date"),
                cell: ({ row }) => {
                    return <span>{isoPrint(dateFromDjango(row.original.date))}</span>
                },
            },
            {
                id: "time",
                header: t("MutationsPage.time"),
                cell: ({ row }) => {
                    return (
                        <span className="text-bold">
                            {getTimeString("start", row.original.startSlot)} - {getTimeString("end", row.original.endSlot)}
                        </span>
                    )
                },
            },
            {
                id: "originalRole",
                header: t("MutationsPage.mutation"),
                cell: ({ row }) => {
                    return (
                        <div>
                            <RoleBadge role={row.original.originalRole ? resolveRole(row.original.originalRole) : null} />
                            <FontAwesomeIcon icon={faAngleRight} className="mx-2" />
                            <RoleBadge role={resolveRole(row.original.role)} />
                        </div>
                    )
                },
            },
        ],
        [t, resolveUserName, resolveRole]
    )

    useEffect(() => setActiveTab("Mutations"), [setActiveTab])
    useEffect(() => continueTour(STEP_INDEX_MUTATIONS), [continueTour])

    const dateFilter = useMemo((): TableFilterDef => {
        return { id: "date", type: "daterange", label: t("MutationsPage.filter_period"), initialValues: [{ key: "dateFrom", value: isoPrint(new Date()) }] }
    }, [t])

    const userFilter = useMemo((): TableFilterDef => {
        return {
            id: "user",
            type: "picker",
            label: t("MutationsPage.filter_user"),
            placeholder: t("MutationsPage.filter_user_placeholder"),
            options: users.map((user) => {
                return { key: user.id.toString(), title: `${user.firstName} ${user.lastName}` }
            }),
            initialValues: [{ key: "user", value: "" }],
        }
    }, [t, users])

    const viewedFilter = useMemo((): TableFilterDef => {
        return {
            id: "viewStatus",
            type: "select",
            label: t("MutationsPage.filter_status"),
            placeholder: t("MutationsPage.filter_status_placeholder"),
            options: [
                { key: "NEW", title: t("MutationsPage.filter_unread") },
                { key: "READ", title: t("MutationsPage.filter_read") },
            ],
            initialValues: [{ key: "viewStatus", value: "" }],
        }
    }, [t])

    const viewStatusAtFilter = useMemo((): TableFilterDef => {
        return {
            id: "viewStatusAt",
            type: "hidden",
            initialValues: [{ key: "viewStatusAt", value: new Date().getTime().toString() }],
        }
    }, [])

    const markViewedFilter = useMemo((): TableFilterDef => {
        return {
            id: "markViewed",
            type: "hidden",
            initialValues: [{ key: "markViewed", value: "true" }],
        }
    }, [])

    const locationFilter = useMemo(() => getLocationFilter(location), [location])

    return (
        <RegularPage id="Mutations">
            <h2>{t("MutationsPage.title")}</h2>
            <Table
                id={`${location.id}_ShiftMutations`}
                columns={columns}
                buttons={[]}
                filters={
                    isAdmin
                        ? [locationFilter, dateFilter, userFilter, viewedFilter, viewStatusAtFilter, markViewedFilter]
                        : [locationFilter, dateFilter, viewedFilter, viewStatusAtFilter, markViewedFilter]
                }
                fetchData={loadShiftMutations}
                subject={t("MutationsPage.mutations")}
                minWidth="600px"
            />
        </RegularPage>
    )
}

export default MutationsPage
