import { FC, useCallback, useMemo } from "react"
import { AxisLinearOptions, Chart, Series, SeriesFocusStatus, SeriesStyles } from "react-charts"
import { useColors, useNestedRolesForSelectedLocation } from "../../../../contexts/UserSettingsContext"
import { dateFromDjango, prettyPrintQuarters } from "../../../../helpers/DaysHelper"
import { constructNestedRoleMap } from "../../../../helpers/RolesHelper"
import { RoleHoursDatum, canShowData, getReferenceDate, getRoleHoursData, getXValue } from "../../../../helpers/TaskHelper"
import useGetTimeAxisValue from "../../../../hooks/UseGetTimeAxisValue"
import { IntervalType, TaskType, TaskUserTimeResult } from "../../../../services/Task"
import NoData from "../../NoData"

interface UserHoursGraphProps {
    task?: TaskType<TaskUserTimeResult>
}

const UserHoursGraph: FC<UserHoursGraphProps> = ({ task }) => {
    const colors = useColors()
    const nestedRoles = useNestedRolesForSelectedLocation()
    const getTimeAxisValue = useGetTimeAxisValue()

    const nestedRoleMap = useMemo(() => {
        return constructNestedRoleMap(nestedRoles)
    }, [nestedRoles])

    const taskResult = useMemo(() => {
        return task?.result
    }, [task])

    const data = useMemo(() => {
        return taskResult ? getRoleHoursData(taskResult, nestedRoleMap, colors) : undefined
    }, [taskResult, nestedRoleMap, colors])

    const primaryAxis = useMemo(() => {
        if (!task) {
            return undefined
        }

        const interval: IntervalType = (task?.result?.interval as IntervalType) ?? "DAY"
        const referenceDate = getReferenceDate(dateFromDjango(task?.result?.fromDate), interval)
        return {
            getValue: (datum: RoleHoursDatum) => getXValue(referenceDate, interval, datum.x).toString(),
            formatters: {
                scale: (value: string) => getTimeAxisValue(referenceDate, interval, parseInt(value)),
            },
        }
    }, [task, getTimeAxisValue])

    const secondaryAxes = useMemo<AxisLinearOptions<RoleHoursDatum>[]>(
        () => [
            {
                getValue: (datum) => datum.count / 4,
                elementType: "bar",
                stacked: true,
                min: 0,
                formatters: {
                    scale: (datum) => prettyPrintQuarters(datum * 4),
                },
            },
        ],
        []
    )

    const getSeriesStyle = useCallback((series: Series<RoleHoursDatum>, status: SeriesFocusStatus): SeriesStyles => {
        return {
            color: series.originalSeries.color,
            opacity: status === "focused" ? 1 : 0.7,
        }
    }, [])

    return (
        <div className="w-100 h-100">
            {canShowData(taskResult) ? (
                <Chart
                    options={{
                        data: data!,
                        primaryAxis: primaryAxis!,
                        secondaryAxes,
                        getSeriesStyle,
                    }}
                />
            ) : (
                <NoData taskResult={taskResult} />
            )}
        </div>
    )
}

export default UserHoursGraph
