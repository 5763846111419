import { formDataHeader } from "../helpers/FormHelper"
import { toObject } from "../helpers/MapHelper"
import { ScheduleMap } from "../helpers/ScheduleMapHelper"
import LocalityMutationType from "../types/LocalityMutationType"
import LocalityType, { StaticLocalityType } from "../types/LocalityType"
import LocationType from "../types/LocationType"
import { ScheduleErrorMessageType } from "../types/ScheduleErrorMessageType"
import { SuggestedShiftImportType, UserMappingMode, UserMappingsType } from "../types/ShiftImportType"
import ShiftMutationType from "../types/ShiftMutationType"
import ShiftType, { StaticShiftType } from "../types/ShiftType"
import { ValidationMessageType } from "../types/ValidationMessageType"
import api from "./Api"

export function getScheduleForDate(location: number, date: number) {
    return api.get<{
        shifts: ShiftType[]
        shiftMutations: ShiftMutationType[]
        localities: LocalityType[]
        localityMutations: LocalityMutationType[]
        messages: ValidationMessageType[]
        errorMessage?: ScheduleErrorMessageType
    }>(`/schedule/?location=${location}&date=${date}`)
}

export function getPersonalShiftsAndLocalities(locations: number[], user: number, date: number, days: number) {
    let url = `/schedule/personal/?locations=${locations}&date=${date}&days=${days}`
    if (user) {
        url += `&user=${user}`
    }
    return api.get<{ shifts: StaticShiftType[]; shiftMutations: ShiftMutationType[]; localities: StaticLocalityType[]; localityMutations: LocalityMutationType[] }>(url)
}

export function storeShiftsAndLocalities(newScheduleMutations: ScheduleMap, sendNotifications: boolean, handleAbsenceRequest?: number) {
    return api.post(`/schedule/store/`, {
        newMutations: toObject(newScheduleMutations),
        handleAbsenceRequest: handleAbsenceRequest,
        sendNotifications: sendNotifications,
    })
}

export function uploadShiftImport(location: LocationType, role: string, file: FileList) {
    const formData = new FormData()

    formData.append("location", location.id.toString())
    formData.append("role", role)
    if (file && file.length > 0) {
        formData.append("file", file.item(0)!)
    }

    return api.post<SuggestedShiftImportType>("/schedule/shift-import/", formData, formDataHeader)
}

export function getUserMappings(location: number, userMappings: { email: string; mode: UserMappingMode; user?: number; firstName?: string; lastName?: string }[]) {
    return api.post<UserMappingsType>("/schedule/shift-import/user-mappings/", { location, userMappings })
}
