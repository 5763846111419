import { FC, useCallback, useMemo } from "react"
import { AxisLinearOptions, Chart, Series, SeriesFocusStatus, SeriesStyles, UserSerie } from "react-charts"
import { useTranslation } from "react-i18next"
import { prettyPrintQuarters } from "../../../../helpers/DaysHelper"
import { canShowData } from "../../../../helpers/TaskHelper"
import useTimeAxis from "../../../../hooks/UseTimeAxis"
import { TaskForecastResult, TaskType } from "../../../../services/Task"
import NoData from "../../NoData"

export interface UnexusDatum {
    x: string
    count: number
}

interface ForecastGraph1Props {
    task?: TaskType<TaskForecastResult>
}

const ForecastGraph1: FC<ForecastGraph1Props> = ({ task }) => {
    const { t } = useTranslation()

    const taskResult = useMemo(() => {
        return task?.result
    }, [task])

    const data = useMemo(() => {
        if (!taskResult) {
            return undefined
        }

        const result: UserSerie<UnexusDatum>[] = []
        if (taskResult.roleUids.length > 0) {
            const shortageData = []
            for (const x in taskResult.roleData) {
                shortageData.push({
                    x,
                    count: taskResult.roleData[x][2],
                })
            }
            if (shortageData.length) {
                result.push({
                    label: t("ForecastGraph2.under_occupation"),
                    color: "rgb(220, 53, 69)",
                    data: shortageData,
                })
            }
        }

        return result
    }, [taskResult, t])

    const primaryAxis = useTimeAxis(task)

    const secondaryAxes = useMemo<AxisLinearOptions<UnexusDatum>[]>(
        () => [
            {
                getValue: (datum) => datum.count / 4,
                elementType: "line",
                min: 0,
                max: 10,
                formatters: {
                    scale: (datum) => prettyPrintQuarters(datum * 4),
                },
            },
        ],
        []
    )

    const getSeriesStyle = useCallback((series: Series<UnexusDatum>, status: SeriesFocusStatus): SeriesStyles => {
        return {
            color: series.originalSeries.color,
        }
    }, [])

    return (
        <div className="w-100 h-100">
            {canShowData(taskResult) ? (
                <Chart
                    options={{
                        data: data!,
                        primaryAxis: primaryAxis!,
                        secondaryAxes,
                        getSeriesStyle,
                    }}
                />
            ) : (
                <NoData taskResult={taskResult} />
            )}
        </div>
    )
}

export default ForecastGraph1
