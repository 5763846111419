import { useMemo } from "react"
import { useTranslation } from "react-i18next"

const useHiringAvailabilityStatusFilterOptions = () => {
    const { t } = useTranslation()

    return useMemo(
        () => [
            { key: "AVAILABLE", title: t("HiringAvailabilityStatus.available") },
            { key: "UNAVAILABLE", title: t("HiringAvailabilityStatus.unavailable") },
            { key: "UNKNOWN", title: t("HiringAvailabilityStatus.unknown") },
        ],
        [t]
    )
}

export default useHiringAvailabilityStatusFilterOptions
