import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import { t } from "i18next"
import { useCallback } from "react"
import { AbsenceRequestReviewStatus } from "../types/AbsenceRequestType"
import useAbsenceRequestReviewStatusOptions from "./UseAbsenceRequestReviewStatusOptions"

const useResolveAbsenceRequestReviewStatusIcon = () => {
    const reviewStatusOptions = useAbsenceRequestReviewStatusOptions()

    return useCallback(
        (status: AbsenceRequestReviewStatus): FontAwesomeIconProps | undefined => {
            return reviewStatusOptions.find((o) => o.id === status)?.icon
        },
        [t]
    )
}

export default useResolveAbsenceRequestReviewStatusIcon
