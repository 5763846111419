import { FC, useCallback, useMemo } from "react"
import { Button, Form } from "react-bootstrap"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import InfoPopover from "../../../components/InfoPopover"
import { useSelectedLocation } from "../../../contexts/UserSettingsContext"
import { loadUnexusRouters } from "../../../services/UnexusRouter"
import { Inputs } from "../EditOccupationTemplateDetailsPage"
import UnexusConnectionRowView from "./UnexusConnectionRowView"
import { UnexusRowConnectionDetails } from "./UnexusRowConnectionDetails"

interface Props {
    watch: UseFormWatch<Inputs>
    setValue: UseFormSetValue<Inputs>
}

const getNextRowId = (rows: UnexusRowConnectionDetails[]) => {
    return rows.map((r) => r.id).reduce((a, b) => Math.max(a, b), 0) + 1
}

const UnexusConnectionRowsView: FC<Props> = ({ watch, setValue }) => {
    const { t } = useTranslation()
    const location = useSelectedLocation()
    const unexusConnectionRows = watch("unexusConnectionRows")
    const { data: unexusRouters } = useQuery(["UnexusRoutersForLocation", location.id], loadUnexusRouters(location.id), { initialData: [] })
    const unexusRouterOptions = useMemo(
        () =>
            unexusRouters
                ? unexusRouters.map((r) => {
                      return { id: r.id.toString(), unexusName: r.unexusName }
                  })
                : [],
        [unexusRouters]
    )
    const addRow = useCallback(() => {
        const rows = [...unexusConnectionRows]
        rows.push({
            id: getNextRowId(rows),
            roleUid: "",
            routers: [],
        })
        setValue("unexusConnectionRows", rows)
    }, [unexusConnectionRows, setValue])

    return (
        <Form.Group>
            <Form.Label>{t("UnexusConnectionRowsView.add_unexus_sources")}</Form.Label>
            <InfoPopover id="unexusConnectionInfo" body={t("UnexusConnectionRowsView.add_unexus_sources_description")} />
            {unexusConnectionRows.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <td style={{ minWidth: "150px" }}>
                                <span className="input-label">{t("UnexusConnectionRowsView.role")}</span>
                            </td>
                            <td className="ps-2" style={{ minWidth: "150px" }}>
                                <span className="input-label">{t("UnexusConnectionRowsView.routers")}</span>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {unexusConnectionRows.map((row, i) => (
                            <UnexusConnectionRowView key={row.id} rowIndex={i} watch={watch} setValue={setValue} unexusRouters={unexusRouterOptions} />
                        ))}
                    </tbody>
                </table>
            ) : null}
            <Button type="button" variant="link" onClick={addRow} data-cy="addConnectionRow">
                {t("Buttons.add")}
            </Button>
        </Form.Group>
    )
}

export default UnexusConnectionRowsView
