import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC } from "react"

interface Props {
    checked: boolean
    markFalse: boolean
}
const CheckMarkCell: FC<Props> = ({ checked, markFalse }) => {
    return checked ? (
        <div className="text-success">
            <FontAwesomeIcon icon={faCheck} />
        </div>
    ) : markFalse ? (
        <div className="text-danger">
            <FontAwesomeIcon icon={faXmark} />
        </div>
    ) : null
}

export default CheckMarkCell
