import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import RoleType from "../types/RoleType"

interface RoleOption {
    id: string
    name: string
}

const useRoleOptions = (roles: RoleType[], includeFree: boolean) => {
    const { t } = useTranslation()

    return useMemo(() => {
        const roleOptions: RoleOption[] = roles.map((role) => {
            return {
                id: role.id.toString(),
                name: role.name,
            }
        })
        if (includeFree) {
            roleOptions.unshift({
                id: "0",
                name: t("Main.role_free"),
            })
        }
        return roleOptions
    }, [roles, includeFree, t])
}

export default useRoleOptions
