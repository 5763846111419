import { FC, useCallback, useMemo, useState } from "react"
import { Modal } from "react-bootstrap"
import { ShiftImportType, ShiftToImportType, SuggestedShiftImportType, UserMappingType } from "../../../types/ShiftImportType"
import ShiftImportShifts from "./ShiftImportShifts"
import ShiftImportUpload from "./ShiftImportUpload"
import ShiftImportUsers from "./ShiftImportUsers"

interface Props {
    visible: boolean
    close: () => void
}

const ShiftImportModal: FC<Props> = ({ visible, close }) => {
    const [suggestedShiftImport, setSuggestedShiftImport] = useState<SuggestedShiftImportType | null>(null)
    const [userMappings, setUserMappings] = useState<UserMappingType[] | null>(null)

    const shiftImport: ShiftImportType | null = useMemo(() => {
        if (!suggestedShiftImport || !userMappings) {
            return null
        }
        let shifts: ShiftToImportType[] = []
        for (const shift of suggestedShiftImport.suggestedShifts) {
            const userMapping = userMappings.find((mapping) => mapping.email === shift.email)
            if (userMapping) {
                shifts.push({
                    user: userMapping.user,
                    date: shift.date,
                    startSlot: shift.startSlot,
                    endSlot: shift.endSlot,
                })
            }
        }
        return {
            location: suggestedShiftImport.location,
            role: suggestedShiftImport.role,
            shifts,
        }
    }, [suggestedShiftImport, userMappings])

    const goBackFromUsers = useCallback(() => {
        setSuggestedShiftImport(null)
    }, [setSuggestedShiftImport])

    const goBackFromShifts = useCallback(() => {
        setUserMappings(null)
    }, [setUserMappings])

    const onHide = useCallback(() => {
        setSuggestedShiftImport(null)
        setUserMappings(null)
        close()
    }, [setSuggestedShiftImport, setUserMappings, close])

    return (
        <Modal show={visible} onHide={onHide}>
            {!suggestedShiftImport ? (
                <ShiftImportUpload setShiftImport={setSuggestedShiftImport} onHide={onHide} />
            ) : !userMappings ? (
                <ShiftImportUsers suggestedUserMappings={suggestedShiftImport.suggestedUserMappings} setUserMappings={setUserMappings} goBack={goBackFromUsers} onHide={onHide} />
            ) : shiftImport ? (
                <ShiftImportShifts shiftImport={shiftImport} goBack={goBackFromShifts} onHide={onHide} />
            ) : null}
        </Modal>
    )
}

export default ShiftImportModal
