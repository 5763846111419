import { FC, ReactNode } from "react"
import { Breadcrumb, Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import styles from "./RegularPage.module.scss"

export interface BreadcrumbType {
    title: string
    href?: string
}

interface RegularPageProps {
    id?: string
    children?: ReactNode
    breadcrumbs?: BreadcrumbType[]
}

const RegularPage: FC<RegularPageProps> = ({ id, children, breadcrumbs }) => {
    return (
        <Card id={id} className="w-100">
            <Card.Body>
                {breadcrumbs ? (
                    <Breadcrumb>
                        {breadcrumbs.map(({ title, href }) => (
                            <Breadcrumb.Item key={`${title}-${href}`} active={!href} linkAs={Link} linkProps={{ to: href }}>
                                {title}
                            </Breadcrumb.Item>
                        ))}
                    </Breadcrumb>
                ) : null}
                <div className={styles.body}>{children}</div>
            </Card.Body>
        </Card>
    )
}

export default RegularPage
