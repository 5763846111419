import AfasLeaveTypeType from "../types/AfasLeaveTypeType"
import api from "./Api"

export function loadAfasLeaveTypes(afasConnection: number): Promise<AfasLeaveTypeType[]> {
    return new Promise<AfasLeaveTypeType[]>((resolve, reject) =>
        getAfasLeaveTypes(afasConnection)
            .then((response) => resolve(response.data.items))
            .catch(reject)
    )
}

export function getAfasLeaveTypes(afasConnection: number) {
    return api.get<{ items: AfasLeaveTypeType[] }>(`/afas-leave-types/?connection=${afasConnection}`)
}
