import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import SmallCardPage from "../components/page/SmallCardPage"

const SamlDeniedPage = () => {
    const { t } = useTranslation()

    return (
        <SmallCardPage>
            <h2>{t("SamlDeniedPage.access_denied")}</h2>
            <div>
                <Trans i18nKey="SamlDeniedPage.contact_via_email">
                    Please contact us at <Link to="mailto:info@llmsolutions.nl">info@llmsolutions.nl</Link> if the problem persists.
                </Trans>
            </div>
        </SmallCardPage>
    )
}

export default SamlDeniedPage
