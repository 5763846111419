import { FC, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import DateSelector, { DateLabelType } from "../../../components/DateSelector"
import InfoPopover from "../../../components/InfoPopover"
import DaySelectorInline from "../../../components/editor/DaySelectorInline"
import RegularPage from "../../../components/page/RegularPage"
import { useTabs } from "../../../contexts/TabsContext"
import { useSelectedLocation } from "../../../contexts/UserSettingsContext"
import useLoadOccupationSource from "../../../hooks/UseLoadOccupationSource"
import useLoadUnexusRoutersForSource from "../../../hooks/UseLoadUnexsusRoutersForSource"
import { useLoadDateUnexusRouterOccupations, useLoadDayUnexusRouterOccupations } from "../../../hooks/UseLoadUnexusRouterOccupations"
import useResolveRoleForSelectedLocation from "../../../hooks/UseResolveRole"
import useResolveRoleGroupForSelectedLocation from "../../../hooks/UseResolveRoleGroup"
import { loadOccupationTemplate } from "../../../services/OccupationTemplate"
import OccupationSourceType from "../../../types/OccupationSourceType"
import { SourcedDayOccupationType } from "../../../types/OccupationType"
import UnexusRouterOccupationType from "../../../types/UnexusRouterOccupationType"
import UnexusRouterType from "../../../types/UnexusRouterType"
import UnexusRouterOccupationsView from "./UnexusRouterOccupationsView"

const getRouterNames = (routerIds: number[], routers: UnexusRouterType[]) => {
    if (routers.length < 1) {
        return []
    }
    return routerIds.map((id) => routers.find((r) => r.id === id)?.unexusName).join(", ")
}

const OccupationSourcePage: FC = ({}) => {
    const { t } = useTranslation()
    const { setActiveTab } = useTabs()
    const params = useParams()
    const location = useSelectedLocation()
    const resolveRoleForSelectedLocation = useResolveRoleForSelectedLocation()
    const resolveRoleGroupForSelectedLocation = useResolveRoleGroupForSelectedLocation()
    const template = parseInt(params.template!)
    const id = parseInt(params.id!)
    const navigate = useNavigate()

    const [occupationSource, setOccupationSource] = useState<OccupationSourceType>()
    const [selectedDate, setSelectedDate] = useState<Date>(new Date())
    const [dateUnexusRouterOccupations, setDateUnexusRouterOccupations] = useState<UnexusRouterOccupationType[]>([])
    const [dateUnexusOccupations, setDateUnexusOccupations] = useState<SourcedDayOccupationType[]>([])
    const [dateReferenceDates, setDateReferenceDates] = useState<Date[]>([])
    const [dayUnexusRouterOccupations, setDayUnexusRouterOccupations] = useState<UnexusRouterOccupationType[]>([])
    const [dayUnexusOccupations, setDayUnexusOccupations] = useState<SourcedDayOccupationType[]>([])
    const [dayReferenceDates, setDayReferenceDates] = useState<Date[]>([])
    const [unexusRouters, setUnexusRouters] = useState<UnexusRouterType[]>([])
    const [selectedDay, setSelectedDay] = useState<number>(0)

    const { data: occupationTemplate } = useQuery(["OccupationTemplate", template], loadOccupationTemplate(template))
    const loadOccupationSource = useLoadOccupationSource(id, setOccupationSource)
    const loadDateUnexusRouterOccupations = useLoadDateUnexusRouterOccupations(occupationSource, selectedDate, setDateUnexusRouterOccupations, setDateUnexusOccupations, setDateReferenceDates)
    const loadDayUnexusRouterOccupations = useLoadDayUnexusRouterOccupations(occupationSource, selectedDay, setDayUnexusRouterOccupations, setDayUnexusOccupations, setDayReferenceDates)
    const loadUnexusRouters = useLoadUnexusRoutersForSource(occupationSource, setUnexusRouters)

    useEffect(() => setActiveTab("OccupationTemplates"), [setActiveTab])
    useEffect(loadOccupationSource, [id])
    useEffect(loadDateUnexusRouterOccupations, [occupationSource, selectedDate])
    useEffect(loadDayUnexusRouterOccupations, [occupationSource, selectedDay])
    useEffect(loadUnexusRouters, [occupationSource])
    useEffect(() => {
        if (occupationTemplate && occupationTemplate.location !== location.id) {
            navigate("/basisbezettingen")
        }
    }, [occupationTemplate])

    const target = useMemo(() => {
        if (occupationSource?.role) {
            return resolveRoleForSelectedLocation(occupationSource.role)?.name
        } else if (occupationSource?.roleGroup) {
            return resolveRoleGroupForSelectedLocation(occupationSource.roleGroup)?.name
        }
    }, [occupationSource, resolveRoleForSelectedLocation, resolveRoleGroupForSelectedLocation])

    return (
        <RegularPage
            id="OccupationSource"
            breadcrumbs={[
                { title: t("OccupationSourcePage.occupation_templates"), href: "/basisbezettingen" },
                { title: occupationTemplate ? occupationTemplate.name : "", href: occupationTemplate ? `/basisbezettingen/${occupationTemplate.id}` : "" },
                { title: t("OccupationSourcePage.occupation_source", { role: target }) },
            ]}
        >
            <h2>{t("OccupationSourcePage.occupation_source", { role: target })}</h2>
            <div className="row">
                <div className="form-group mb-4 col-md-5 col-lg-4">
                    <label className="input-label">{t("OccupationSourcePage.handling_time")}</label>
                    <span className="text-bold">{t("Main.seconds", { count: occupationSource?.handlingTime || 0 })}</span>
                </div>
                <div className="form-group mb-4 col-md-5 col-lg-4">
                    <label className="input-label">{t("OccupationSourcePage.speed_of_answer")}</label>
                    <span className="text-bold">{t("Main.seconds", { count: occupationSource?.speedOfAnswer || 0 })}</span>
                </div>
            </div>
            <div className="row">
                <div className="form-group mb-4 col-md-5 col-lg-4">
                    <label className="input-label">{t("OccupationSourcePage.service_level")}</label>
                    <span className="text-bold">{occupationSource ? occupationSource.serviceLevel : ""}</span>
                </div>
                <div className="form-group mb-4 col-md-5 col-lg-4">
                    <label className="input-label">{t("OccupationSourcePage.max_occupancy")}</label>
                    <span className="text-bold">{occupationSource ? occupationSource.maxOccupancy : ""}</span>
                </div>
            </div>
            <div className="form-group mb-4">
                <label className="input-label">{t("OccupationSourcePage.routers")}</label>
                <span className="text-bold">{occupationSource ? getRouterNames(occupationSource.routers, unexusRouters) : ""}</span>
            </div>
            <div className="form-section mb-4">
                <div className="form-group mb-4">
                    <label className="input-label d-inline-block me-2">{t("OccupationSourcePage.show_calculation_for_date")}</label>
                    <DateSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} labelType={DateLabelType.DATE_AND_YEAR} />
                    <InfoPopover id="datePopover" body={t("OccupationSourcePage.show_calculation_for_date_description")} />
                </div>
                <UnexusRouterOccupationsView referenceDates={dateReferenceDates} occupations={dateUnexusOccupations} routerOccupations={dateUnexusRouterOccupations} />
            </div>
            <div className="form-section">
                <div className="form-group mb-4">
                    <label className="input-label d-inline-block me-2">{t("OccupationSourcePage.show_calculation_for_day")}</label>
                    <DaySelectorInline selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
                    <InfoPopover id="dayPopover" body={t("OccupationSourcePage.show_calculation_for_day_description")} />
                </div>
                <UnexusRouterOccupationsView referenceDates={dayReferenceDates} occupations={dayUnexusOccupations} routerOccupations={dayUnexusRouterOccupations} />
            </div>
        </RegularPage>
    )
}

export default OccupationSourcePage
