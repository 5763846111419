import { faChevronLeft, faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AxiosResponse } from "axios"
import { FC, useCallback, useContext, useEffect, useMemo } from "react"
import { Badge, Button, Form, Modal } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { UserSettingsContext, useSelectedLocation } from "../../../contexts/UserSettingsContext"
import { getUserMappings } from "../../../services/Schedule"
import { SuggestedUserMappingType, UserMappingMode, UserMappingType, UserMappingsType } from "../../../types/ShiftImportType"
import ShiftImportUserMapping from "./ShiftImportUserMapping"
import styles from "./ShiftImportUsers.module.scss"

interface Props {
    suggestedUserMappings: SuggestedUserMappingType[]
    setUserMappings: (userMappings: UserMappingType[]) => void
    goBack: () => void
    onHide: () => void
}

interface UserMappingInput {
    email: string
    suggestedUser: string
    selectedUser: string
    suggestedFirstName: string
    suggestedLastName: string
}

export interface ShiftImportUsersInput {
    userMappings: UserMappingInput[]
}

const ShiftImportUsers: FC<Props> = ({ suggestedUserMappings, setUserMappings, goBack, onHide }) => {
    const { t } = useTranslation()
    const location = useSelectedLocation()
    const { reloadUserSettings } = useContext(UserSettingsContext)

    const { register, handleSubmit, setValue, watch } = useForm<ShiftImportUsersInput>({
        defaultValues: {
            userMappings: [],
        },
    })

    useEffect(() => {
        suggestedUserMappings.forEach(({ email, suggestedUser, suggestedFirstName, suggestedLastName }, index) => {
            setValue(`userMappings.${index}`, {
                email,
                suggestedUser: suggestedUser !== null ? suggestedUser.toString() : "new",
                selectedUser: suggestedUser !== null ? suggestedUser.toString() : "new",
                suggestedFirstName,
                suggestedLastName,
            })
        })
    }, [suggestedUserMappings, setValue])

    const onSuccess = useCallback(
        (response: AxiosResponse<UserMappingsType>) => {
            reloadUserSettings()
            setUserMappings(response.data.userMappings)
        },
        [setUserMappings]
    )

    const onSubmit: SubmitHandler<ShiftImportUsersInput> = useCallback(
        ({ userMappings }) => {
            getUserMappings(
                location.id,
                userMappings.map(({ email, selectedUser, suggestedFirstName, suggestedLastName }) => {
                    const mode: UserMappingMode = selectedUser === "new" ? "new" : selectedUser === "ignore" ? "ignore" : "user"
                    return {
                        email,
                        mode,
                        user: mode === "user" ? parseInt(selectedUser) : undefined,
                        firstName: mode === "new" ? suggestedFirstName : undefined,
                        lastName: mode === "new" ? suggestedLastName : undefined,
                    }
                })
            ).then(onSuccess)
        },
        [location, setUserMappings]
    )

    const input = watch()

    const newUsersCount = useMemo(() => input.userMappings.filter((u) => u.selectedUser === "new").length, [input])

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header className="justify-content-between">
                <Modal.Title>
                    <Button type="button" variant="link" onClick={goBack} size="sm">
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                    Selecteer medewerkers ({input.userMappings.length})
                </Modal.Title>
                <Button type="button" variant="link" onClick={onHide}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body className={styles.scrollContainer}>
                <table>
                    <thead>
                        <tr>
                            <td className="p-1 text-bold fs-small">{t("ShiftImport.Email_in_import")}</td>
                            <td className="p-1 text-bold fs-small">{t("ShiftImport.User_in_Rooster_Planner")}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {suggestedUserMappings.map((userMapping, index) => (
                            <ShiftImportUserMapping key={userMapping.email} register={register} index={index} watch={watch} />
                        ))}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" data-cy="submit" className="me-2">
                    {t("Buttons.next")}
                </Button>
                {newUsersCount > 0 ? (
                    <Badge bg="info-light" text="dark">
                        <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                        {t("ShiftImport.Creates_new_user", { count: newUsersCount })}
                    </Badge>
                ) : null}
            </Modal.Footer>
        </Form>
    )
}

export default ShiftImportUsers
