import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import FormErrorMessages from "../../components/form/FormErrorMessages"
import RegularPage from "../../components/page/RegularPage"
import RoleBadge from "../../components/RoleBadge"
import { useTabs } from "../../contexts/TabsContext"
import { useIsAdmin, useRolesForSelectedLocation, useUsersForSelectedLocation } from "../../contexts/UserSettingsContext"
import { dateFromDjango, dateTimeFromDjango, getTimeString, isoPrint, prettyPrintDateTime } from "../../helpers/DaysHelper"
import useResolveUserName from "../../hooks/UseResolveUserName"
import { loadShiftMutation, removeShiftMutation } from "../../services/ShiftMutation"

const EditMutationPage: FC = () => {
    const { t } = useTranslation()
    const users = useUsersForSelectedLocation()
    const roles = useRolesForSelectedLocation()
    const { setActiveTab } = useTabs()
    const params = useParams()
    const id = useMemo(() => parseInt(params.id!), [params])
    const navigate = useNavigate()
    const isAdmin = useIsAdmin()

    const { data: mutation } = useQuery(["Mutation", id], loadShiftMutation(id))
    const [formErrors, setFormErrors] = useState<string[]>([])
    const [deletionWarningVisible, setDeletionWarningsVisible] = useState(false)
    const resolveUserName = useResolveUserName(users)

    const onSuccess = useCallback(() => navigate("/wijzigingen"), [navigate])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setFormErrors(data.nonFieldErrors || [t("Main.something_went_wrong")])
        },
        [setFormErrors, t]
    )

    const deleteMutation = useCallback(() => {
        if (!deletionWarningVisible) {
            setDeletionWarningsVisible(true)
            return
        }
        removeShiftMutation(id).then(onSuccess).catch(onFailure)
    }, [deletionWarningVisible, setDeletionWarningsVisible, id, onSuccess, onFailure])

    useEffect(() => setActiveTab("Mutations"), [setActiveTab])

    const userName = useMemo(() => (mutation ? resolveUserName(mutation.user) : ""), [mutation])
    const date = useMemo(() => (mutation ? isoPrint(dateFromDjango(mutation.date)) : ""), [mutation])
    const startTime = useMemo(() => (mutation ? getTimeString("start", mutation.startSlot) : ""), [mutation])
    const endTime = useMemo(() => (mutation ? getTimeString("end", mutation.endSlot) : ""), [mutation])
    const originalRole = useMemo(() => (mutation?.originalRole ? roles.find((r) => r.id === mutation.originalRole) : null), [mutation])
    const newRole = useMemo(() => (mutation?.role ? roles.find((r) => r.id === mutation.role) : null), [mutation])
    const createdBy = useMemo(() => (mutation?.createdBy ? resolveUserName(mutation.createdBy) : null), [mutation])
    const createdAt = useMemo(() => (mutation ? prettyPrintDateTime(dateTimeFromDjango(mutation.createdAt)) : null), [mutation])
    const notifiedAt = useMemo(() => (mutation?.notifiedAt ? prettyPrintDateTime(dateTimeFromDjango(mutation.notifiedAt)) : null), [mutation])

    return (
        <RegularPage id="EditMutation" breadcrumbs={[{ title: t("MutationsPage.title"), href: "/wijzigingen" }, { title: id.toString() }]}>
            <h2>{userName}</h2>
            <div className="form-group">
                <label className="input-label">{t("EditMutationPage.moment")}</label>
                <span className="text-bold">{t("EditMutationPage.moment_detailed", { date, startTime, endTime })}</span>
            </div>
            <div className="form-group">
                <label className="input-label">{t("EditMutationPage.original_role")}</label>
                <RoleBadge role={originalRole} />
            </div>
            <div className="form-group">
                <label className="input-label">{t("EditMutationPage.new_role")}</label>
                <RoleBadge role={newRole} />
            </div>
            <div className="text-small text-muted">
                <span className="me-1">{t("EditMutationPage.submitted_by", { user: createdBy, at: createdAt })}</span>
                {notifiedAt ? t("EditMutationPage.notified_at", { at: notifiedAt }) : null}
            </div>
            {isAdmin ? (
                <div>
                    <Button type="button" onClick={deleteMutation} variant="danger" data-cy="deleteMutation">
                        {t("Buttons.delete")}
                    </Button>
                </div>
            ) : null}
            {deletionWarningVisible ? (
                <span className="ms-3">
                    <FontAwesomeIcon icon={faExclamationCircle} className="me-1" />
                    {t("EditMutationPage.are_you_sure_delete")}
                    <Button variant="link" type="button" onClick={deleteMutation} data-cy="confirmDeleteMutation">
                        {t("Buttons.yes_delete")}
                    </Button>
                </span>
            ) : null}
            <FormErrorMessages errors={formErrors} />
        </RegularPage>
    )
}

export default EditMutationPage
