import { useMemo } from "react"
import { dateFromDjango } from "../helpers/DaysHelper"
import { getReferenceDate, getXValue } from "../helpers/TaskHelper"
import { IntervalType, TaskForecastResult, TaskRoleTimeResult, TaskType, TaskUserTimeResult } from "../services/Task"
import useGetTimeAxisValue from "./UseGetTimeAxisValue"

const useTimeAxis = (task?: TaskType<TaskRoleTimeResult | TaskUserTimeResult | TaskForecastResult>) => {
    const getTimeAxisValue = useGetTimeAxisValue()

    return useMemo(() => {
        if (!task) {
            return undefined
        }

        const interval: IntervalType = (task?.result?.interval as IntervalType) ?? "DAY"
        const referenceDate = getReferenceDate(dateFromDjango(task?.result?.fromDate), interval)
        return {
            getValue: (datum: { x: string }) => getXValue(referenceDate, interval, datum.x),
            formatters: {
                scale: (value: number) => getTimeAxisValue(referenceDate, interval, value),
            },
        }
    }, [task, getTimeAxisValue])
}

export default useTimeAxis
