import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMemo } from "react"
import { Link } from "react-router-dom"
import { useOccupationEditorState } from "../../contexts/OccupationEditorContext"

const OccupationsEditorHead = () => {
    const editorState = useOccupationEditorState()

    const { id, name } = useMemo(() => {
        return {
            id: editorState.occupationTemplate?.id ?? 0,
            name: editorState.occupationTemplate?.name ?? "",
        }
    }, [editorState.occupationTemplate])

    return (
        <h2 className="mb-3 d-inline-block">
            {name}
            <span className="ms-3 title-button">
                <Link to={`/basisbezettingen/${id}/details`} data-cy="toDetails">
                    <FontAwesomeIcon icon={faEdit} />
                </Link>
            </span>
        </h2>
    )
}

export default OccupationsEditorHead
