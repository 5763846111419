import { FC, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import RegularPage from "../../components/page/RegularPage"
import { useTabs } from "../../contexts/TabsContext"
import { useContinueTour } from "../../contexts/TourContext"
import { STEP_INDEX_STATISTICS } from "../../contexts/TourSteps"
import styles from "./StatisticsPage.module.scss"
import StatisticsTabs from "./StatisticsTabs"
import Forecast from "./section/forecast/Forecast"
import RoleHours from "./section/rolehours/RoleHours"
import UserHours from "./section/userhours/UserHours"

const StatisticsPage: FC = () => {
    const { t } = useTranslation()
    const { setActiveTab } = useTabs()
    const continueTour = useContinueTour()
    const tabs = useMemo(() => {
        return [
            {
                id: "roles",
                name: t("StatisticsPage.roles"),
            },
            {
                id: "users",
                name: t("StatisticsPage.users"),
            },
            {
                id: "forecast",
                name: t("StatisticsPage.forecast"),
            },
        ]
    }, [t])
    const [activeStatisticsTabId, setActiveStatisticsTabId] = useState<string>(tabs[0].id)

    useEffect(() => continueTour(STEP_INDEX_STATISTICS), [continueTour])
    useEffect(() => setActiveTab("Statistics"), [setActiveTab])

    return (
        <RegularPage id="Statistics">
            <h2>{t("StatisticsPage.title")}</h2>
            <div className={styles.tabBar}>
                <StatisticsTabs tabs={tabs} activeTabId={activeStatisticsTabId} setActiveTabId={setActiveStatisticsTabId} />
            </div>
            <RoleHours show={activeStatisticsTabId === "roles"} />
            <UserHours show={activeStatisticsTabId === "users"} />
            <Forecast show={activeStatisticsTabId === "forecast"} />
        </RegularPage>
    )
}

export default StatisticsPage
