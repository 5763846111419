import { FilterSelectOption } from "../components/table/TableFilters"
import { getUsers } from "../services/User"
import CompanyType from "../types/CompanyType"
import LocationType from "../types/LocationType"
import RoleType from "../types/RoleType"
import { EditUserAvailabilityType, RolePreferenceType, UserType } from "../types/UserType"
import { dateFromDjango, getTimeString, isoPrint } from "./DaysHelper"

export function getUserOptions(users: { id: number; firstName: string; lastName: string }[]) {
    if (!users) {
        return []
    }
    return users.map((user) => {
        return {
            id: user.id.toString(),
            name: user.firstName + " " + user.lastName,
        }
    })
}

export function getUserFilterOptions(users: UserType[]): FilterSelectOption[] {
    if (!users) {
        return []
    }
    return users.map((user) => {
        return {
            key: user.id.toString(),
            title: user.firstName + " " + user.lastName,
        }
    })
}

export function loadUsersForPeriod(location: LocationType, dateFrom: Date, dateTo: Date | null): Promise<UserType[]> {
    return new Promise((resolve, reject) => {
        getUsers({
            pageIndex: 0,
            pageSize: 999,
            filters: [
                { key: "employableperiodfrom", value: isoPrint(dateFrom) },
                { key: "employableperiodto", value: dateTo ? isoPrint(dateTo) : "" },
                { key: "location", value: location.id.toString() },
            ],
        })
            .then((response) => {
                resolve(response.data.items)
            })
            .catch(reject)
    })
}

export const initAvailabilities = (currentCompany: CompanyType, weekCycle: number) => {
    const availabilities: EditUserAvailabilityType[] = []
    for (let i = 0; i < weekCycle * 7; i++) {
        availabilities.push({
            startTime: getTimeString("start", currentCompany.defaultStartSlotUser),
            endTime: getTimeString("end", currentCompany.defaultEndSlotUser),
            day: i,
        })
    }
    return availabilities
}

export const initRolePreferences = (roles: RoleType[]): RolePreferenceType[] => {
    return roles
        ? roles
              .filter((r) => r.isDefaultPreference)
              .map((r) => {
                  return { role: r.id, priority: 1 }
              })
        : []
}

export const isEmployableInPeriod = (user: UserType, dateFrom: Date, dateTo: Date | null) => {
    if (user.employableFrom && dateTo !== null && dateFromDjango(user.employableFrom) > dateTo) {
        return false
    }
    if (user.employableTo && dateFromDjango(user.employableTo) < dateFrom) {
        return false
    }
    return true
}

export const isEmployableNow = (user: UserType) => {
    const dateFrom = new Date()
    dateFrom.setUTCHours(0, 0, 0, 0)
    const dateTo = new Date()
    dateTo.setUTCHours(23, 59, 59, 999)
    return isEmployableInPeriod(user, dateFrom, dateTo)
}

export const isExternal = (user: UserType) => {
    return user.type === "EXTERNAL"
}

export const getNonExternalEmployableUsers = (users: UserType[], dateFrom: Date, dateTo: Date | null) => {
    return users.filter((u) => u.type !== "EXTERNAL").filter((u) => isEmployableInPeriod(u, dateFrom, dateTo))
}
