import { FC } from "react"
import { Form } from "react-bootstrap"
import { UseFormRegister, UseFormWatch } from "react-hook-form"
import { useExternalUsersForSelectedLocation } from "../../../contexts/UserSettingsContext"
import { ShiftImportUsersInput } from "./ShiftImportUsers"

interface Props {
    index: number
    register: UseFormRegister<ShiftImportUsersInput>
    watch: UseFormWatch<ShiftImportUsersInput>
}

const ShiftImportUserMapping: FC<Props> = ({ index, register, watch }) => {
    const users = useExternalUsersForSelectedLocation()

    const email = watch(`userMappings.${index}.email`)

    return (
        <tr>
            <td className="p-1 fs-small">{email}</td>
            <td className="p-1">
                <Form.Select {...register(`userMappings.${index}.selectedUser`)}>
                    <option value="new">Maak nieuw...</option>
                    <option value="ignore">Sla over...</option>
                    {users.map(({ id, firstName, lastName }) => (
                        <option key={id.toString()} value={id.toString()}>
                            {firstName} {lastName}
                        </option>
                    ))}
                </Form.Select>
            </td>
        </tr>
    )
}

export default ShiftImportUserMapping
