import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AxiosResponse } from "axios"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap"
import DatePicker from "react-datepicker"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import InlineFormMultiSelect from "../../components/form/InlineFormMultiSelect"
import InfoPopover from "../../components/InfoPopover"
import RegularPage, { BreadcrumbType } from "../../components/page/RegularPage"
import DatePickerHeader from "../../components/table/DatePickerHeader"
import { useTabs } from "../../contexts/TabsContext"
import { useRolesForSelectedLocation, useSelectedLocation, useUsersForSelectedLocation } from "../../contexts/UserSettingsContext"
import { ROLE_TYPE_REGULAR } from "../../helpers/Constants"
import { dateFromDjango, dateToDjango } from "../../helpers/DaysHelper"
import { setOptionalError } from "../../helpers/FormHelper"
import { getRoleOptionsForType } from "../../helpers/RolesHelper"
import { getNonExternalEmployableUsers, getUserOptions } from "../../helpers/UsersHelper"
import { loadOccupationTemplatesForLocation } from "../../services/OccupationTemplate"
import { CreatedShiftTemplateResponse, createShiftTemplate, deleteShiftTemplate, loadShiftTemplatesForLocation, updateShiftTemplate } from "../../services/ShiftTemplate"
import ShiftTemplateType, { ShiftTemplateInitMode } from "../../types/ShiftTemplateType"

interface Props {
    mode: "Create" | "Update"
    shiftTemplate?: ShiftTemplateType
}

interface Inputs {
    name: string
    validFrom: Date | null
    validTo: Date | null
    initializationMode: ShiftTemplateInitMode
    sourceTemplate: string
    occupationTemplate: string
    weekCycle: number
    isPublished: boolean
    users: string[]
    roles: string[]
}

const EditShiftTemplateDetailsPage: FC<Props> = ({ mode, shiftTemplate }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useSelectedLocation()
    const allUsers = useUsersForSelectedLocation()
    const allRoles = useRolesForSelectedLocation()
    const { setActiveTab } = useTabs()
    const {
        register,
        setError,
        setValue,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm<Inputs>({
        defaultValues: {
            validFrom: new Date(),
            validTo: null,
            users: getNonExternalEmployableUsers(allUsers, new Date(), null).map((u) => u.id.toString()),
            roles: getRoleOptionsForType(allRoles, ROLE_TYPE_REGULAR).map((r) => r.id.toString()),
        },
    })

    const [deletionWarningVisible, setDeletionWarningVisible] = useState(false)

    const { data: shiftTemplates } = useQuery(["ShiftTemplatesForLocation", location.id], loadShiftTemplatesForLocation(location.id), { enabled: mode === "Create", initialData: [] })
    const { data: occupationTemplates } = useQuery(["OccupationTemplatesForLocation", location.id], loadOccupationTemplatesForLocation(location.id), { initialData: [] })

    const initializationModeOptions = useMemo(() => {
        return [
            { id: ShiftTemplateInitMode.EMPTY, name: t("EditShiftTemplateDetailsPage.mode_empty") },
            { id: ShiftTemplateInitMode.GENERATE, name: t("EditShiftTemplateDetailsPage.mode_generate") },
            { id: ShiftTemplateInitMode.COPY, name: t("EditShiftTemplateDetailsPage.mode_copy") },
        ]
    }, [t])

    useEffect(() => {
        if (shiftTemplate && shiftTemplate.location !== location.id) {
            navigate("/basisroosters")
            return
        }
        setValue("name", shiftTemplate ? shiftTemplate.name : "")
        const validFrom = shiftTemplate ? dateFromDjango(shiftTemplate?.validFrom) : new Date()
        setValue("validFrom", validFrom)
        const validTo = shiftTemplate?.validTo ? dateFromDjango(shiftTemplate?.validTo) : null
        setValue("validTo", validTo)
        setValue("initializationMode", ShiftTemplateInitMode.EMPTY)
        setValue("sourceTemplate", "")
        setValue("occupationTemplate", shiftTemplate?.occupationTemplate?.toString() || "")
        setValue("weekCycle", shiftTemplate ? shiftTemplate.weekCycle : 1)
        setValue("isPublished", shiftTemplate ? shiftTemplate.isPublished : false)

        if (validFrom) {
            setValue(
                "users",
                getNonExternalEmployableUsers(allUsers, validFrom, validTo).map((u) => u.id.toString())
            )
        }
    }, [shiftTemplate])

    const onSuccessCreate = useCallback(
        (response: AxiosResponse<CreatedShiftTemplateResponse>) => {
            const newId = response.data.template.id
            const task = response.data.task
            if (task) {
                navigate(`/basisroosters/${newId}/genereer/${task.id}`)
            } else {
                navigate(`/basisroosters/${newId}`)
            }
        },
        [navigate]
    )

    const onSuccessUpdate = useCallback(() => {
        navigate(`/basisroosters/${shiftTemplate!.id}`)
    }, [navigate, shiftTemplate])

    const onSuccessDelete = useCallback(() => {
        navigate("/basisroosters/")
    }, [navigate])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "name", data.name)
            setOptionalError(setError, "validFrom", data.validFrom)
            setOptionalError(setError, "validTo", data.validTo)
            setOptionalError(setError, "initializationMode", data.initializationMode)
            setOptionalError(setError, "sourceTemplate", data.sourceTemplate)
            setOptionalError(setError, "occupationTemplate", data.occupationTemplate)
            setOptionalError(setError, "weekCycle", data.weekCycle)
            setOptionalError(setError, "isPublished", data.isPublished)
            setOptionalError(setError, "root", data.nonFieldErrors)
        },
        [setError]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ name, validFrom, validTo, initializationMode, sourceTemplate, occupationTemplate, weekCycle, users, roles, isPublished }) => {
            if (mode === "Create") {
                createShiftTemplate({
                    name,
                    validFrom: validFrom ? dateToDjango(validFrom) : null,
                    validTo: validTo ? dateToDjango(validTo) : null,
                    initializationMode: initializationMode,
                    location: location.id,
                    sourceTemplate: sourceTemplate ? parseInt(sourceTemplate) : null,
                    occupationTemplate: occupationTemplate ? parseInt(occupationTemplate) : null,
                    weekCycle,
                    users: users.map((u) => parseInt(u)),
                    roles: roles.map((r) => parseInt(r)),
                })
                    .then(onSuccessCreate)
                    .catch(onFailure)
            } else if (mode === "Update") {
                updateShiftTemplate({
                    ...shiftTemplate!,
                    name,
                    validFrom: validFrom ? dateToDjango(validFrom) : null,
                    validTo: validTo ? dateToDjango(validTo) : null,
                    occupationTemplate: occupationTemplate ? parseInt(occupationTemplate) : null,
                    weekCycle,
                    isPublished,
                })
                    .then(onSuccessUpdate)
                    .catch(onFailure)
            }
        },
        [location, onSuccessCreate, onSuccessUpdate, onFailure]
    )

    const onDeleteShiftTemplate = useCallback(() => {
        if (!deletionWarningVisible) {
            setDeletionWarningVisible(true)
            return
        }

        deleteShiftTemplate(shiftTemplate!.id).then(onSuccessDelete).catch(onFailure)
    }, [deletionWarningVisible, setDeletionWarningVisible, shiftTemplate, onSuccessDelete, onFailure])

    useEffect(() => setActiveTab("ShiftTemplates"), [setActiveTab])

    const pageTitle = useMemo(() => (mode === "Update" ? shiftTemplate?.name : t("EditShiftTemplateDetailsPage.new_shift_template")), [mode, shiftTemplate])

    const breadcrumbs: BreadcrumbType[] = useMemo(() => {
        const breadcrumbs: BreadcrumbType[] = []
        breadcrumbs.push({ title: t("EditShiftTemplateDetailsPage.shift_templates"), href: "/basisroosters" })
        if (mode === "Create") {
            breadcrumbs.push({ title: t("Main.new") })
        } else if (mode === "Update") {
            breadcrumbs.push({ title: shiftTemplate?.name ?? "", href: `/basisroosters/${shiftTemplate?.id}` })
            breadcrumbs.push({ title: t("Main.details") })
        }
        return breadcrumbs
    }, [t, mode, shiftTemplate])

    const validFrom = watch("validFrom")
    const validFromIsError = useMemo(() => !!errors.validFrom, [errors])

    const validTo = watch("validTo")
    const validToIsError = useMemo(() => !!errors.validTo, [errors])

    const setValidFrom = useCallback(
        (newValue: Date | null) => {
            setValue("validFrom", newValue)
            if (newValue) {
                setValue(
                    "users",
                    getNonExternalEmployableUsers(allUsers, newValue, validTo).map((u) => u.id.toString())
                )
            }
        },
        [setValue]
    )

    const setValidTo = useCallback(
        (newValue: Date | null) => {
            setValue("validTo", newValue)
            if (validFrom) {
                setValue(
                    "users",
                    getNonExternalEmployableUsers(allUsers, validFrom, newValue).map((u) => u.id.toString())
                )
            }
        },
        [setValue]
    )

    const initializationMode = watch("initializationMode")

    const users = watch("users")
    const setUsers = useCallback((newValue: string[]) => setValue("users", newValue), [setValue])
    const userOptions = useMemo(() => {
        return getUserOptions(getNonExternalEmployableUsers(allUsers, validFrom ?? new Date(), validTo))
    }, [allUsers, validFrom, validTo])

    const roles = watch("roles")
    const setRoles = useCallback((newValue: string[]) => setValue("roles", newValue), [setValue])
    const roleOptions = useMemo(() => (allRoles ? getRoleOptionsForType(allRoles, ROLE_TYPE_REGULAR) : []), [allRoles])

    return (
        <RegularPage id="EditShiftTemplateDetails" breadcrumbs={breadcrumbs}>
            <h2>{pageTitle}</h2>
            <Form noValidate onSubmit={handleSubmit(onSubmit)} className="spacer">
                <Form.Group>
                    <Form.Label>{t("Forms.name")}</Form.Label>
                    <Form.Control type="text" {...register("name")} isInvalid={!!errors.name} />
                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("Forms.active")}</Form.Label>
                    <InfoPopover id="validPeriod" body={t("EditShiftTemplateDetailsPage.valid_period_description")} />
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1">{t("EditShiftTemplateDetailsPage.from")}</InputGroup.Text>
                        <DatePicker
                            selected={validFrom}
                            onChange={setValidFrom}
                            showPopperArrow={false}
                            calendarStartDay={1}
                            todayButton={t("Main.today")}
                            showWeekNumbers
                            dateFormat="d-M-yyyy"
                            className={"form-control form-control-lg input-group-middle" + (validFromIsError ? " form-input-error" : "")}
                            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => <DatePickerHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />}
                        />
                        <InputGroup.Text id="basic-addon1">{t("EditShiftTemplateDetailsPage.to")}</InputGroup.Text>
                        <DatePicker
                            selected={validTo}
                            onChange={setValidTo}
                            showPopperArrow={false}
                            calendarStartDay={1}
                            todayButton={t("Main.today")}
                            showWeekNumbers
                            dateFormat="d-M-yyyy"
                            className={"form-control form-control-lg input-group-end" + (validToIsError ? " form-input-error" : "")}
                            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => <DatePickerHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />}
                        />
                    </InputGroup>
                    <Form.Control type="hidden" isInvalid={!!errors.validFrom} />
                    <Form.Control.Feedback type="invalid">{errors.validFrom?.message}</Form.Control.Feedback>
                    <Form.Control type="hidden" isInvalid={!!errors.validTo} />
                    <Form.Control.Feedback type="invalid">{errors.validTo?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("EditShiftTemplateDetailsPage.occupation_template")}</Form.Label>
                    <InfoPopover id="occupationTemplate" body={t("EditShiftTemplateDetailsPage.occupation_template_description")} />
                    <Form.Select {...register("occupationTemplate")} isInvalid={!!errors.occupationTemplate?.message}>
                        <option value="">{t("Main.choose")}</option>
                        {occupationTemplates?.map(({ id, name }) => (
                            <option key={id} value={`${id}`}>
                                {name}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.occupationTemplate?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("EditShiftTemplateDetailsPage.week_cycle")}</Form.Label>
                    <InfoPopover id="weekCycle" body={t("EditShiftTemplateDetailsPage.week_cycle_description")} />
                    <Form.Control type="number" {...register("weekCycle")} isInvalid={!!errors.weekCycle?.message} />
                    <Form.Control.Feedback type="invalid">{errors.weekCycle?.message}</Form.Control.Feedback>
                </Form.Group>
                {mode === "Create" ? (
                    <Row>
                        <Col xs={initializationMode === ShiftTemplateInitMode.COPY ? 6 : 12}>
                            <Form.Group>
                                <Form.Label>{t("EditShiftTemplateDetailsPage.initialization_mode")}</Form.Label>
                                <InfoPopover id="initializationMode" body={t("EditShiftTemplateDetailsPage.initialization_mode_description")} />
                                <Form.Select {...register("initializationMode")} isInvalid={!!errors.initializationMode}>
                                    {initializationModeOptions.map(({ id, name }) => (
                                        <option key={id} value={id}>
                                            {name}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.initializationMode?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        {initializationMode === ShiftTemplateInitMode.COPY ? (
                            <Col>
                                <Form.Group>
                                    <Form.Label>{t("EditShiftTemplateDetailsPage.copy_shift_template")}</Form.Label>
                                    <Form.Select {...register("sourceTemplate")} isInvalid={!!errors.sourceTemplate}>
                                        <option value="">{t("Main.choose")}</option>
                                        {shiftTemplates?.map(({ id, name }) => (
                                            <option key={id} value={`${id}`}>
                                                {name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.sourceTemplate?.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        ) : null}
                    </Row>
                ) : null}
                {initializationMode === ShiftTemplateInitMode.GENERATE ? (
                    <Row>
                        <Col xs={6}>
                            <Form.Group>
                                <Form.Label>{t("EditShiftTemplateDetailsPage.generate_for_users")}</Form.Label>
                                <div className="container-scroll" style={{ maxHeight: "400px" }}>
                                    <InlineFormMultiSelect id="users" options={userOptions} values={users} onChange={setUsers} toggleAllEnabled={true} />
                                </div>
                                <Form.Control type="hidden" isInvalid={!!errors.users?.message} />
                                <Form.Control.Feedback type="invalid">{errors.users?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group>
                                <Form.Label>{t("EditShiftTemplateDetailsPage.generate_roles")}</Form.Label>
                                <div className="container-scroll" style={{ maxHeight: "400px" }}>
                                    <InlineFormMultiSelect id="roles" options={roleOptions} values={roles} onChange={setRoles} toggleAllEnabled={true} />
                                </div>
                                <Form.Control type="hidden" isInvalid={!!errors.roles?.message} />
                                <Form.Control.Feedback type="invalid">{errors.roles?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                ) : null}
                {mode === "Create" ? (
                    <div>
                        <Button type="submit" data-cy="createShiftTemplate">
                            {t("Buttons.save")}
                        </Button>
                    </div>
                ) : null}
                {mode === "Update" ? (
                    <div>
                        <Button type="submit" data-cy="saveShiftTemplate">
                            {t("Buttons.save")}
                        </Button>
                        <Button type="button" variant="danger" onClick={onDeleteShiftTemplate} className="ms-2">
                            {t("Buttons.delete")}
                        </Button>
                    </div>
                ) : null}
                {deletionWarningVisible ? (
                    <span className="ms-3">
                        <FontAwesomeIcon icon={faExclamationCircle} className="me-1" />
                        {t("EditShiftTemplateDetailsPage.are_you_sure_delete")}
                        <Button type="button" variant="link" onClick={onDeleteShiftTemplate}>
                            {t("Buttons.yes_delete")}
                        </Button>
                    </span>
                ) : null}
                <Form.Group hidden={!errors.root}>
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid" data-cy="root_errors">
                        {errors.root?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </RegularPage>
    )
}

export default EditShiftTemplateDetailsPage
