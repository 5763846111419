import { FC, useCallback } from "react"
import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useScheduleEditorState } from "../contexts/ScheduleEditorContext"

interface Props {
    selectedRoleId: number
    setSelectedRoleId: (value: number) => void
}

const RoleSelect: FC<Props> = ({ selectedRoleId, setSelectedRoleId }) => {
    const { t } = useTranslation()
    const editorState = useScheduleEditorState()
    const { roles } = editorState

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            setSelectedRoleId(parseInt(e.target.value))
        },
        [setSelectedRoleId]
    )

    return (
        <Form.Select data-cy="roleSelect" value={selectedRoleId} onChange={onChange} className="form-select-inline">
            <option value={0}>{t("Main.role_free")}</option>
            {roles.map(({ id, name }) => (
                <option key={id} value={id}>
                    {name}
                </option>
            ))}
        </Form.Select>
    )
}

export default RoleSelect
