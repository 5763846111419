import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useEffect } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQuery, useQueryClient } from "react-query"
import { getDateTime, prettyPrintDateTime } from "../../helpers/DaysHelper"
import { setOptionalError } from "../../helpers/FormHelper"
import { createAbsenceRequestAfasSync } from "../../services/AbsenceRequestAfasSync"
import { loadAfasConnections } from "../../services/AfasConnection"
import { loadAfasLeaveTypes } from "../../services/AfasLeaveType"
import { loadAfasUserConnections } from "../../services/AfasUserConnection"
import AbsenceRequestType from "../../types/AbsenceRequestType"
import { UserType } from "../../types/UserType"

interface Props {
    show: boolean
    close: () => void
    user?: UserType
    absenceRequest?: AbsenceRequestType
}

interface Inputs {
    afasConnection: string
    afasUserConnection: string
    leaveType: string
    dateTimeFrom: string
    dateTimeTo: string
    message: string
    partialLeave: boolean
}

const AfasSyncModal: FC<Props> = ({ show, close, user, absenceRequest }) => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()

    const {
        register,
        setError,
        setValue,
        formState: { errors },
        handleSubmit,
        watch,
        reset,
    } = useForm<Inputs>()

    const selectedAfasConnection = watch("afasConnection")

    const { data: afasConnections } = useQuery(["AfasConnections", user?.id], () => loadAfasConnections(user!.id), { enabled: !!user })

    const { data: afasUserConnections } = useQuery(["AfasUserConnections", user?.id], () => loadAfasUserConnections(parseInt(selectedAfasConnection!), user!.id), {
        enabled: !!user && !!selectedAfasConnection,
    })

    const { data: afasLeaveTypes } = useQuery(["AfasLeaveTypes", selectedAfasConnection], () => loadAfasLeaveTypes(parseInt(selectedAfasConnection!)), { enabled: !!selectedAfasConnection })

    useEffect(() => {
        if (afasConnections && afasConnections.length > 0) {
            setValue("afasConnection", afasConnections[0].id.toString())
        }
    }, [afasConnections, setValue])

    useEffect(() => {
        if (afasUserConnections && afasUserConnections.length > 0) {
            setValue("afasUserConnection", afasUserConnections[0].id.toString())
        }
    }, [afasUserConnections, setValue])

    useEffect(() => {
        if (absenceRequest && user) {
            setValue("dateTimeFrom", prettyPrintDateTime(getDateTime("start", absenceRequest.startDate, absenceRequest.startSlot !== null ? absenceRequest.startSlot : user.startSlot)))
            setValue("dateTimeTo", prettyPrintDateTime(getDateTime("end", absenceRequest.endDate, absenceRequest.endSlot !== null ? absenceRequest.endSlot : user.endSlot)))
            setValue("message", absenceRequest.userMessage)
        }
    }, [absenceRequest])

    const onSuccess = useCallback(() => {
        reset()
        queryClient.removeQueries({ queryKey: ["data", "AbsenceRequestAfasSync"] })
        close()
    }, [reset, close])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "afasUserConnection", data.afasUserConnection)
            setOptionalError(setError, "leaveType", data.leaveType)
            setOptionalError(setError, "dateTimeFrom", data.dateTimeFrom)
            setOptionalError(setError, "dateTimeTo", data.dateTimeTo)
            setOptionalError(setError, "message", data.message)
            setOptionalError(setError, "partialLeave", data.partialLeave)
            setOptionalError(setError, "root", data.nonFieldErrors || [t("Main.something_went_wrong")])
        },
        [setError]
    )

    const onSubmit = useCallback(
        ({ afasUserConnection, leaveType, dateTimeFrom, dateTimeTo, message, partialLeave }: Inputs) => {
            createAbsenceRequestAfasSync({
                afasUserConnection: parseInt(afasUserConnection),
                absenceRequest: absenceRequest!.id,
                leaveType,
                dateTimeFrom,
                dateTimeTo,
                message,
                partialLeave,
            })
                .then(onSuccess)
                .catch(onFailure)
        },
        [absenceRequest, onSuccess, onFailure]
    )

    return (
        <Modal show={show} onHide={close}>
            <Modal.Header className="justify-content-between">
                <Modal.Title>{t("AfasSyncModal.title")}</Modal.Title>
                <Button type="button" variant="link" onClick={close}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
            </Modal.Header>
            <Modal.Body className="spacer">
                <Form noValidate onSubmit={handleSubmit(onSubmit)} className="spacer">
                    <Form.Group>
                        <Form.Label>{t("AbsenceRequestAfasSync.afas_connection")}</Form.Label>
                        <Form.Select {...register("afasConnection")} isInvalid={!!errors.afasConnection}>
                            {afasConnections?.map(({ id, name }) => (
                                <option key={id} value={id.toString()}>
                                    {name}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.afasConnection?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t("AbsenceRequestAfasSync.afas_user_connection")}</Form.Label>
                        <Form.Select {...register("afasUserConnection")} isInvalid={!!errors.afasUserConnection}>
                            {afasUserConnections?.map(({ id, afasEmail, afasEmployeeId, afasEmploymentId }) => (
                                <option key={id} value={id.toString()}>
                                    {afasEmail} ({afasEmployeeId}-{afasEmploymentId})
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.afasConnection?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t("AbsenceRequestAfasSync.leave_type")}</Form.Label>
                        <Form.Select {...register("leaveType")} isInvalid={!!errors.leaveType}>
                            {afasLeaveTypes?.map(({ id, cao, leaveCode, leaveName, leaveBaseName }) => (
                                <option key={id} value={leaveCode}>
                                    {cao} - {leaveCode} - {leaveName} ({leaveBaseName})
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.leaveType?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t("AbsenceRequestAfasSync.date_time_from")}</Form.Label>
                        <Form.Control {...register("dateTimeFrom")} isInvalid={!!errors.dateTimeFrom} />
                        <Form.Control.Feedback type="invalid">{errors.dateTimeFrom?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t("AbsenceRequestAfasSync.date_time_to")}</Form.Label>
                        <Form.Control {...register("dateTimeTo")} isInvalid={!!errors.dateTimeTo} />
                        <Form.Control.Feedback type="invalid">{errors.dateTimeTo?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{t("AbsenceRequestAfasSync.message")}</Form.Label>
                        <Form.Control {...register("message")} isInvalid={!!errors.message} />
                        <Form.Control.Feedback type="invalid">{errors.message?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="partialLeave">
                        <Form.Check {...register("partialLeave")} isInvalid={!!errors.partialLeave} label={t("AbsenceRequestAfasSync.partial_leave")} />
                        <Form.Control.Feedback type="invalid">{errors.partialLeave?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group hidden={!errors.root}>
                        <Form.Control type="hidden" isInvalid={!!errors.root} />
                        <Form.Control.Feedback type="invalid" data-cy="root_errors">
                            {errors.root?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div>
                        <Button type="submit">{t("Buttons.save")}</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default AfasSyncModal
