import { UserAvailabilityType } from "../types/UserAvailabilityType"
import { UserDetailedType, UserType } from "../types/UserType"
import { getWeekNumber } from "./DaysHelper"

export function getApplicableAvailabilities(user: UserDetailedType, date: Date): UserAvailabilityType[] {
    const weekDay = (date.getDay() + 6) % 7
    const cycleWeek = (getWeekNumber(date) - 1) % user.weekCycle

    return user.availabilities.filter((a) => a.day === weekDay + cycleWeek * 7)
}

export function getApplicableAvailability(availabilities: UserAvailabilityType[], user: UserType, templateDay: number | null, date: Date | null): UserAvailabilityType | undefined {
    let day = null
    if (templateDay !== null) {
        day = templateDay % ((user.weekCycle + 1) * 7)
    } else if (date !== null) {
        const weekDay = (date.getDay() + 6) % 7
        const cycleWeek = (getWeekNumber(date) - 1) % user.weekCycle
        day = weekDay + cycleWeek * 7
    }

    if (day !== null) {
        return availabilities.find((a) => a.user === user.id && a.day === day)
    }
}
