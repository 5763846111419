import { FC, useCallback, useMemo } from "react"
import { AxisLinearOptions, AxisOptions, Chart, Datum, DatumFocusStatus, UserSerie } from "react-charts"
import { useTranslation } from "react-i18next"
import { useColors, useNestedRolesForSelectedLocation } from "../../../../contexts/UserSettingsContext"
import { getCSSColor } from "../../../../helpers/ColorHelper"
import { prettyPrintQuarters } from "../../../../helpers/DaysHelper"
import { RoleHoursAggregatedDatum, canShowData } from "../../../../helpers/TaskHelper"
import { TaskRoleTimeResult, TaskType } from "../../../../services/Task"
import NoData from "../../NoData"

interface RoleHoursGraph2Props {
    task?: TaskType<TaskRoleTimeResult>
}

const RoleHoursGraph3: FC<RoleHoursGraph2Props> = ({ task }) => {
    const { t } = useTranslation()
    const colors = useColors()
    const nestedRoles = useNestedRolesForSelectedLocation()

    const taskResult = useMemo(() => {
        return task?.result
    }, [task])

    const data = useMemo(() => {
        if (!taskResult) {
            return undefined
        }

        const result: UserSerie<RoleHoursAggregatedDatum>[] = []

        const data = []
        for (const roleItem of nestedRoles) {
            const itemAggregate = taskResult["rolesAggregate"][roleItem.uid]
            if (itemAggregate) {
                data.push({
                    roleItem,
                    total: itemAggregate[0],
                    isSubItem: false,
                })
            }
            if (roleItem.subRoles) {
                for (const subItem of roleItem.subRoles) {
                    const subItemAggregate = taskResult["rolesAggregate"][subItem.uid]
                    if (subItemAggregate) {
                        data.push({
                            roleItem: subItem,
                            total: subItemAggregate[0],
                            isSubItem: true && !!itemAggregate, // If parent is not included, don't treat role as subRole
                        })
                    }
                }
            }
        }
        data.reverse()

        result.push({
            label: t("Main.total"),
            data: data,
        })

        return result
    }, [taskResult, nestedRoles, t])

    const primaryAxis = useMemo<AxisOptions<RoleHoursAggregatedDatum>>(() => {
        return {
            position: "left",
            getValue: (datum) => (datum.roleItem ? datum.roleItem.name : ""),
        }
    }, [])

    const secondaryAxes = useMemo<AxisLinearOptions<RoleHoursAggregatedDatum>[]>(
        () => [
            {
                position: "bottom",
                getValue: (datum) => datum.total / 4,
                min: 0,
                formatters: {
                    scale: (datum) => prettyPrintQuarters(datum * 4),
                },
            },
        ],
        []
    )

    const getDatumStyle = useCallback(
        (datum: Datum<RoleHoursAggregatedDatum>, status: DatumFocusStatus) => {
            return {
                color: getCSSColor(colors.get(datum.originalDatum.roleItem.color)!),
                opacity: status === "focused" ? 1 : 0.7,
            }
        },
        [colors]
    )

    return (
        <div className="w-100 h-100">
            {canShowData(taskResult) ? (
                <Chart
                    options={{
                        data: data!,
                        primaryAxis,
                        secondaryAxes,
                        getDatumStyle,
                    }}
                />
            ) : (
                <NoData taskResult={taskResult} />
            )}
        </div>
    )
}

export default RoleHoursGraph3
