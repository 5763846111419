import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import RolesLegend from "../../components/editor/RolesLegend"
import FormColorSelect from "../../components/form/FormColorSelect"
import InfoPopover from "../../components/InfoPopover"
import RegularPage from "../../components/page/RegularPage"
import { useTabs } from "../../contexts/TabsContext"
import { UserSettingsContext, useSelectedLocation } from "../../contexts/UserSettingsContext"
import { setOptionalError } from "../../helpers/FormHelper"
import { createRoleGroup, loadRoleGroup, removeRoleGroup, updateRoleGroup } from "../../services/RoleGroup"

interface Props {
    mode: "Create" | "Update"
}

interface Inputs {
    name: string
    color: number | undefined
    hasOccupationTarget: boolean
}

const EditRoleGroupPage: FC<Props> = ({ mode }) => {
    const { t } = useTranslation()
    const location = useSelectedLocation()
    const { setActiveTab } = useTabs()
    const params = useParams()
    const id = useMemo(() => parseInt(params.id!), [params])
    const navigate = useNavigate()
    const { reloadUserSettings } = useContext(UserSettingsContext)

    const {
        register,
        setError,
        clearErrors,
        setValue,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm<Inputs>({
        defaultValues: {
            name: "",
            color: undefined,
            hasOccupationTarget: false,
        },
    })

    const [deletionWarningVisible, setDeletionWarningVisible] = useState(false)

    const { data: roleGroup } = useQuery(["RoleGroup", id], loadRoleGroup(id), { enabled: mode !== "Create" })

    useEffect(() => {
        if (!roleGroup) {
            return
        }
        setValue("name", roleGroup.name)
        setValue("color", roleGroup.color)
        setValue("hasOccupationTarget", roleGroup.hasOccupationTarget)
    }, [roleGroup])

    const onSuccess = useCallback(() => {
        reloadUserSettings()
        navigate("/taakgroepen")
    }, [reloadUserSettings, navigate])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "name", data.name)
            setOptionalError(setError, "color", data.color)
            setOptionalError(setError, "hasOccupationTarget", data.hasOccupationTarget)
            setOptionalError(setError, "root", data.nonFieldErrors)
        },
        [setError]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ name, color, hasOccupationTarget }) => {
            if (mode === "Create") {
                createRoleGroup({
                    location: location.id,
                    name,
                    color,
                    hasOccupationTarget,
                })
                    .then(onSuccess)
                    .catch(onFailure)
            } else if (mode === "Update") {
                updateRoleGroup({
                    id,
                    name,
                    color,
                    hasOccupationTarget,
                })
                    .then(onSuccess)
                    .catch(onFailure)
            }
        },
        [id, location, onSuccess, onFailure]
    )

    const onDeleteRoleGroup = useCallback(() => {
        if (!deletionWarningVisible) {
            setDeletionWarningVisible(true)
            return
        }

        removeRoleGroup(id).then(onSuccess).catch(onFailure)
    }, [deletionWarningVisible, setDeletionWarningVisible, id, navigate, onFailure])

    useEffect(() => setActiveTab("Roles"), [setActiveTab])

    const name = watch("name")

    const crumbTitle = useMemo(() => (mode === "Create" ? t("Main.new") : name), [name])
    const pageTitle = useMemo(() => (mode === "Create" ? t("EditRoleGroupPage.new_role_group") : name), [name])

    const color = watch("color")
    const setColor = useCallback(
        (color: number) => {
            setValue("color", color)
            clearErrors("color")
        },
        [setValue]
    )

    return (
        <RegularPage
            id="EditRoleGroup"
            breadcrumbs={[{ title: t("RolesPage.title_roles"), href: "/taken" }, { title: t("RoleGroupsPage.title_role_groups"), href: "/taakgroepen" }, { title: crumbTitle }]}
        >
            <Form noValidate onSubmit={handleSubmit(onSubmit)} className="spacer">
                <h2>{pageTitle}</h2>
                <Form.Group>
                    <Form.Label>{t("Forms.name")}</Form.Label>
                    <Form.Control size="lg" type="text" {...register("name")} isInvalid={!!errors.name} />
                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("Forms.color")}</Form.Label>
                    <FormColorSelect selectedColor={color} setSelectedColor={setColor} />
                    <Form.Control type="hidden" isInvalid={!!errors.color} />
                    <Form.Control.Feedback type="invalid">{errors.color?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("EditRoleGroupPage.has_occupation_target")}</Form.Label>
                    <InfoPopover id="hasOccupationTarget" body={t("EditRoleGroupPage.has_occupation_target_description")} />
                    <Form.Check {...register("hasOccupationTarget")} isInvalid={!!errors.hasOccupationTarget} />
                    <Form.Control.Feedback type="invalid">{errors.hasOccupationTarget?.message}</Form.Control.Feedback>
                </Form.Group>
                <div className="d-flex align-items-center">
                    <Button type="submit" className="me-2">
                        {t("Buttons.save")}
                    </Button>
                    {mode === "Update" ? (
                        <Button type="button" onClick={onDeleteRoleGroup} variant="danger">
                            {t("Buttons.delete")}
                        </Button>
                    ) : null}
                    {deletionWarningVisible ? (
                        <div className="ms-1 mt-2">
                            <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                            <span className="me-1">{t("EditRoleGroupPage.are_you_sure_delete")}</span>
                            <Button variant="link" type="button" onClick={onDeleteRoleGroup}>
                                {t("Buttons.yes_delete")}
                            </Button>
                        </div>
                    ) : null}
                </div>
                <Form.Group hidden={!errors.root}>
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid" data-cy="root_errors">
                        {errors.root?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <RolesLegend />
            </Form>
        </RegularPage>
    )
}

export default EditRoleGroupPage
