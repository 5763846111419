import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import debounce from "debounce"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"
import AfasBalanceModal from "../../components/afasLeaveBalance/AfasBalanceModal"
import DateTimeSelector from "../../components/form/DateTimeSelector"
import RegularPage from "../../components/page/RegularPage"
import { useTabs } from "../../contexts/TabsContext"
import { useCurrentUser, useIsAdmin, useRolesForSelectedLocation, useSelectedLocation, useUsersForSelectedLocation } from "../../contexts/UserSettingsContext"
import { ROLE_TYPE_LEAVE } from "../../helpers/Constants"
import { dateFromDjango, getFirstNextWorkday, getSlot, getTime, isoPrint, isoPrintTime, timeFromIso } from "../../helpers/DaysHelper"
import { setOptionalError } from "../../helpers/FormHelper"
import { getRoleOptionsForType } from "../../helpers/RolesHelper"
import { getUserOptions } from "../../helpers/UsersHelper"
import useAbsenceRequestAfasHandleStatusOptions from "../../hooks/UseAbsenceRequestAfasHandleStatusOptions"
import useAbsenceRequestHandleStatusOptions from "../../hooks/UseAbsenceRequestHandleStatusOptions"
import useAbsenceRequestReviewStatusOptions from "../../hooks/UseAbsenceRequestReviewStatusOptions"
import { createAbsenceRequest, deleteAbsenceRequest, loadAbsenceRequest, updateAbsenceRequest } from "../../services/AbsenceRequest"
import { AbsenceRequestAfasHandleStatus, AbsenceRequestHandleStatus, AbsenceRequestReviewStatus } from "../../types/AbsenceRequestType"
import AbsenceRequestAfasSyncsTable from "./AbsenceRequestAfasSyncsTable"
import AfasSyncModal from "./AfasSyncModal"
import styles from "./EditAbsenceRequestPage.module.scss"

interface Props {
    mode: "Create" | "Update"
}

interface Inputs {
    user: number
    startDate: Date
    startTime: string
    endDate: Date
    endTime: string
    userMessage: string
    adminMessage: string
    reviewStatus: AbsenceRequestReviewStatus
    handleStatus: AbsenceRequestHandleStatus
    afasHandleStatus: AbsenceRequestAfasHandleStatus
    role?: number
}

const EditAbsenceRequestPage: FC<Props> = ({ mode }) => {
    const { t } = useTranslation()
    const { setActiveTab } = useTabs()
    const params = useParams()
    const location = useSelectedLocation()
    const roles = useRolesForSelectedLocation()
    const id = useMemo(() => parseInt(params.id!), [params])
    const navigate = useNavigate()
    const isAdmin = useIsAdmin()
    const currentUser = useCurrentUser()
    const firstNextWorkday = useMemo(() => getFirstNextWorkday(new Date(), currentUser.enabledDays), [currentUser])
    const users = useUsersForSelectedLocation()
    const roleOptions = useMemo(() => getRoleOptionsForType(roles, ROLE_TYPE_LEAVE), [roles])
    const reviewStatusOptions = useAbsenceRequestReviewStatusOptions()
    const handleStatusOptions = useAbsenceRequestHandleStatusOptions()
    const afasHandleStatusOptions = useAbsenceRequestAfasHandleStatusOptions()

    const [showAfasBalanceModal, setShowAfasBalanceModal] = useState(false)
    const [showAfasSyncModal, setShowAfasSyncModal] = useState(false)

    const {
        register,
        setError,
        setValue,
        getValues,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm<Inputs>({
        defaultValues: {
            user: currentUser.id,
            startDate: firstNextWorkday,
            startTime: "",
            endDate: firstNextWorkday,
            endTime: "",
            userMessage: "",
            adminMessage: "",
            reviewStatus: "TO_BE_REVIEWED",
            handleStatus: "TO_BE_HANDLED",
            afasHandleStatus: "TO_BE_HANDLED",
        },
    })

    const [deletionWarningVisible, setDeletionWarningVisible] = useState(false)

    const { data: absencerequest } = useQuery(["AbsenceRequest", id], loadAbsenceRequest(id), { enabled: mode !== "Create" })

    useEffect(() => {
        if (!absencerequest) {
            return
        }
        setValue("user", absencerequest.user)
        setValue("startDate", dateFromDjango(absencerequest.startDate))
        setValue("startTime", absencerequest.startSlot !== null ? isoPrintTime(getTime("start", absencerequest.startSlot)!)! : "")
        setValue("endDate", dateFromDjango(absencerequest.endDate))
        setValue("endTime", absencerequest.endSlot !== null ? isoPrintTime(getTime("end", absencerequest.endSlot)!)! : "")
        setValue("userMessage", absencerequest.userMessage)
        setValue("adminMessage", absencerequest.adminMessage)
        setValue("reviewStatus", absencerequest.reviewStatus)
        setValue("handleStatus", absencerequest.handleStatus)
        setValue("afasHandleStatus", absencerequest.afasHandleStatus)
    }, [absencerequest])

    const navigateToPlan = useCallback(() => {
        const { user, startDate, startTime, endDate, endTime, role } = getValues()
        navigate(
            `/rooster?absencerequest=${id}&user=${user}&location=${location.id}&date=${isoPrint(startDate)}&startDate=${isoPrint(startDate)}&startTime=${startTime}&endDate=${isoPrint(endDate)}&endTime=${endTime}&role=${role}`
        )
    }, [navigate, id])

    const onSuccess = useCallback(() => navigate("/verlofaanvragen"), [navigate])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "user", data.user)
            setOptionalError(setError, "startDate", data.startDate)
            setOptionalError(setError, "startTime", data.startTime)
            setOptionalError(setError, "endDate", data.endDate)
            setOptionalError(setError, "endTime", data.endTime)
            setOptionalError(setError, "userMessage", data.userMessage)
            setOptionalError(setError, "adminMessage", data.adminMessage)
            setOptionalError(setError, "reviewStatus", data.reviewStatus)
            setOptionalError(setError, "handleStatus", data.handleStatus)
            setOptionalError(setError, "afasHandleStatus", data.afasHandleStatus)
            setOptionalError(setError, "root", data.nonFieldErrors)
        },
        [setError]
    )

    const onSave = useCallback(
        debounce(
            ({ user, startDate, startTime, endDate, endTime, userMessage, adminMessage, reviewStatus, handleStatus, afasHandleStatus }: Inputs, plan?: boolean) => {
                if (mode === "Create") {
                    createAbsenceRequest({
                        user: user,
                        startDate: isoPrint(startDate),
                        startSlot: startTime !== "" ? getSlot("start", timeFromIso(startTime)!)! : undefined,
                        endDate: isoPrint(endDate),
                        endSlot: endTime !== "" ? getSlot("end", timeFromIso(endTime)!)! : undefined,
                        userMessage: userMessage,
                    })
                        .then(onSuccess)
                        .catch(onFailure)
                } else if (mode === "Update") {
                    updateAbsenceRequest({
                        id: id,
                        user: user,
                        startDate: isoPrint(startDate),
                        startSlot: startTime !== "" ? getSlot("start", timeFromIso(startTime)!)! : undefined,
                        endDate: isoPrint(endDate),
                        endSlot: endTime !== "" ? getSlot("end", timeFromIso(endTime)!)! : undefined,
                        userMessage: userMessage,
                        adminMessage: adminMessage,
                        reviewStatus: reviewStatus,
                        handleStatus: handleStatus,
                        afasHandleStatus: afasHandleStatus,
                    })
                        .then(plan ? navigateToPlan : onSuccess)
                        .catch(onFailure)
                }
            },
            300,
            {
                immediate: true,
            }
        ),
        [id]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback((inputs: Inputs) => onSave(inputs, false), [onSave])

    const onSubmitWithPlan = useCallback(() => onSave(getValues(), true), [onSave, getValues])

    const onDeleteAbsenceRequest = useCallback(() => {
        if (!deletionWarningVisible) {
            setDeletionWarningVisible(true)
            return
        }
        deleteAbsenceRequest(id).then(onSuccess).catch(onFailure)
    }, [deletionWarningVisible, setDeletionWarningVisible, id, onSuccess, onFailure])

    const userOptions = useMemo(() => getUserOptions(users), [users])

    useEffect(() => setActiveTab("AbsenceRequests"), [setActiveTab])

    const setStartDate = useCallback((value: Date | null) => setValue("startDate", value!), [setValue])
    const setStartTime = useCallback((value: string) => setValue("startTime", value), [setValue])
    const setEndDate = useCallback((value: Date | null) => setValue("endDate", value!), [setValue])
    const setEndTime = useCallback((value: string) => setValue("endTime", value), [setValue])

    const user = watch("user")
    const startDate = watch("startDate")
    const startTime = watch("startTime")
    const endDate = watch("endDate")
    const endTime = watch("endTime")
    const reviewStatus = watch("reviewStatus")
    const handleStatus = watch("handleStatus")

    const targetUser = useMemo(() => users.find((u) => u.id === user), [users])
    const targetUserName = useMemo(() => (targetUser ? targetUser.firstName + " " + targetUser.lastName : ""), [targetUser])
    const crumbTitle = useMemo(() => (mode === "Create" ? t("Main.new") : t("EditAbsenceRequestPage.absence_request")), [mode, t])
    const pageTitle = useMemo(() => (mode === "Create" ? t("EditAbsenceRequestPage.new_absence_request") : targetUserName), [mode, targetUserName, t])

    const openAfasBalanceModal = useCallback(() => setShowAfasBalanceModal(true), [setShowAfasBalanceModal])
    const closeAfasBalanceModal = useCallback(() => setShowAfasBalanceModal(false), [setShowAfasBalanceModal])

    const openAfasSyncModal = useCallback(() => setShowAfasSyncModal(true), [setShowAfasSyncModal])
    const closeAfasSyncModal = useCallback(() => setShowAfasSyncModal(false), [setShowAfasSyncModal])

    return (
        <RegularPage id="EditAbsenceRequest" breadcrumbs={[{ title: t("AbsenceRequestsPage.title"), href: "/verlofaanvragen" }, { title: crumbTitle }]}>
            {mode === "Update" && location.afasConnections.length > 0 ? (
                <>
                    <AfasBalanceModal show={showAfasBalanceModal} close={closeAfasBalanceModal} user={targetUser} />
                    <AfasSyncModal show={showAfasSyncModal} close={closeAfasSyncModal} user={targetUser} absenceRequest={absencerequest} />
                </>
            ) : null}
            <Form noValidate onSubmit={handleSubmit(onSubmit)} className="spacer">
                <h2>{pageTitle}</h2>
                {mode === "Create" && isAdmin ? (
                    <Form.Group>
                        <Form.Label>{t("Forms.employee")}</Form.Label>
                        <Form.Select {...register("user")} isInvalid={!!errors.user} size="lg" data-cy="user">
                            {userOptions.map(({ id, name }) => (
                                <option key={id} value={id}>
                                    {name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                ) : null}
                <Row>
                    <Col xs={12} md={5} xl={4}>
                        <Form.Group>
                            <Form.Label>{t("Time.from")}</Form.Label>
                            <DateTimeSelector
                                selectedDate={startDate}
                                setSelectedDate={setStartDate}
                                dateIsError={!!errors.startDate}
                                dateTabIndex={2}
                                selectedTime={startTime}
                                setSelectedTime={setStartTime}
                                timeIsError={!!errors.startTime}
                                timeTabIndex={3}
                                startSlot={currentUser.startSlot}
                                endSlot={currentUser.endSlot}
                                disabled={!isAdmin && reviewStatus !== "TO_BE_REVIEWED"}
                                inline={false}
                                type="start"
                            />
                            <Form.Control.Feedback type="invalid">{errors.startDate?.message}</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors.startTime?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={5} xl={4} className="mt-3 mt-md-0">
                        <Form.Group>
                            <Form.Label>{t("Time.to")}</Form.Label>
                            <DateTimeSelector
                                selectedDate={endDate}
                                setSelectedDate={setEndDate}
                                dateIsError={!!errors.endDate}
                                dateTabIndex={4}
                                selectedTime={endTime}
                                setSelectedTime={setEndTime}
                                timeIsError={!!errors.endTime}
                                timeTabIndex={5}
                                startSlot={currentUser.startSlot}
                                endSlot={currentUser.endSlot}
                                disabled={!isAdmin && reviewStatus !== "TO_BE_REVIEWED"}
                                inline={false}
                                type="end"
                            />
                            <Form.Control.Feedback type="invalid">{errors.endDate?.message}</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors.endTime?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group>
                    <Form.Label>{mode === "Create" ? t("EditAbsenceRequestPage.message") : t("EditAbsenceRequestPage.message_requested")}</Form.Label>
                    <Form.Control as="textarea" {...register("userMessage")} rows={3} isInvalid={!!errors.userMessage} disabled={!isAdmin && reviewStatus !== "TO_BE_REVIEWED"} />
                    <Form.Control.Feedback type="invalid">{errors.userMessage?.message}</Form.Control.Feedback>
                </Form.Group>
                {mode === "Update" ? (
                    <Form.Group>
                        <Form.Label>{t("EditAbsenceRequestPage.message_admin")}</Form.Label>
                        <Form.Control as="textarea" {...register("adminMessage")} rows={3} isInvalid={!!errors.adminMessage} disabled={!isAdmin} />
                        <Form.Control.Feedback type="invalid">{errors.adminMessage?.message}</Form.Control.Feedback>
                    </Form.Group>
                ) : null}
                {mode === "Update" ? (
                    <Row>
                        <Col xs={12} md={4}>
                            <Form.Group>
                                <Form.Label>{t("EditAbsenceRequestPage.review_status")}</Form.Label>
                                <Form.Select {...register("reviewStatus")} isInvalid={!!errors.reviewStatus} disabled={!isAdmin}>
                                    {reviewStatusOptions.map(({ id, name }) => (
                                        <option key={id} value={id}>
                                            {name}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.reviewStatus?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className="mt-3 mt-md-0">
                                <Form.Label>{t("EditAbsenceRequestPage.handle_status")}</Form.Label>
                                <Form.Select {...register("handleStatus")} isInvalid={!!errors.handleStatus} disabled={!isAdmin}>
                                    {handleStatusOptions.map(({ id, name }) => (
                                        <option key={id} value={id}>
                                            {name}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.handleStatus?.message}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        {mode === "Update" && location.afasConnections.length > 0 ? (
                            <Col xs={12} md={4}>
                                <Form.Group className="mt-3 mt-md-0">
                                    <Form.Label>{t("EditAbsenceRequestPage.afas_handle_status")}</Form.Label>
                                    <Form.Select {...register("afasHandleStatus")} isInvalid={!!errors.afasHandleStatus} disabled={!isAdmin}>
                                        {afasHandleStatusOptions.map(({ id, name }) => (
                                            <option key={id} value={id}>
                                                {name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">{errors.afasHandleStatus?.message}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        ) : null}
                    </Row>
                ) : null}
                {mode === "Update" && location.afasConnections.length > 0 ? (
                    <Form.Group>
                        <Form.Label className="me-2">{t("EditAbsenceRequestPage.afas_syncs")}</Form.Label>
                        <Button type="button" variant="link" className="text-nowrap" onClick={openAfasBalanceModal}>
                            {t("EditAbsenceRequestPage.check_leave_balance")}
                        </Button>
                        <Button type="button" variant="link" className="text-nowrap" onClick={openAfasSyncModal}>
                            {t("EditAbsenceRequestPage.sync_with_afas")}
                        </Button>
                        <AbsenceRequestAfasSyncsTable absenceRequestId={id} />
                    </Form.Group>
                ) : null}
                <div className="d-flex align-items-center">
                    <Button type="submit">{t("Buttons.save")}</Button>
                    {isAdmin && mode === "Update" ? (
                        <div className={`d-flex align-items-center mx-2 ${styles.saveAndPlan}`}>
                            <Button type="button" className="text-nowrap" onClick={onSubmitWithPlan} disabled={handleStatus !== "TO_BE_HANDLED"}>
                                {t("Buttons.save_and_plan")}
                            </Button>
                            <small className="mx-2">{t("EditAbsenceRequestPage.role")}</small>
                            <Form.Select {...register("role")} className="form-select-inline">
                                {roleOptions.map(({ id, name }) => (
                                    <option key={id} value={id}>
                                        {name}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    ) : null}
                    {isAdmin && mode === "Update" ? (
                        <Button type="button" onClick={onDeleteAbsenceRequest} variant="danger">
                            {t("Buttons.delete")}
                        </Button>
                    ) : null}
                </div>
                {deletionWarningVisible ? (
                    <div className="ms-1 mt-2">
                        <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                        <span className="me-1">{t("EditAbsenceRequestPage.are_you_sure_delete")}</span>
                        <Button variant="link" type="button" onClick={onDeleteAbsenceRequest}>
                            {t("Buttons.yes_delete")}
                        </Button>
                    </div>
                ) : null}
                <Form.Group hidden={!errors.root}>
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid" data-cy="root_errors">
                        {errors.root?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </RegularPage>
    )
}

export default EditAbsenceRequestPage
