import { useCallback, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { SubmitHandler, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import FormSuccessMessage from "../components/form/FormSuccessMessage"
import SmallCardPage from "../components/page/SmallCardPage"
import { setOptionalError } from "../helpers/FormHelper"
import { signup } from "../services/User"

interface Inputs {
    firstName: string
    lastName: string
    email: string
    password1: string
    password2: string
}

const SignupPage = () => {
    const { t } = useTranslation()
    const [succeededId, setSucceededId] = useState(0)
    const {
        register,
        reset,
        setError,
        formState: { errors },
        handleSubmit,
    } = useForm<Inputs>()

    const onSuccess = useCallback(() => {
        reset()
        setSucceededId(succeededId + 1)
    }, [reset, setSucceededId])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "firstName", data.first_name)
            setOptionalError(setError, "lastName", data.last_name)
            setOptionalError(setError, "email", data.email)
            setOptionalError(setError, "password1", data.password1)
            setOptionalError(setError, "password2", data.password2)
            setOptionalError(setError, "root", data.nonFieldErrors)
            setSucceededId(0)
        },
        [setError]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ firstName, lastName, email, password1, password2 }) => {
            signup(firstName, lastName, email, password1, password2).then(onSuccess).catch(onFailure)
        },
        [onSuccess, onFailure]
    )

    return (
        <SmallCardPage>
            <h2>{t("SignupPage.register")}</h2>
            <p>{t("SignupPage.fill_details_to_login")}</p>
            <Form noValidate onSubmit={handleSubmit(onSubmit)} className="spacer">
                <Form.Group>
                    <Form.Label>{t("Forms.first_name")}</Form.Label>
                    <Form.Control type="text" autoComplete="given-name" {...register("firstName")} isInvalid={!!errors.firstName} data-cy="firstName" autoFocus />
                    <Form.Control.Feedback type="invalid" data-cy="firstName_errors">
                        {errors.firstName?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("Forms.last_name")}</Form.Label>
                    <Form.Control type="text" autoComplete="family-name" {...register("lastName")} isInvalid={!!errors.lastName} data-cy="lastName" />
                    <Form.Control.Feedback type="invalid" data-cy="lastName_errors">
                        {errors.lastName?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("Forms.email_address")}</Form.Label>
                    <Form.Control type="email" autoComplete="email" {...register("email")} isInvalid={!!errors.email} data-cy="email" />
                    <Form.Control.Feedback type="invalid" data-cy="email_errors">
                        {errors.email?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("Forms.password")}</Form.Label>
                    <Form.Control type="password" {...register("password1")} isInvalid={!!errors.password1} data-cy="password1" />
                    <Form.Control.Feedback type="invalid" data-cy="password1_errors">
                        {errors.password1?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("Forms.repeat_password")}</Form.Label>
                    <Form.Control type="password" {...register("password2")} isInvalid={!!errors.password2} data-cy="password2" />
                    <Form.Control.Feedback type="invalid" data-cy="password2_errors">
                        {errors.password2?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="flex-row">
                    <Button type="submit" data-cy="signup">
                        {t("SignupPage.register")}
                    </Button>
                    <small className="end text-muted fs-small">
                        <Trans i18nKey="SignupPage.already_an_account">
                            Already have an account?
                            <Link data-cy="toLogin" to="/inloggen">
                                Login
                            </Link>
                        </Trans>
                    </small>
                </Form.Group>
                <FormSuccessMessage succeededId={succeededId} />
                <Form.Group hidden={!errors.root}>
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid" data-cy="root_errors">
                        {errors.root?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </SmallCardPage>
    )
}

export default SignupPage
